<div class="table-responsive" style="position:relative;">
  <div class="scroll-info">
    &rArr;&nbsp;&nbsp;Scroll
  </div>
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">{{'price-list.manage.category' | translate}}</th>
        <th scope="col" class="text-center">{{'price-list.manage.position' | translate}}</th>
        <th scope="col" class="text-center">{{'price-list.manage.displayed' | translate}}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let category of priceList.productCategories; first as isFirst; last as isLast"
        #categoryRow{{category.id}}>
        <td [style.width]="700">
          <ng-container *ngIf="toEditProductCategory.id == category.id; else showNameTemplate">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control" [(ngModel)]="toEditProductCategory.name"
                placeholder="Product Category Name" aria-describedby="button-addon4">
              <div class="input-group-append" id="button-addon4">
                <button class="btn btn-outline-secondary" type="button" (click)="editProductCategory()">
                  <i class="fa fa-check"></i>
                </button>
                <button class="btn btn-outline-secondary" type="button" (click)="disableEditMode()">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </ng-container>
          <ng-template #showNameTemplate>
            {{category.name}}
          </ng-template>
        </td>
        <td [style.width]="150" class="text-center"><i class="fa fa-arrows-alt-v"></i>
          {{category.itemOrder}}</td>
        <td [style.width]="150" class="text-center">
          <ng-container *ngIf="category.status == 1; else disabledTemplate">
            <i style="color: green; cursor: pointer;" class="fas fa-check"
              (click)="changeCategoryStatus(category, 0)"></i>
          </ng-container>
          <ng-template #disabledTemplate>
            <i style="color: red; cursor: pointer;" class="fas fa-times"
              (click)="changeCategoryStatus(category, 1)"></i>
          </ng-template>
        </td>
        <td class="text-right">
          <div class="btn-group btn-group-sm" role="group" *ngIf="!editProductMood">
            <button type="button" class="btn btn-link" *ngIf="!isFirst"
              (click)="changeCategoryPosition(category.id, category.itemOrder-1)" placement="top"
              [ngbTooltip]="'price-list.manage.moveUp' | translate"><i class="fa fa-angle-up"></i></button>
            <button type="button" class="btn btn-link" *ngIf="!isLast"
              (click)="changeCategoryPosition(category.id, category.itemOrder+1)" placement="top"
              [ngbTooltip]="'price-list.manage.moveDown' | translate"><i class="fa fa-angle-down"></i></button>
            <button type="button" class="btn btn-link" placement="top"
              [ngbTooltip]="'price-list.manage.edit' | translate" (click)="enableEditProductCategory(category)"><i
                class="fa fa-edit"></i></button>
            <button type="button" class="btn btn-link" placement="top"
              [ngbTooltip]="'price-list.manage.delete' | translate" (click)="deleteProductCategory(category.id)">
              <i class="fa fa-trash"></i></button>
            <button type="button" class="btn btn-link" placement="top"
              [ngbTooltip]="'price-list.manage.duplicate' | translate"
              (click)="duplicateProductCategory(category.id)"><i class="fa fa-copy"></i></button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-confirm-dialog-model #confirmDeleteProductCategoryDialog (getResult)="getDeleteProductCategoryDecision($event)"
  [modalTitle]="'price-list.manage.deleteProductCategoryTitle' | translate"
  [modalContent]="'price-list.manage.deleteProductCategoryMsg' | translate"
  [denyText]="'price-list.manage.no' | translate" [acceptText]="'price-list.manage.yes' | translate">
</app-confirm-dialog-model>

<app-confirm-dialog-model #confirmDuplicateProductCategoryDialog
  (getResult)="getDuplicateProductCategoryDecision($event)"
  [modalTitle]="'price-list.manage.duplicateProductCategoryTitle' | translate"
  [modalContent]="'price-list.manage.duplicateProductCategoryMsg' | translate"
  [denyText]="'price-list.manage.no' | translate" [acceptText]="'price-list.manage.yes' | translate">
</app-confirm-dialog-model>
