<div class="row">
  <div class="col">
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
      <h1 class="display-4">{{'event.statistic.pageTitle' | translate}}</h1>
      <p class="lead">{{'event.statistic.pageSubTitle' | translate}}</p>
      <hr class="my-4">
    </div>
  </div>
</div>
<div class="searchbar">
  <h3 style="color: #2ba8e3!important; font-weight:bold; text-align: center;">
    {{'event.statistic.filters' | translate}}
  </h3>
  <h4 class="mt-0 mb-3">{{'event.statistic.filtersDescription' | translate}}</h4>
  <div class="searchcontent">
    <div class="filters">
      <div class="filter">
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectionDateRange' | translate}}</div>
        <div class="col-12 input">
          <kendo-daterange>
            <kendo-dateinput
              kendoDateRangeStartInput
              autoCorrectOn="blur"
              [(value)]="selectionDateRange.start"
              [format]="'dd-MM-yyyy'"
              [min]="eventStartDate"
              [readonly]="true"
              style="width:46%; margin:0 2%;"
            ></kendo-dateinput>
            <kendo-dateinput
              kendoDateRangeEndInput
              autoCorrectOn="blur"
              [(value)]="selectionDateRange.end"
              [format]="'dd-MM-yyyy'"
              [max]="eventEndDate"
              [readonly]="true"
              style="width:46%; margin:0 2%;"
            ></kendo-dateinput>
            <kendo-daterange-popup
              [animate]="true"
            ></kendo-daterange-popup>
          </kendo-daterange>
        </div>
      </div>
      <div class="filter">
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectionTimeRange' | translate}}</div>
        <div class="col-12 input">
          <kendo-timepicker
            [(value)]="selectionTimeRange.start"
            format="HH:mm"
            style="width:46%; margin:0 2%;"
          ></kendo-timepicker>
          <kendo-timepicker
            [(value)]="selectionTimeRange.end"
            format="HH:mm"
            style="width:46%; margin:0 2%;"
          ></kendo-timepicker>
        </div>
      </div>
      <div class="filter">
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectPricelists' | translate}}</div>
        <div class="col-12 input">
          <kendo-dropdownlist
            [data]="allPriceLists"
            textField="name"
            valueField="id"
            [(ngModel)]="selectedPriceList"
          ></kendo-dropdownlist>
        </div>
      </div>
      <div class="filter">
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.showCharts' | translate}}</div>
        <div class="col-12 input" style="text-align:center">
          <kendo-switch
            [(ngModel)]="showChartChecked"
            onLabel= "{{'event.statistic.showChartYes' | translate}}"
            offLabel= "{{'event.statistic.showChartNo' | translate}}"
          ></kendo-switch>
        </div>
      </div>
  </div>
    <div class="filters mt-4">
      <div class="filter">
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectDeviceType' | translate}}</div>
        <div class="col-12 input">
          <kendo-dropdownlist
            [data]="allDeviceTypes"
            textField="name"
            valueField="id"
            [value]="selectedDeviceType"
            (selectionChange)="onSelectDeviceType($event)"
          ></kendo-dropdownlist>
        </div>
      </div>
      <div class="filter">
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectDevice' | translate}}</div>
        <div class="col-12 input">
          <kendo-multiselect
            [data]="allDevices"
            textField="name"
            valueField="id"
            [value]="selectedDevicesEmpty ? null : selectedDevices"
            (valueChange)="onSelectDevice($event)"
          ></kendo-multiselect>
        </div>
      </div>
      <div class="filter">
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectTable' | translate}}</div>
        <div class="col-12 input">
          <kendo-dropdownlist
            [data]="allTables"
            textField="name"
            valueField="id"
            [(ngModel)]="selectedTable"
          ></kendo-dropdownlist>
        </div>
      </div>
      <div class="filter" *ngIf="showStatistic" >
        <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">&nbsp;</div>
        <div class="col-12 input" style="text-align:center">
          <button kendoButton (click)="onDeleteButtonClick()"
                  size="medium"
                  shape="rectangle"
                  rounded="medium"
                  fillMode="solid"
                  themeColor="error"
                  style="margin:0 10px"
          >{{'event.statistic.deleteDataSwitcherLabel' | translate}}</button>
        </div>
      </div>
    </div>
    <div class="buttons row" style="margin:20px 0; justify-content: space-between">
      <div></div>
      <div>
        <button kendoButton (click)="onSearchButtonClick()"
                size="large"
                themeColor="primary"
                style="margin:0 10px"
        >{{'event.statistic.search' | translate}}</button>
        <button kendoButton (click)="onResetButtonClick()"
                size="large"
                themeColor="secondary"
                style="margin:0 10px"
        >{{'event.statistic.reset' | translate}}</button>
        <button kendoButton icon="file-excel" (click)="save(excelexport2, excelexport3, excelexport1)"
                size="large"
                themeColor="success"
                style="margin:0 10px"
        >{{'event.statistic.export' | translate}}</button>
      </div>
      <div>
        <kendo-excelexport
          [data]="statisticTurnoverData?.sales.tables"
          [collapsible]="true"
          fileName="{{event.eventName}} {{selectionDateRange.start | date: 'dd/MM/yyyy'}} - {{selectionDateRange.end | date: 'dd/MM/yyyy'}}.xlsx"
          #excelexport1
        >
          <kendo-excelexport-column
            field="name"
            title="{{'event.statistic.tables' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="amount"
            title="{{'event.statistic.amount' | translate}} ({{eventCurrency}})"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="quantity"
            title="{{'event.statistic.tablesQuantity' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
          </kendo-excelexport>
          <kendo-excelexport
            [data]="exportProductsData"
            fileName="{{event.eventName}} {{selectionDateRange.start | date: 'dd/MM/yyyy'}} - {{selectionDateRange.end | date: 'dd/MM/yyyy'}}.xlsx"
            #excelexport2
          >
            <kendo-excelexport-column
              field="product"
              title="{{'event.statistic.productName' | translate}}"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="productInfo"
              title="{{'event.statistic.productInfo' | translate}}"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="productPrice"
              title="{{'event.statistic.productPrice' | translate}} ({{eventCurrency}})"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="amountPaid"
              title="{{'event.statistic.amount' | translate}} ({{eventCurrency}})"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="quantityPaid"
              title="{{'event.statistic.quantity' | translate}}"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="amountFree"
              title="{{'event.statistic.amountFree' | translate}} ({{eventCurrency}})"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="quantityFree"
              title="{{'event.statistic.quantityFree' | translate}}"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="amountCancelled"
              title="{{'event.statistic.amountCancelled' | translate}} ({{eventCurrency}})"
              [width]="150">
            </kendo-excelexport-column>
            <kendo-excelexport-column
              field="quantityCancelled"
              title="{{'event.statistic.quantityCancelled' | translate}}"
              [width]="150">
            </kendo-excelexport-column>
        </kendo-excelexport>
        <kendo-excelexport
          [data]="exportWaitersData"
          fileName="{{event.eventName}} {{selectionDateRange.start | date: 'dd/MM/yyyy'}} - {{selectionDateRange.end | date: 'dd/MM/yyyy'}}.xlsx"
          #excelexport3>
          <kendo-excelexport-column
            field="waiter"
            title="{{'event.statistic.waiter' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="product"
            title="{{'event.statistic.productName' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="productInfo"
            title="{{'event.statistic.productInfo' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="productPrice"
            title="{{'event.statistic.productPrice' | translate}} ({{eventCurrency}})"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="amountPaid"
            title="{{'event.statistic.amount' | translate}} ({{eventCurrency}})"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="quantityPaid"
            title="{{'event.statistic.quantity' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="amountFree"
            title="{{'event.statistic.amountFree' | translate}} ({{eventCurrency}})"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="quantityFree"
            title="{{'event.statistic.quantityFree' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="amountCancelled"
            title="{{'event.statistic.amountCancelled' | translate}} ({{eventCurrency}})"
            [width]="150">
          </kendo-excelexport-column>
          <kendo-excelexport-column
            field="quantityCancelled"
            title="{{'event.statistic.quantityCancelled' | translate}}"
            [width]="150">
          </kendo-excelexport-column>
        </kendo-excelexport>
  <!--      <button kendoButton (click)="exportChartsToPDF(printableArea)"
                icon="download"
                size="large"
                themeColor="success"
                style="margin:0 10px"
        >{{'event.statistic.graphDownload' | translate}}</button>-->
      </div>
    </div>
  </div>
</div>
<div #printableArea>
<div class="row" style="margin:25px 0;" *ngIf="showStatistic">
  <div class="col-12">
    <div class="card-group">
      <div class="card border">
        <div class="card-body text-center">
          <div class="row" style="justify-content: center;">
            <div style="margin-left: 50px; margin-right: 50px">
              <h5 class="card-title"><strong>{{'event.statistic.salesLabel' | translate}}</strong></h5>
              <p class="card-text form-text text-muted">{{  eventTotalTurnover}} {{eventCurrency}}</p>
            </div>
            <div style="margin-left: 50px; margin-right: 50px">
              <h5 class="card-title"><strong>{{'event.statistic.salesFreeLabel' | translate}}</strong></h5>
              <p class="card-text form-text text-muted">{{  eventFreeTurnover}} {{eventCurrency}}</p>
            </div>
            <div style="margin-left: 50px; margin-right: 50px">
              <h5 class="card-title"><strong>{{'event.statistic.salesDeletedLabel' | translate}}</strong></h5>
              <p class="card-text form-text text-muted">{{  eventDeletedTurnover}} {{eventCurrency}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card border">
        <div class="card-body text-center">
          <div class="row" style="justify-content: center">
            <div style="margin-left: 80px; margin-right: 80px">
              <h5 class="card-title"><strong>{{'event.statistic.ordersLabel' | translate}}</strong></h5>
              <p class="card-text form-text text-muted">{{ allOrders.length - cancelledOrders }}</p>
            </div>
            <div style="margin-left: 80px; margin-right: 80px">
              <h5 class="card-title"><strong>{{'event.statistic.salesDeletedLabel' | translate}}</strong></h5>
              <p class="card-text form-text text-muted">{{ cancelledOrders}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="showStatistic && showChartChecked" style="margin-right: 0; margin-left: 0;">
  <kendo-chart #salesCategoryChart [ngStyle]="((statisticTurnoverData.sales.categoriesFree.length > 0 && statisticTurnoverData.sales.categoriesCancelled.length > 0) || (statisticTurnoverData.sales.categoriesFree.length === 0 && statisticTurnoverData.sales.categoriesCancelled.length === 0)) && {'width': '100%'}" title="{{'event.statistic.salesCategory' | translate}}">
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="pie"
        field="amount"
        categoryField="name"
        [data]="statisticTurnoverData.sales.categories"
        [labels]="{ visible: true, content: amountContent }">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  <kendo-chart *ngIf="statisticTurnoverData.sales.categoriesFree.length > 0" #freeCategoryChart title="{{'event.statistic.freeCategory' | translate}}">
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
      type="pie"
      field="amount"
      categoryField="name"
      [data]="statisticTurnoverData.sales.categoriesFree"
      [labels]="{ visible: true, content: amountContent }">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  <kendo-chart *ngIf="statisticTurnoverData.sales.categoriesCancelled.length > 0" #cancelledCategoryChart title="{{'event.statistic.cancelledCategory' | translate}}">
    <kendo-chart-legend [visible]="false">
      <kendo-chart-series>
        <kendo-chart-series-item
        type="pie"
        field="amount"
        categoryField="name"
        [data]="statisticTurnoverData.sales.categoriesCancelled"
        [labels]="{ visible: true, content: amountContent }">
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart-legend>
  </kendo-chart>
</div>
<div class="d-flex flex-wrap" *ngIf="showStatistic && showChartChecked && statisticTurnoverData.sales.devices?.length > 1">
  <kendo-chart #salesDeviceChart class="pl-0" title="{{'event.statistic.salesDevice' | translate}}">
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="pie"
        field="amount"
        categoryField="name"
        [data]="statisticTurnoverData.sales.devices"
        [labels]="{visible: true, content: amountContent}">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  <kendo-chart #qtyDeviceChart *ngIf="statisticTurnoverData.sales.devices?.length > 1"
       class="pr-0" title="{{'event.statistic.qtyOrdersDevice' | translate}}">
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="pie"
        field="quantity"
        categoryField="name"
        [data]="statisticTurnoverData.sales.devices"
        [labels]="{visible: true, content: quantityContent}">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
</div>
<div class="p-4 bg-white" *ngIf="showStatistic && showChartChecked">
  <kendo-chart #turnoverChart *ngIf="allOrders?.length > 1" class="w-100"
      [pannable]="{ lock: 'y' }">
    <kendo-chart-title text="{{'event.statistic.turnoverTimeLog'|translate}}"></kendo-chart-title>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item
        [title]="{ text: 'event.statistic.tableTurnover' | translate } + eventCurrency"
        [max]="maxTurnover"
      >
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        [categories]="timeTurnoverCategories"
        [max]="40"
        [title]="{ text: 'event.statistic.time' | translate }"
      >
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-series-defaults [highlight]="{inactiveOpacity: 0.3}"></kendo-chart-series-defaults>
    <kendo-chart-series>
      <kendo-chart-series-item
      type="area"
      [data]="turnoverDataRounded">
      <kendo-chart-series-item-tooltip>
        <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
          <div>{{ 'event.statistic.salesLabel' | translate }}: {{value}} {{eventCurrency}} / {{eventTotalTurnover}} {{eventCurrency}}</div>
        </ng-template>
      </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
</div>
<div class="p-4 bg-white" *ngIf="showStatistic && showChartChecked">
  <kendo-chart #ordersChart *ngIf="this.ordersData?.length > 1" class="w-100"
    [pannable]="{ lock: 'y' }">
    <kendo-chart-title text="{{'event.statistic.ordersTimeLog'|translate}}"></kendo-chart-title>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item
        [title]="{ text: 'event.statistic.orders' | translate }"
        [max]="maxOrders"
      >
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        [categories]="timeOrdersCategories"
        [max]="40"
        [title]="{ text: 'event.statistic.time' | translate }"
      >
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-series-defaults [highlight]="{inactiveOpacity: 0.3}"></kendo-chart-series-defaults>
    <kendo-chart-series>
      <kendo-chart-series-item
      type="area"
      [data]="ordersDataRounded">
      <kendo-chart-series-item-tooltip>
        <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
          <div>{{ 'event.statistic.orders' | translate }}: {{value}} / {{ordersDataTotal}}</div>
        </ng-template>
      </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
</div>
<div class="p-4 bg-white" *ngIf="showStatistic && showChartChecked">
  <kendo-chart #salesTablesChart *ngIf="hasWaiters" class="w-100">
    <kendo-chart-title text="{{'event.statistic.salesTables'|translate}}"></kendo-chart-title>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        [data]="tableStats">
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value">
            {{ 'event.statistic.salesLabel' | translate }}: {{ value | currency : '':''}} {{eventCurrency}}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item
        max="{{tableStatsMaxValue}}"
        [title]="{ text: 'event.statistic.tableTurnover' | translate } + eventCurrency">
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        [title]="{ text: 'event.statistic.tableNumber' | translate}"
        [categories]="tableNumbersStats">
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
  </kendo-chart>
  <div fxLayout="row" fxLayoutAlign="center center" style="justify-content: center; display: flex">
    <button kendoButton [disabled]="tableStatsIsFirstPage" style="margin: 10px" (click)="onStatsPageChange(false)">&#60;</button>
    <div style="padding: 18px">{{ 'event.statistic.page' | translate }} {{tableStatsPage}} {{ 'event.statistic.pageOf' | translate }} {{tableStatsMaxPages}}</div>
    <button kendoButton [disabled]="tableStatsIsLastPage" style="margin: 10px" (click)="onStatsPageChange(true)">></button>
  </div>
</div>
<div class="p-4 bg-white" *ngIf="statisticTurnoverData?.sales.tables?.length > 1">
  <kendo-chart #qtyTablesChart class="w-100">
    <kendo-chart-title text="{{'event.statistic.qtyOrdersTable' | translate}}"></kendo-chart-title>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        [data]="ordersTable">
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value">
            {{ 'event.statistic.orders' | translate }}: {{ value }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item
        [title]="{ text: 'event.statistic.orders' | translate }"
        max="{{ordersTableMaxValue}}">
      </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        [title]="{ text: 'event.statistic.tableNumber' | translate}"
        [categories]="tableNumbersOrders">
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
  </kendo-chart>
  <div fxLayout="row" fxLayoutAlign="center center" style="justify-content: center; display: flex">
    <button kendoButton [disabled]="ordersTableIsFirstPage" style="margin: 10px" (click)="onOrdersPageChange(false)">&#60;</button>
    <div style="padding: 18px">{{ 'event.statistic.page' | translate }} {{ordersTablePage}} {{ 'event.statistic.pageOf' | translate }} {{ordersTableMaxPages}}</div>
    <button kendoButton [disabled]="ordersTableIsLastPage" style="margin: 10px" (click)="onOrdersPageChange(true)">></button>
  </div>
</div>
<kendo-chart #salesProductChart *ngIf="showStatistic && showChartChecked && chartData.products?.length > 0" class="w-100">
  <kendo-chart-title text="{{'event.statistic.salesProduct' | translate}}"></kendo-chart-title>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item [categories]="chartData.products.names"></kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-series>
    <kendo-chart-series-item
      type="bar"
      [data]="chartData.products.amounts"
      [color]="'#57c6f2'"
      [gap]="0.8">
      <kendo-chart-series-item-labels [format]="'{0} {{eventCurrency}}'"></kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
<!--
<div class="p-4 bg-white" *ngIf="showStatistic && showChartChecked">
  <kendo-chart #ordersChart *ngIf="this.ordersData?.length > 1" class="w-100">
    <kendo-chart-title text="{{'event.statistic.ordersTimeLog'|translate}}"></kendo-chart-title>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="scatterLine"
        [data]="ordersData"
        xField="date"
        yField="count">
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value">
            {{ value.x | date: 'dd/MM/yyyy HH:mm' }} - {{ value.y }} {{ 'event.statistic.orders' | translate }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-y-axis>
      <kendo-chart-y-axis-item
        [title]="{ text: 'event.statistic.orders' | translate }"
        [min]="0"
        [max]="maxOrdersCount"
        [labels]="{ format: '{0:N0}' }">
      </kendo-chart-y-axis-item>
    </kendo-chart-y-axis>
    <kendo-chart-x-axis>
      <kendo-chart-x-axis-item [type]="'date'" [labels]="{ format: 'dd/MM/yyyy HH:mm' }"></kendo-chart-x-axis-item>
    </kendo-chart-x-axis>
  </kendo-chart>
</div>
<div class="p-4 bg-white" *ngIf="showStatistic && showChartChecked">
  <kendo-chart #timeOrdersChart *ngIf="this.ordersData?.length > 1" class="w-100">
    <kendo-chart-title text="{{'event.statistic.ordersPerPeriod'|translate}}"></kendo-chart-title>
    <kendo-chart-legend position="bottom" orientation="vertical" [labels]="{content: dateContent}"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        *ngFor="let series of timeOrdersData"
        type="scatterLine"
        name="{{series.day}}"
        [data]="series.orders"
        xField="time"
        yField="count">
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value">
            {{ value.x }} - {{ value.y }} {{ 'event.statistic.orders' | translate }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-y-axis>
        <kendo-chart-y-axis-item
          [title]="{ text: 'event.statistic.orders' | translate }"
          [min]="0"
          [max]="maxOrdersCount"
          [labels]="{ format: '{0:N0}' }">
        </kendo-chart-y-axis-item>
      </kendo-chart-y-axis>
      <kendo-chart-x-axis>
        <kendo-chart-x-axis-item
          [title]="{ text: 'event.statistic.time' | translate }"
          [type]="'date'"
          [labels]="{ format: 'HH:mm' }">
        </kendo-chart-x-axis-item>
      </kendo-chart-x-axis>
  </kendo-chart>
  <div style="text-align: center;">
    <h5 class="card-title">{{'event.statistic.ordersPerPeriod_time_label'|translate}}</h5>
    <div style="display: flex; margin: 0 25%;">
      <kendo-timepicker
        [(ngModel)]="chartTimeRange.start"
        format="HH:mm"
        style="width:46%; margin:0 2%;"
      ></kendo-timepicker>
      <kendo-timepicker
        [(ngModel)]="chartTimeRange.end"
        format="HH:mm"
        style="width:46%; margin:0 2%;"
      ></kendo-timepicker>
      <button kendoButton themeColor="primary" (click)="buildFilteredTimeOrdersData()">
        {{'event.statistic.apply' | translate}}
      </button>
  </div>
  </div>
</div>
-->
</div>
<h3 style="color: #2ba8e3!important; text-align: center;"  class="mt-4" *ngIf="showStatistic">{{'event.statistic.resume' | translate}}</h3>
<div class="row mb-4" *ngIf="showStatistic">
  <div class="col">
    <kendo-grid
      [data]="gridData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="sortingSettings"
      [pageable]="{
        info: pagingSettings.info,
        type: pagingSettings.type,
        pageSizes: pagingSettings.pageSizes,
        previousNext: pagingSettings.previousNext,
        position: pagingSettings.position
      }"
      [reorderable]="false"
      [resizable]="false"
      [navigable]="true"
      [height]="800"
      filterable="menu"
      [columnMenu]="{ filter: true }"
      (dataStateChange)="dataStateChange($event)"
      [kendoGridExpandDetailsBy]="expandDetailsBy"
      [(expandedDetailKeys)]="expandedDetailKeys"
      (cellClick)="onCellClick($event)"
      (excelExport)="onExcelExport($event)"
    >
 <!--     <ng-template kendoGridToolbarTemplate>
        <kendo-grid-spacer></kendo-grid-spacer>
        <button kendoGridExcelCommand type="button" icon="file-excel">Export to Excel</button>
        <button kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
      </ng-template>-->
      <!-- ------------------ GRID ------------------ -->
      <kendo-grid-column field="order.table" title="{{'event.statistic.table' | translate}}"></kendo-grid-column>
      <kendo-grid-column field="order.createdAt" title="{{'event.statistic.dateLabel' | translate}}"></kendo-grid-column>
      <kendo-grid-column field="deviceUserName" title="{{'event.statistic.deviceUserName' | translate}}"></kendo-grid-column>
      <kendo-grid-column field="deviceType" title="{{'event.statistic.deviceUserType' | translate}}"></kendo-grid-column>
      <kendo-grid-column field="order.fiscalPrinted" title="{{'event.statistic.fiscalPrinted' | translate}}" *ngIf="hasFiscalPrinter"></kendo-grid-column>
      <kendo-grid-column field="order.amount" title="{{'event.statistic.totalAmount' | translate}}" format="{0} {{eventCurrency}}"></kendo-grid-column>
      <div *kendoGridDetailTemplate="let dataItem">
        <app-order-categories [orderCategories]="dataItem.order.orderCategory" [eventCurrency]="eventCurrency"></app-order-categories>
      </div>
      <!-- ----------------------------------------- -->
      <!--<kendo-grid-excel fileName="statistic.xlsx" [fetchData]="statisticTurnoverData.orders"></kendo-grid-excel>
      <kendo-grid-pdf
        class="kendo-grid-pdf"
        fileName="statistic.pdf"
        [allPages]="true"
        paperSize="A4"
        [repeatHeaders]="true"
        [landscape]="true"
        [scale]="0.6"
      >
        <kendo-grid-pdf-margin
          top="1cm"
          left="0.5cm"
          right="0.5cm"
          bottom="1cm">
        </kendo-grid-pdf-margin>
        <ng-template
          class="pdf-template"
          kendoGridPDFTemplate
          let-pageNum="pageNum"
          let-totalPages="totalPages">
          <div class="page-template">
            <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
          </div>
        </ng-template>
      </kendo-grid-pdf>-->
    </kendo-grid>
  </div>
</div>
<h3 *ngIf="statisticTurnoverData?.orders?.length === 0" style="text-align: center">{{'event.statistic.noData' | translate}}</h3>
<kendo-dialog
  title="{{'event.statistic.deleteDataDialogTitle' | translate}}"
  *ngIf="dialogOpened"
  (close)="closeDialog(false)"
  [minWidth]="250"
  [width]="450"
>
  <p style="margin: 30px; text-align: center;">
    {{'event.statistic.deleteDataMessage' | translate}}
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog(false)">{{'event.statistic.deleteDataNo' | translate}}</button>
    <button kendoButton (click)="closeDialog(true)" themeColor="primary">{{'event.statistic.deleteDataYes' | translate}}</button>
  </kendo-dialog-actions>
</kendo-dialog>
