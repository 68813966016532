<div class="row">
    <div class="col">
        <div class="jumbotron p-0">
            <h1 class="display-4" style="font-size: 30px">{{'event.advertiseEvent.title' | translate}}</h1>
            <p class="lead" [innerHTML]="'event.advertiseEvent.titleDescription' | translate"></p>
            <hr class="my-4">
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" [value]="true"
                [(ngModel)]="onlineEvent.onlinePublish" (change)="changeOnlineEventPublishValue()">
            <label class="custom-control-label" for="customSwitch1" style="padding-top: 3px;">
                <strong>{{'event.advertiseEvent.checkboxLabelEnableAdvertising' | translate}}</strong>
            </label>
        </div>
    </div>
</div>
<div *ngIf="onlineEvent.onlinePublish" style="margin-top: 10px">
    <div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">{{'event.advertiseEvent.timeDateTitle' | translate}}</h5>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>{{'event.advertiseEvent.startDate' | translate}}</label>
                            <input type="text" class="form-control form-control-sm" disabled="disabled"
                                value="{{startDateToShow | date:'dd-MM-yyyy'}}" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>{{'event.advertiseEvent.endDate' | translate}}</label>
                            <input type="text" class="form-control form-control-sm" disabled="disabled"
                                value="{{endDateToShow | date:'dd-MM-yyyy'}}" />
                        </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col" *ngFor="let item of startTimeArray">
                    <div class="form-group">
                       <label>{{'event.advertiseEvent.startTime' | translate}}
                         <u>{{item.date | date:'dd-MM-yyyy'}}</u></label>
                       <ngb-timepicker [(ngModel)]="item.time" [spinners]="false" [size]="'small'">
                       </ngb-timepicker>
                     </div>
                   </div>
                </div>
                <hr />
                <h5 class="card-title">{{'event.advertiseEvent.eventPicture' | translate}}<br />
                    <small>{{'event.advertiseEvent.eventPicturedescription' | translate}}</small>
                </h5>
                <div class="row">
                    <div class="col-12 text-center" *ngIf="imageUrl">
                        <div class="row">
                            <div class="col">
                                <img [src]="imageUrl" class="img-thumbnail mb-3" height="300" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="button" class="btn btn-sm btn-danger"
                                    (click)="removeImagePreview()">{{'event.advertiseEvent.removeImage' | translate}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label
                                class="control-label col-form-label-sm">{{'event.advertiseEvent.eventPicture' | translate}}</label>
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="customFile"
                                    (change)="handleImageFileInput($event)">
                                <label class="custom-file-label {{'shared.langCode' | translate}}"
                                    for="customFile">{{'event.advertiseEvent.choosePicture' | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="card-title">{{'event.advertiseEvent.describeEvent' | translate}}</h5>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="de-tab" data-toggle="tab" href="#de" role="tab"
                            aria-controls="de" aria-selected="true">{{'event.advertiseEvent.german' | translate}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="it-tab" data-toggle="tab" href="#it" role="tab" aria-controls="it"
                            aria-selected="false">{{'event.advertiseEvent.italian' | translate}}</a>
                    </li>
                </ul>
                <div class="tab-content mb-2" id="myTabContent">
                    <div class="tab-pane fade show active p-2 border border-top-0" id="de" role="tabpanel"
                        aria-labelledby="de-tab">
                        <app-simple-form-group-input label="{{'event.advertiseEvent.eventTitle' | translate}}"
                            [helpText]="'event.advertiseEvent.eventTitleHelpText' | translate"
                            [(model)]="onlineEvent.eventNameDe">
                        </app-simple-form-group-input>
                        <div class="form-group">
                            <label
                                class="control-label col-form-label-sm">{{'event.advertiseEvent.eventDescription' | translate}}</label>
                            <textarea [placeholder]="'event.advertiseEvent.eventDescriptionHelpText' | translate"
                                rows="10" [(ngModel)]="onlineEvent.eventDescriptionDe"
                                class="form-control form-control-sm"></textarea>
                        </div>
                    </div>
                    <div class="tab-pane fade p-2 border border-top-0" id="it" role="tabpanel" aria-labelledby="it-tab">
                        <app-simple-form-group-input label="{{'offers.request.eventTitle' | translate}}"
                            [helpText]="'offers.request.eventTitleHelpText' | translate"
                            [(model)]="onlineEvent.eventNameIt">
                        </app-simple-form-group-input>
                        <div class="form-group">
                            <label
                                class="control-label col-form-label-sm">{{'event.advertiseEvent.eventDescription' | translate}}</label>
                            <textarea [placeholder]="'event.advertiseEvent.eventDescriptionHelpText' | translate"
                                [(ngModel)]="onlineEvent.eventDescriptionIt" rows="10"
                                class="form-control form-control-sm"></textarea>
                        </div>
                    </div>
                </div>
                <hr />
                <h5 class="card-title" [innerHTML]="'event.advertiseEvent.locationTitle' | translate">
                </h5>
                <app-event-location-card [latitude]="onlineEvent.latitude" [longitude]="onlineEvent.longitude"
                    (onLocationChange)="onLocationChange($event)">
                </app-event-location-card>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-12 text-center" [hidden]="!showSuccessMsg">
                <strong class="text-success" [innerHTML]="'event.advertiseEvent.successMsg' | translate"></strong>
            </div>

            <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-primary"
                    (click)="submitOnlineEvent()">{{'event.advertiseEvent.save' | translate}}</button>
            </div>
        </div>
    </div>
</div>
