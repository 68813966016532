<div class="container mt-3" id="container">
    <div data-size="A4" id="container-print-deliverypaper1">
        <div>
          <div class="row">
              <div class="col" id="title-container">
                  <h2>{{'event.delivery-paper.deliveryPaper' | translate}}</h2>
              </div>
          </div>
          <div class="row mt-3">
              <div class="col-6">
                  <div class="card border" style="height: 150px;">
                      <div class="card-body">
                          <strong class="text-uppercase">{{'event.delivery-paper.sender' | translate}}</strong>
                          <div class="card-text" [innerHTML]="'event.delivery-paper.addressSender' | translate">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-6">
                <div class="card border" style="height: 80px">
                  <div class="card-body">
                    <strong class="text-uppercase">{{'event.delivery-paper.deliveryPaper' | translate}}</strong>
                    <div class="card-text">
                      <span class="text-uppercase">Nr.: <input type="text" /> {{'event.delivery-paper.of' | translate}} <input type="text" /></span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="row mt-1 ">
              <div class="col-6">
                  <div class="card border" style="height: 105px; margin-top: -5px">
                      <div class="card-body">
                          <div class="row" style="margin-top: -5px;">
                              <div class="col">
                                  <strong
                                      class="text-uppercase">{{'event.delivery-paper.eventName' | translate}}:</strong>
                                  <div class="card-text">
                                      {{event.eventName}}
                                  </div>
                                  <strong class="text-uppercase">{{'event.delivery-paper.date' | translate}}:</strong>
                                  <div class="card-text">
                                      {{event.startDate | date:'dd-MM-yyyy'}} / {{event.endDate | date:'dd-MM-yyyy'}}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-6">
                <div class="card border" style="height: 175px; margin-top: -75px">
                    <div class="card-body">
                        <strong class="text-uppercase">{{'event.delivery-paper.reciever' | translate}}</strong>
                        <div class="card-text">
                            {{event.clientUser.firstName}} {{event.clientUser.lastName}}<br />
                            {{event.clientUser.company.name}}
                            <br /> {{event.clientUser.company.address}} <br /> I -
                            {{event.clientUser.company.postalCode}}
                            <br /> {{event.clientUser.company?.village?.name}}
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="row mt-3">
              <div class="col">
                  <table class="table table-bordered" style="margin-top: -12px">
                      <thead>
                          <tr>
                              <th class="text-uppercase text-center" style="width: 70%; color: #000000;">
                                  {{'event.delivery-paper.productDescription' | translate}}
                              </th>
                              <th class="text-uppercase text-center" style="width: 10%; color: #000000;">
                                  {{'event.delivery-paper.software' | translate}}
                              </th>
                              <th class="text-uppercase text-center" style="width: 10%; color: #000000">
                                  {{'event.delivery-paper.hardware' | translate}}
                              </th>
                              <th class="text-uppercase text-center" style="width: 10%; color: #000000">
                                  {{'event.delivery-paper.service' | translate}}
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="waitersCount > 0 || waiterSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.waiter' | translate}}</td>
                            <td class="text-center">{{waiterSoftwareCount}}</td>
                            <td class="text-center">{{waitersCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="waitersCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{waitersCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="cashRegisterPhoneCount + cashRegisterTabletCount > 0 || cashRegisterSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.cashRegister' | translate}}</td>
                            <td class="text-center">{{cashRegisterSoftwareCount}}</td>
                            <td class="text-center">{{cashRegisterPhoneCount + cashRegisterTabletCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="cashRegisterPhoneCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{cashRegisterPhoneCount}}"</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="cashRegisterTabletCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.tabletCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{cashRegisterTabletCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="mainDevicePhoneCount + mainDeviceTabletCount > 0 || mainDeviceSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.mainDevices' | translate}}</td>
                            <td class="text-center">{{mainDeviceSoftwareCount}}</td>
                            <td class="text-center">{{mainDevicePhoneCount + mainDeviceTabletCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="mainDevicePhoneCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{mainDevicePhoneCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="mainDeviceTabletCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.tabletCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{mainDeviceTabletCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="printerCount > 0 || printerSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.printers' | translate}}</td>
                            <td class="text-center">{{printerSoftwareCount}}</td>
                            <td class="text-center">{{printerCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="fiscalPrinterCount > 0 || fiscalPrinterSoftwareCount">
                          <tr>
                            <td>{{'event.delivery-paper.fiscalPrinter' | translate}}</td>
                            <td class="text-center">{{fiscalPrinterSoftwareCount}}</td>
                            <td class="text-center">{{fiscalPrinterCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="printerCount > 0 || fiscalPrinterCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.printerCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{printerCount+fiscalPrinterCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container>
                          <tr *ngFor="let cable of cablesAmount">
                            <td>{{'event.delivery-paper.cable' | translate}} {{cable.length}}m</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{cable.amount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="routerCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.routers' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{routerCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container>
                          <tr *ngFor="let additionalCost of additionalCostsArray">
                            <ng-container *ngIf="additionalCost.id < 3 || additionalCost.id === 6 || additionalCost.id === 7 || additionalCost.id === 8 || additionalCost.id === 11">
                              <td>{{getadditionalCostLabel(additionalCost.name)}}</td>
                              <td class="text-center">-</td>
                              <td class="text-center">-</td>
                              <td class="text-center">{{additionalCost.quantityRequested}}</td>
                            </ng-container>
                            <ng-container *ngIf="additionalCost.id === 3 || additionalCost.id === 4 || additionalCost.id === 5 || additionalCost.id === 12">
                              <td>{{getadditionalCostLabel(additionalCost.name)}}</td>
                              <td class="text-center">-</td>
                              <td class="text-center">{{additionalCost.quantityRequested}}</td>
                              <td class="text-center">-</td>
                            </ng-container>
                            <ng-container *ngIf="additionalCost.id === 9 || additionalCost.id === 10">
                              <td>{{getadditionalCostLabel(additionalCost.name)}}</td>
                              <td class="text-center">{{additionalCost.quantityRequested}}</td>
                              <td class="text-center">-</td>
                              <td class="text-center">-</td>
                            </ng-container>
                          </tr>
                        </ng-container>
                      </tbody>
                  </table>
                  <div class="row mb-3">
                    <div class="col">
                      <div class="class border">
                        <div class="card-body text-uppercase" style="font-weight: bold;">
                          {{'event.delivery-paper.notes' | translate}}
                          <textarea class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <div *ngIf="hasHardware">
            <div class="row">
              <div class="col-8">
                <div class="class border">
                  <div class="card-body text-uppercase">
                    <b>{{'event.delivery-paper.transportTitle' | translate}}</b><br/><br/>
                    <div class="form-check form-check-inline" style="margin-right: 40px;">
                        <input class="form-check-input" type="checkbox" id="absender"
                            [checked]="!event.delivery" [disabled]="true">
                        <label class="form-check-label text-uppercase" for="absender"
                            style="color: #212529 !important;">{{'event.delivery-paper.pickupOurOffice' | translate}}</label>
                    </div>
                    <div class="form-check form-check-inline" style="margin-right: 40px;">
                        <input class="form-check-input" type="checkbox" id="lieferfirma"
                            [checked]="event.delivery" [disabled]="true">
                        <label class="form-check-label text-uppercase" for="lieferfirma"
                            style="color: #212529 !important;">{{'event.delivery-paper.sendByPost' | translate}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-8">
                <div class="class border">
                  <div class="card-body text-uppercase">
                    <ng-container *ngIf="event.delivery; else pickupPointTemplate">
                      <p class="text-center" style="font-size: 0.75rem !important;">
                          <strong>{{event.deliveryAddress?.firstLastName}}</strong><br />
                          {{event.deliveryAddress?.address}}<br />
                          {{event.deliveryAddress?.postcode}} {{deliveryAddress?.city}}<br />
                          {{event.deliveryAddress?.country.name}}<br />
                      </p>
                  </ng-container>
                  <ng-template #pickupPointTemplate>
                      <p class="text-center" style="font-size: 0.75rem !important;">
                          <strong>{{pickupPoint?.nameLangKey | translate}}</strong> <br />
                          {{pickupPoint?.addressLangKey | translate}} <br />
                          {{pickupPoint?.postCode}} {{pickupPoint?.cityLangKey | translate}}
                          <br />
                          {{pickupPoint?.country.name}}
                      </p>
                  </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="class border" style="height: 228px; margin-top: -99px; padding-top: 50px">
                  <div class="card-body text-uppercase">
                    <b>{{'event.delivery-paper.transportDate' | translate}}</b><br/>
                    <input style="width: 100%" type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-size="A4" id="container-print-deliverypaper2">
        <div>
          <div class="row">
              <div class="col">
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.routerName' | translate}}</th>
                          <th>{{'event.delivery-paper.networkName' | translate}}</th>
                          <th>{{'event.delivery-paper.networkPassword' | translate}}</th>
                          <th>{{'event.delivery-paper.routerType' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let router of event.routers"
                              style="page-break-inside: avoid; page-break-after: auto">
                              <td>{{router.name}}</td>
                              <td>{{router.networkName}}</td>
                              <td>{{router.networkPassword}}</td>
                              <td>{{(router.master === 0 ? 'event.delivery-paper.mainRouter' : 'event.delivery-paper.slaveRouter') | translate}}
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.printerName' | translate}}</th>
                          <th>{{'event.delivery-paper.printerCategory' | translate}}</th>
                          <th>{{'event.delivery-paper.deviceName' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let printerCat of printersCategoriesDeliveryPaper">
                              <td>{{printerCat.printerName}}</td>
                              <td>{{printerCat.categoryName}}</td>
                              <td>{{printerCat.deviceName}}</td>
                          </tr>
                      </tbody>
                  </table>
                  <table class="table table-bordered">
                      <thead style="text-align: center;">
                          <th>{{'event.delivery-paper.name' | translate}}</th>
                          <th>{{'event.delivery-paper.rule' | translate}}</th>
                          <th>{{'event.delivery-paper.username' | translate}}</th>
                          <th>{{'event.delivery-paper.accessCode' | translate}}</th>
                          <th>{{'event.delivery-paper.lvl1Code' | translate}}</th>
                          <th>{{'event.delivery-paper.deviceName' | translate}}</th>
                          <th *ngIf="showPayment">{{'event.details.showPayments' | translate}}</th>
                          <th *ngIf="showSales">{{'event.details.showSales' | translate}}</th>
                          <th *ngIf="showDoublePrint">{{'event.details.enableDoublePrint' | translate}}</th>
                          <th *ngIf="showDisablePrint">{{'event.details.paidWithoutPrint' | translate}}</th>
                          <th *ngIf="showSumUp">{{'event.details.enableSumup' | translate}}</th>
                          <th *ngIf="showVivaWallet">{{'event.details.enableVivaWallet' | translate}}</th>
                          <th *ngIf="showFiscalPrinter">{{'event.details.enableFiscalPrinter' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let deviceUser of event.deviceUsers"
                              style="page-break-inside: avoid; page-break-after: auto">
                              <td>{{deviceUser.name}}</td>
                              <td>{{deviceUser.deviceUserType.name | deviceTypeTranslation}}</td>
                              <td>{{deviceUser.username}}</td>
                              <td>{{deviceUser.pinCode}}</td>
                              <td>{{deviceUser.lvlOnePin}}</td>
                              <td>{{ (!deviceUser.device) ? 'N/D' : deviceUser.device.name }}</td>
                              <td *ngIf="showPayment" style="text-align: center; "><ng-container>{{deviceUser.enablePaymentScreen ? '✓': 'X'}}</ng-container></td>
                              <td *ngIf="showSales" style="text-align: center;"><ng-container>{{deviceUser.showSales ? '✓' : 'X'}}</ng-container></td>
                              <td *ngIf="showDoublePrint" style="text-align: center;"><ng-container>{{deviceUser.enableDoublePrint ? '✓' : 'X'}}</ng-container></td>
                              <td *ngIf="showDisablePrint" style="text-align: center;"><ng-container>{{deviceUser.paidWithoutPrint ? '✓' : 'X'}}</ng-container></td>
                              <td *ngIf="showSumUp" style="text-align: center;"><ng-container>{{deviceUser.enableSumUp ? '✓' : 'X'}}</ng-container></td>
                              <td *ngIf="showVivaWallet" style="text-align: center;"><ng-container>{{deviceUser.enableVivaWallet ? '✓' : 'X'}}</ng-container></td>
                              <td *ngIf="showFiscalPrinter" style="text-align: center;"><ng-container>{{deviceUser.hasFiscalPrinter ? '✓' : 'X'}}</ng-container></td>
                          </tr>
                      </tbody>
                  </table>
                  <blockquote class="blockquote mt-5">
                      <h4 class="mb-0 mt-0 text-center" [innerHTML]="'event.delivery-paper.warningMessage' | translate">
                      </h4>
                  </blockquote>
                  <blockquote class="blockquote mt-5">
                    <h4 class="mb-0 mt-0 text-center" [innerHTML]="'event.delivery-paper.videoQrCodeMessage' | translate"> </h4>
                    <img *ngIf="this.currentLanguage === 'de'" src="../../../../assets/img/qrcode_famulus_video_de.png" style="display:block; width:100px;height: auto; margin:0 auto;"/>
                    <img *ngIf="this.currentLanguage === 'it'" src="../../../../assets/img/qrcode_famulus_video_it.png" style="display:block; width:100px;height: auto; margin:0 auto;"/>
                </blockquote>
              </div>
          </div>
        </div>
    </div>
</div>
