import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PriceList } from 'src/app/shared/models/PriceList';
import { PriceListService } from '../shared/price-list.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { AccountService } from 'src/app/account/shared/account.service';
import { EventService } from 'src/app/event/shared/event.service';

@Component({
  selector: 'app-manage-price-list',
  templateUrl: './manage-price-list.component.html',
  styleUrls: ['./manage-price-list.component.css']
})
export class ManagePriceListComponent implements OnInit {

  public productCategoryForm: FormGroup;
  public errorArray = new Array();
  public priceListId: number;
  public priceList: PriceList = new PriceList();
  public priceListNameError = '';
  public clientErrorArray = Array();
  public productCategoryOptions: Option[] = [];
  public disableAddProductBtn = false;
  public unAssignedApprovedEventsArray = new Array();
  public companyId: number;
  public useStock = false;

  public eventCurrency = '€';

  constructor(private _accountService: AccountService, private _activatedRoute: ActivatedRoute, private _priceListService: PriceListService,
    private _navigation: NavigationService, private _formBuilder: FormBuilder, public jwtHelper: JwtHelperService, private _eventService: EventService,
    private _toastr: ToastrService, private _translate: TranslateService) {
    this.productCategoryForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
    });
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.priceListId = params['priceListId'];
      if (this.priceListId) {
        const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
        const tokenData = this.jwtHelper.decodeToken(accessToken).data;
        // this._currentClientId = tokenData.id;
        // this._currentClientId
        this._priceListService.getPriceListById(this.priceListId, tokenData.id).subscribe({
          next: (result) => {
            this.priceList = result;

            this._eventService.getEventForClient(this.priceList.event.id, tokenData.id).subscribe(res => {
              this.eventCurrency = res.deviceUsers[0].currency;
            })

            this.useStock = result.event.useStock;
            this._populateProductCategoryOptions();
          }, error: (error) => {
            console.log(error);
            if (error.status === 600 || error.status === 601) {
              this._navigation.priceListDashboard().go();
            }
          }
        });
      } else {
        this._navigation.priceListDashboard().go();
      }
    });
  }

  private _populateProductCategoryOptions() {
    this.productCategoryOptions = [];
    this.productCategoryOptions.push({ value: '', label: '' });
    this.priceList.productCategories.forEach(category => {
      this.productCategoryOptions.push({ value: category.id.toString(), label: category.name });
    });
  }

  onProductCategorySubmit() {
    this.errorArray = new Array();
    if (this.productCategoryForm.valid) {
      const newProductCategory = new ProductCategory();
      newProductCategory.name = this.productCategoryForm.value.name;
      newProductCategory.priceList = this.priceList;
      newProductCategory.status = 1;
      this._priceListService.insertNewProductCategory(newProductCategory).subscribe({
        next: (result) => {
          this.priceList.productCategories.push(result);
          // this.productCategoryOptions.push({ value: result.id.toString(), label: result.name });
          this.productCategoryForm.reset();
          this._toastr.success(this._translate.instant('price-list.manage.productCategoryAddSuccessMsg'));
        }, error: (error) => {
          console.log(error);
        }
      });
    }
    this.clientErrorArray = ValidationUtils.getFormValidationErrors(this.productCategoryForm, 'price-list.manage');
  }

  public beforeChange($event: NgbNavChangeEvent) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this.jwtHelper.decodeToken(accessToken).data;
    this._priceListService.getPriceListById(this.priceListId, tokenData.id).subscribe(result => {
      this.priceList = result;
      this._populateProductCategoryOptions();
    });
  }


  getProductEditMood(value: boolean) {
    this.disableAddProductBtn = value;
  }

  updatePriceListName() {
    this.priceListNameError = '';
    if (this.priceList.name !== '') {
      this._priceListService.updatePriceList(this.priceListId, this.priceList.name).subscribe({
        next: (result) => {
          this._toastr.success(this._translate.instant('price-list.manage.priceListNameUpdateSuccess'));
        }, error: (error) => {
          if (error.status === 605) {
            this.priceListNameError = 'price-list.manage.errorMsg.priceListNameExistError';
          }
        }
      });
    } else {
      this.priceListNameError = 'price-list.manage.errorMsg.required';
    }
  }

}
