import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { PriceListService } from './shared/price-list.service';
import { PRICELIST_ROUTES } from './price-list.routes';
import { PriceListDashboardComponent } from './price-list-dashboard/price-list-dashboard.component';
import { PriceListComponent } from './price-list.component';
import { ManagePriceListComponent } from './manage-price-list/manage-price-list.component';
import { CategoryTableComponent } from './manage-price-list/category-table/category-table.component';
import { ProductsTableComponent } from './manage-price-list/products-table/products-table.component';
import { AddProductFormComponent } from './manage-price-list/add-product-form/add-product-form.component';
import { EditProductFormComponent } from './manage-price-list/products-table/edit-product-form/edit-product-form.component';
// tslint:disable-next-line:max-line-length
import { ProductOptionFormComponent } from './manage-price-list/products-table/product-options/product-option-form/product-option-form.component';
// tslint:disable-next-line:max-line-length
import { ProductOptionTableComponent } from './manage-price-list/products-table/product-options/product-option-table/product-option-table.component';
// tslint:disable-next-line:max-line-length
import { ProductOptionUpdateFormComponent } from './manage-price-list/products-table/product-options/product-option-table/product-option-update-form/product-option-update-form.component';
import { ProductsBulkActionsComponent } from './manage-price-list/products-table/products-bulk-actions/products-bulk-actions.component';
import { PriceListSummeryPaperComponent } from './price-list-summery-paper/price-list-summery-paper.component';
import { MyPricelistsComponent } from './my-pricelists/my-pricelists.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
  imports: [
    RouterModule.forChild(PRICELIST_ROUTES),
    SharedModule,
    CoreModule,
    ButtonsModule
  ],
  declarations: [
    PriceListComponent,
    PriceListDashboardComponent,
    ManagePriceListComponent,
    CategoryTableComponent,
    ProductsTableComponent,
    AddProductFormComponent,
    EditProductFormComponent,
    ProductOptionFormComponent,
    ProductOptionTableComponent,
    ProductOptionUpdateFormComponent,
    ProductsBulkActionsComponent,
    PriceListSummeryPaperComponent,
    MyPricelistsComponent
  ],
  providers: [
    PriceListService
  ]
})
export class PriceListModule { }
