import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from 'src/app/shared/http-utils';
import { map } from 'rxjs/operators';
import { URL_BASE } from 'src/app/core/constants';
import { Client } from 'src/app/shared/models/Client';
import { Company } from 'src/app/shared/models/Company';
import { Observable } from 'rxjs';
import { Village } from 'src/app/shared/models/Village';
import { Survey } from 'src/app/shared/models/Survey';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private _http: HttpClient) { }

  /**
   * getSpecificClientById
   */
  public getSpecificClientById(clientId: number) {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/clientUser/details', { headers: headers, params: { id: clientId.toString() } }).pipe(
      map((res: Client) => {
        return Client.fromDto(res);
      }));
  }

  public getCompanyByClientId(clientId: number) {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/company/getByClientId', { headers: headers, params: { clientId: clientId.toString() } }).pipe(
      map((res: Company) => {
        return Company.fromDto(res);
      }));
  }

  /**
   * updateClient
   */
  public updateClient(client: Client) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/clientUser/update', { client: client }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public updateCompany(clientId: number, company: Company): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/company/update', { clientId, company}, { headers }) .pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * Update client password
   */
  public changeClientPassword(clientId: number, newPassword: string) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/clientUser/updatePassword', { clientId, newPassword }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public updateFirstLoginClientPassword(clientId: number, newPassword: string) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/clientUser/updatePassword', { clientId, newPassword, firstVisit: true }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
  * getVillages
  */
  public getAllVillages(): Observable<Village[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/villages/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Village.fromDto(res)))
    );
  }
  /**
  * getAllVillages
  */
  public getAllVillagesWithoutToken(): Observable<Village[]> {
    return this._http.get(URL_BASE + '/villages/getAll').pipe(
      map((result: any[]) => result.map(res => Village.fromDto(res)))
    );
  }

  /**
  * getSurveyOptions
  */
  public getSurveyOptions(): Observable<Survey[]> {
    return this._http.get(URL_BASE + '/survey/getOptions').pipe(
      map((result: Survey[]) => result.map(res => Survey.fromDto(res)))
    );
  }

  /**
  * saveSurveyAnswer
  */
  public saveSurveyAnswer(surveyAnswer: any) {
    return this._http.put(URL_BASE + '/surveyAnswer/save', { answer: surveyAnswer }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public getSurveyAnswers(clientId: number) {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/surveyAnswer/get', { headers: headers, params: { id: clientId } }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public updateClientLanguage(clientId: string, language: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/clientUser/updateLanguage', { clientId, language }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public getPriceListsByCompanyId(companyId: number, lastTwoYears: boolean = false) {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/priceList/getByCompanyId', { headers: headers, params: { companyId: companyId.toString(), lastTwoYears: lastTwoYears.toString() } }).pipe(
      map((res: any) => {
        return res;
      }));
  }

   /**
   * exportPriceList
   */
   public exportPriceList(priceListId: number, language: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/priceList/export', { id: priceListId, language }, {
      responseType: 'blob',
      headers: headers
    });
  }
}
