export const environment = {
  production: false,
  local: false,
  URL_BASE: 'https://famulusbackend.rrsolutionstest.it/api/v1/client',
  URL_IMAGES: 'https://famulusimages.rrsolutionstest.it/',
  COOKIE_DOMAIN: '.rrsolutionstest.it',
  MEETING_URL: 'https://us05web.zoom.us/j/81130983216?pwd=Y9ycFaJdXaxZb9pcs8FajMvb6EFlBk.1',
  MEETING_URL2: 'https://us05web.zoom.us/j/82909962985?pwd=ETI6jjuQSf0NrCFcuJEF10tFY6Roka.1',
  MEETING_URL3: 'https://us05web.zoom.us/j/82425652670?pwd=bQedYW0Qc1Pi1PbF146c64ZYeiwW0A.1'
};
