<div class="wrapper">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel">
    <app-top-navbar
      title="{{ 'offers.request.eventRequest' | translate }}"
    ></app-top-navbar>
    <div class="main-content container-fluid">
      <div
        *ngIf="userDrafts.length > 0"
        style="width: 100%; overflow-x: auto; margin-bottom: 15px"
      >
        <div
          class="row"
          style="
            padding-left: 15px;
            padding-right: 15px;
            flex-wrap: nowrap;
            display: inline-flex;
          "
        >
          <button
            class="btn btn-success"
            style="
              width: 120px;
              margin-right: 15px;
              padding-top: 15px;
              background-color: white;
            "
            (click)="newDraft()"
            onclick="this.blur();"
          >
            <div style="display: grid">
              <i class="fa fa-plus" style="justify-self: center"></i>
              <div
                class="disableSelect"
                style="text-align: center; font-size: 12px"
              >
                {{ "offers.request.newDraft" | translate }}
              </div>
            </div>
          </button>
          <button
            class="btn btn-primary"
            *ngFor="let draft of userDrafts"
            style="
              width: 120px;
              margin-right: 15px;
              margin-bottom: 0px;
              background-color: white;
              padding: 0px;
              display: flex;
              flex-direction: column;
            "
            [ngClass]="{
              'btn-primary': draft.id === currentDraft.id,
              'btn-secondary': draft.id !== currentDraft.id
            }"
            (click)="chooseDraft(draft)"
            onclick="this.blur();"
          >
            <div style="display: flex">
              <button
                class="btn"
                style="
                  padding: 0px;
                  margin-left: 100px;
                  border: none;
                  color: red;
                "
                (click)="openCloseDraftModal(draft)"
                onclick="this.blur();"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <div style="margin-top: -5px; padding-left: 5px; padding-right: 5px; margin-bottom: 10px">
              <div
                class="disableSelect"
                style="text-align: center; font-size: 12px"
              >
                {{ draft.eventName }}
              </div>
              <div
                class="disableSelect"
                style="text-align: center; font-size: 12px"
              >
                {{
                  (draft.updatedAt ? draft.updatedAt : draft.createdAt)
                    | date : "dd-MM-YYYY"
                }}
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row" style="justify-content: center">
            <div style="min-width: 64%; margin-right: 2%; margin-left: 3%">
              <!-- Event Info -->
               <form [formGroup]="infoForm" style="margin-bottom: 20px" (submit)="continue()">
                <!-- Event Name -->
                <div style="justify-self: center; width: 50%; text-align: center">
                  <div style="font-size: x-large">
                    {{ "offers.request.eventInsertName" | translate }}
                  </div>
                  <kendo-formfield>
                  <kendo-textbox
                    [(ngModel)]="currentDraft.eventName"
                    (modelChange)="onNameChange()"
                    formControlName="eventName"
                    required
                  >
                </kendo-textbox>
                <kendo-formerror>{{'offers.request.missingName' | translate}}</kendo-formerror>
              </kendo-formfield>
                </div>
                <!-- Event Date -->
                <div style="font-size: x-large; text-align: center">
                  {{ "offers.request.eventInsertDate" | translate }}
                </div>
                <div style="display: flex; justify-content: center; flex-wrap: wrap">
                  <kendo-formfield>
                    <kendo-label text="{{ 'offers.request.from' | translate }}">
                      <kendo-datepicker
                        [(value)]="selectionDateRange.start"
                        [format]="'dd-MM-yyyy'"
                        [min]="today"
                        style="width: 40%; min-width: 250px; margin: 20px"
                        placeholder="{{
                          'offers.request.startDateLabel' | translate
                        }}"
                        (valueChange)="onDateChange()"
                        formControlName="startDate"
                        required
                      >
                      <kendo-datepicker-messages today="{{'offers.request.today' | translate}}"></kendo-datepicker-messages>
                    </kendo-datepicker>
                    </kendo-label>
                    <kendo-formerror style="margin-top: -20px; margin-left: 40px">{{'offers.request.missingStart' | translate}}</kendo-formerror>
                  </kendo-formfield>
                    <kendo-formfield>
                    <kendo-label text="{{ 'offers.request.to' | translate }}">
                      <kendo-datepicker
                        [(value)]="selectionDateRange.end"
                        [format]="'dd-MM-yyyy'"
                        [min]="selectionDateRange.start ? selectionDateRange.start : today"
                        style="width: 40%; min-width: 250px; margin: 20px"
                        placeholder="{{
                          'offers.request.endDateLabel' | translate
                        }}"
                        (valueChange)="onDateChange()"
                        formControlName="endDate"
                        required
                      ></kendo-datepicker>
                    </kendo-label>
                    <kendo-formerror style="margin-top: -20px; margin-left: 40px">{{'offers.request.missingEnd' | translate}}</kendo-formerror>
                  </kendo-formfield>
                </div>
                <button
                *ngIf="!showTabs"
                class="btn btn-primary"
                style="
                  justify-self: center;
                  display: block;
                  margin-bottom: 20px;
                "
                type="submit"
              >
                {{ "offers.request.continue" | translate }}
              </button>
               </form>
              <!-- Software -->
              <div
                style="border: gray 1px solid; margin-bottom: 20px"
                [hidden]="!showTabs"
                [@fade]="showTabs ? 'visible' : 'hidden'"
              >
                <button
                  class="accordion"
                  style="
                    background-color: #57c6f2;
                    color: white;
                    font-size: large;
                  "
                  (click)="softwarePanelOpen = !softwarePanelOpen"
                >
                  <div class="row">
                    <div style="margin-left: 10px">
                      {{ "offers.request.softwareTitle" | translate }}
                    </div>
                    <i
                      *ngIf="!softwarePanelOpen"
                      class="fa fa-plus"
                      style="
                        margin-left: auto;
                        margin-right: 20px;
                        margin-top: 5px;
                      "
                    ></i>
                    <i
                      *ngIf="softwarePanelOpen"
                      class="fa fa-minus"
                      style="
                        margin-left: auto;
                        margin-right: 20px;
                        margin-top: 5px;
                      "
                    ></i>
                  </div>
                </button>
                <div *ngIf="softwarePanelOpen">
                  <div
                    class="row"
                    style="
                      padding: 10px;
                      font-size: large;
                      border-bottom: lightgray 1px solid;
                      justify-content: center;
                    "
                  >
                    <div>{{'offers.request.softwareInfoTitle' | translate}}</div>
                    <div
                    style="
                      margin-top: 3px;
                      margin-left: 10px;
                      padding: 1px;
                      width: 20px;
                      height: 20px;
                      background-color: #57c6f2;
                      border-radius: 30px;
                      text-align: center;
                      font-size: 12px;
                    "
                    [ngbTooltip]="'offers.request.softwareInfo' | translate"
                  >
                    <i class="fa fa-info" style="color: white"> </i>
                  </div>
                  </div>

                  <!-- Waiters -->
                  <div class="row" style="padding: 10px; margin: 0px">
                    <div style="font-size: medium; padding-top: 2px">
                      {{ "offers.request.waiterQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <kendo-numerictextbox
                        style="margin-right: 10px"
                        [style.width.px]="45"
                        [style.height.px]="32"
                        [min]="0"
                        [max]="99"
                        [maxLength]="2"
                        [decimals]="0"
                        [format]="'#'"
                        [spinners]="false"
                        [(ngModel)]="currentDraft.softwareWaiter"
                        (ngModelChange)="
                          onSoftwareLicenseQuantityChange('waiter')
                        "
                      ></kendo-numerictextbox>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="'offers.request.waiterDesc' | translate"
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- Cash Registers -->
                  <div
                    class="row"
                    style="
                      padding: 10px;
                      margin: 0px;
                      border-top: lightgray 1px solid;
                    "
                  >
                    <div style="font-size: medium; padding-top: 2px">
                      {{ "offers.request.cashRegisterQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <kendo-numerictextbox
                        style="margin-right: 10px"
                        [style.width.px]="45"
                        [style.height.px]="32"
                        [min]="0"
                        [max]="99"
                        [maxLength]="2"
                        [decimals]="0"
                        [format]="'#'"
                        [spinners]="false"
                        [(ngModel)]="currentDraft.softwareCashRegister"
                        (ngModelChange)="
                          onSoftwareLicenseQuantityChange('cashRegister')
                        "
                      ></kendo-numerictextbox>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.cashRegisterDesc' | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- Printers -->
                  <div
                    class="row"
                    style="
                      padding: 10px;
                      margin: 0px;
                      border-top: lightgray 1px solid;
                    "
                  >
                    <div style="font-size: medium; padding-top: 2px">
                      {{ "offers.request.printersQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <kendo-numerictextbox
                        style="margin-right: 10px"
                        [style.width.px]="45"
                        [style.height.px]="32"
                        [maxLength]="2"
                        [decimals]="0"
                        [min]="0"
                        [max]="99"
                        [format]="'#'"
                        [spinners]="false"
                        [(ngModel)]="currentDraft.softwarePrinter"
                        (ngModelChange)="
                          onSoftwareLicenseQuantityChange('printer')
                        "
                      ></kendo-numerictextbox>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="'offers.request.printersDesc' | translate"
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- Main Device -->
                  <div
                    class="row"
                    style="
                      padding: 10px;
                      margin: 0px;
                      border-top: lightgray 1px solid;
                    "
                  >
                    <div style="font-size: medium; padding-top: 2px">
                      {{ "offers.request.mainDeviceQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <div
                        class="form-group row"
                        style="
                          margin-right: 20px;
                          max-height: fit-content;
                          margin-top: 6px;
                          margin-bottom: 4px;
                        "
                      >
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="mainDeviceCheck"
                              [(ngModel)]="currentDraft.softwareMainDevice"
                              (change)="
                                onSoftwareLicenseQuantityChange('mainDevice')
                              "
                              id="mainDeviceCheckTrue"
                              [value]="1"
                            />
                            <label
                              class="form-check-label"
                              for="softwareLicenseCheckTrue"
                            >
                              {{ "offers.request.yes" | translate }}
                            </label>
                          </div>
                        </div>
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="mainDeviceCheck"
                              [(ngModel)]="currentDraft.softwareMainDevice"
                              (change)="
                                onSoftwareLicenseQuantityChange(
                                  'mainDevice',
                                  true
                                )
                              "
                              id="mainDeviceCheckFalse"
                              [value]="0"
                            />
                            <label
                              class="form-check-label"
                              for="mainDeviceCheckFalse"
                            >
                              {{ "offers.request.no" | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.mainDeviceDesc' | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- Stock Management -->
                  <div
                    [hidden]="currentDraft.softwareMainDevice !== 1"
                    [@fade]="
                      currentDraft.softwareMainDevice === 1
                        ? 'visible'
                        : 'hidden'
                    "
                    class="row"
                    style="padding: 10px; margin: 0px"
                  >
                    <i
                      class="fa fa-arrow-right"
                      style="padding-top: 7px; margin-right: 10px"
                    ></i>
                    <div style="font-size: medium; padding-top: 2px">
                      {{ "offers.request.stockManagementQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <div
                        class="form-group row"
                        style="
                          margin-right: 20px;
                          max-height: fit-content;
                          margin-top: 6px;
                          margin-bottom: 4px;
                        "
                      >
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="stockManagementCheck"
                              id="stockManagementCheckTrue"
                              [(ngModel)]="currentDraft.hasStockManagement"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="true"
                            />
                            <label
                              class="form-check-label"
                              for="stockManagementCheckTrue"
                            >
                              {{ "offers.request.yes" | translate }}
                            </label>
                          </div>
                        </div>
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="stockManagementCheck"
                              id="stockManagementCheckFalse"
                              [(ngModel)]="currentDraft.hasStockManagement"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="false"
                            />
                            <label
                              class="form-check-label"
                              for="stockManagementCheckFalse"
                            >
                              {{ "offers.request.no" | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.additionalCosts.stockManagement.description'
                            | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- Additional Costs Software -->
                  <div
                    class="row"
                    style="
                      padding: 10px;
                      margin: 0px;
                      border-top: lightgray 1px solid;
                    "
                  >
                    <div style="font-size: medium">
                      {{ "offers.request.onlinePayQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <div
                        class="form-group row"
                        style="
                          margin-right: 20px;
                          max-height: fit-content;
                          margin-top: 6px;
                          margin-bottom: 4px;
                        "
                      >
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="onlinePayCheck"
                              id="onlinePayCheckTrue"
                              [(ngModel)]="showOnlinePay"
                              (ngModelChange)="onOnlinePaySwitch(false)"
                              [value]="true"
                            />
                            <label
                              class="form-check-label"
                              for="onlinePayCheckTrue"
                            >
                              {{ "offers.request.yes" | translate }}
                            </label>
                          </div>
                        </div>
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="onlinePayCheck"
                              id="onlinePayCheckFalse"
                              [(ngModel)]="showOnlinePay"
                              (ngModelChange)="onOnlinePaySwitch(true)"
                              [value]="false"
                            />
                            <label
                              class="form-check-label"
                              for="onlinePayCheckFalse"
                            >
                              {{ "offers.request.no" | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="'offers.request.onlinePayTooltip' | translate"
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- VivaWallet -->
                  <div
                    [hidden]="!showOnlinePay"
                    [@fade]="showOnlinePay ? 'visible' : 'hidden'"
                    class="row"
                    style="margin: 0px; padding: 10px"
                  >
                    <i
                      class="fa fa-arrow-right"
                      style="padding-top: 8px; margin-right: 10px"
                    ></i>
                    <div style="font-size: medium; padding-top: 2px">
                      {{ "offers.request.vivaWalletQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <kendo-numerictextbox
                        style="margin-right: 10px"
                        [style.width.px]="45"
                        [style.height.px]="32"
                        [maxLength]="2"
                        [decimals]="0"
                        [min]="0"
                        [max]="99"
                        [format]="'#'"
                        [spinners]="false"
                        [(ngModel)]="currentDraft.vivaWalletLicenses"
                        (ngModelChange)="onAdditionalCostNumberInputChange()"
                      ></kendo-numerictextbox>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.additionalCosts.vivaWallet.description'
                            | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- SumUp -->
                  <div
                    [hidden]="!showOnlinePay"
                    [@fade]="showOnlinePay ? 'visible' : 'hidden'"
                    class="row"
                    style="margin: 0px; padding: 10px"
                  >
                    <i
                      class="fa fa-arrow-right"
                      style="padding-top: 8px; margin-right: 10px"
                    ></i>
                    <div style="font-size: medium; padding-top: 2px">
                      {{ "offers.request.sumUpQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <kendo-numerictextbox
                        style="margin-right: 10px"
                        [style.width.px]="45"
                        [style.height.px]="32"
                        [maxLength]="2"
                        [decimals]="0"
                        [min]="0"
                        [max]="99"
                        [format]="'#'"
                        [spinners]="false"
                        [(ngModel)]="currentDraft.sumUpLicenses"
                        (ngModelChange)="onAdditionalCostNumberInputChange()"
                      ></kendo-numerictextbox>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.additionalCosts.sumUp.description'
                            | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Rent Devices -->
              <div
                style="border: gray 1px solid; margin-bottom: 20px"
                [hidden]="!showTabs"
                [@fade]="showTabs ? 'visible' : 'hidden'"

              >
                <button
                  class="accordion"
                  style="
                    background-color: #57c6f2;
                    color: white;
                    font-size: large;
                  "
                  (click)="hardwarePanelOpen = !hardwarePanelOpen"
                >
                  <div class="row">
                    <div style="margin-left: 10px">{{'offers.request.hardwareTitle' | translate}}</div>
                    <i
                      *ngIf="!hardwarePanelOpen"
                      class="fa fa-plus"
                      style="
                        margin-left: auto;
                        margin-right: 20px;
                        margin-top: 5px;
                      "
                    ></i>
                    <i
                      *ngIf="hardwarePanelOpen"
                      class="fa fa-minus"
                      style="
                        margin-left: auto;
                        margin-right: 20px;
                        margin-top: 5px;
                      "
                    ></i>
                  </div>
                </button>
                <!-- Hardware -->
                <div *ngIf="hardwarePanelOpen">
                  <div
                  class="row"
                    style="
                      padding: 10px;
                      font-size: large;
                      border-bottom: lightgray 1px solid;
                      text-align: center;
                      justify-content: center;
                    "
                  >
                  <div>{{'offers.request.hardwareInfoTitle' | translate}}</div>
                  <div
                  style="
                    margin-top: 3px;
                    margin-left: 10px;
                    padding: 1px;
                    width: 20px;
                    height: 20px;
                    background-color: #57c6f2;
                    border-radius: 30px;
                    text-align: center;
                    font-size: 12px;
                  "
                  [ngbTooltip]="'offers.request.hardwareInfo' | translate"
                >
                  <i class="fa fa-info" style="color: white"> </i>
                </div>
                  </div>
                  <div
                    *ngIf="!showHardware"
                    style="justify-content: center; display: flex; margin: 20px"
                  >
                    <button
                      kendoButton
                      (click)="onRentDevicesClick()"
                      class="btn btn-primary"
                    >
                      {{'offers.request.rentDevicesButton' | translate}}
                    </button>
                  </div>
                  <div
                    [hidden]="!showHardware"
                    [@fade]="showHardware ? 'visible' : 'hidden'"
                  >
                    <!-- Rental Place -->
                    <div
                      style="
                        font-size: medium;
                        padding: 10px;
                        margin: 0px;
                        border-top: lightgray 1px solid;
                      "
                    >
                      {{ "offers.request.deliveryQuestion" | translate }}
                    </div>
                    <!-- Bozen -->
                    <div class="row" style="margin: 0px; padding: 10px">
                      <i
                        class="fa fa-arrow-right"
                        style="padding-top: 7px; margin-right: 10px"
                      ></i>
                      <div style="font-size: medium; padding-top: 1px">
                        {{ "offers.request.deliveryBozen" | translate }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: -20px"
                      >
                        <input
                          class="form-control form-control-sm"
                          style="
                            width: 20px;
                            margin-right: 20px;
                            text-align: center;
                          "
                          type="radio"
                          [value]="false"
                          [(ngModel)]="currentDraft.delivery"
                          (ngModelChange)="onPickupPointChange(1)"
                        />
                      </div>
                    </div>
                    <!-- Kurier -->
                    <div class="row" style="margin: 0px; padding: 10px">
                      <i
                        class="fa fa-arrow-right"
                        style="padding-top: 7px; margin-right: 10px"
                      ></i>
                      <div style="font-size: medium; padding-top: 1px">
                        {{ "offers.request.deliveryCourier" | translate }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: -20px"
                      >
                        <input
                          class="form-control form-control-sm"
                          style="
                            width: 20px;
                            margin-right: 20px;
                            text-align: center;
                          "
                          type="radio"
                          [value]="true"
                          [ngModel]="currentDraft.delivery"
                          (ngModelChange)="onPickupPointChange(3)"
                        />
                      </div>
                    </div>
                    <!-- No Devices -->
                     <div class="row" style="margin: 0px; padding: 10px">
                      <i
                        class="fa fa-arrow-right"
                        style="padding-top: 7px; margin-right: 10px"
                      ></i>
                      <div style="font-size: medium; padding-top: 1px">
                        {{ "offers.request.noDevices" | translate }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: -20px"
                      >
                        <input
                          class="form-control form-control-sm"
                          style="
                            width: 20px;
                            margin-right: 20px;
                            text-align: center;
                          "
                          type="radio"
                          [checked]="noDevices"
                          (click)="removeHardware()"
                        />
                      </div>
                     </div>
                    <!-- Phones -->
                    <div
                      class="row"
                      style="
                        padding: 10px;
                        margin: 0px;
                        border-top: lightgray 1px solid;
                      "
                    >
                      <div style="font-size: medium; padding-top: 2px">
                        {{ "offers.request.phoneQuestion" | translate }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <kendo-numerictextbox
                          style="margin-right: 10px"
                          [style.width.px]="45"
                          [style.height.px]="32"
                          [maxLength]="2"
                          [decimals]="0"
                          [min]="0"
                          [max]="99"
                          [format]="'#'"
                          [spinners]="false"
                          [(ngModel)]="currentDraft.hardwarePhone"
                          (ngModelChange)="onHardwareQuantityChange('phone')"
                        ></kendo-numerictextbox>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="'offers.request.phoneDesc' | translate"
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                      </div>
                    </div>
                    <!-- Printers -->
                    <div
                      class="row"
                      style="
                        padding: 10px;
                        margin: 0px;
                        border-top: lightgray 1px solid;
                      "
                    >
                      <div style="font-size: medium; padding-top: 2px">
                        {{ "offers.request.printerQuestion" | translate }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <kendo-numerictextbox
                          style="margin-right: 10px"
                          [style.width.px]="45"
                          [style.height.px]="32"
                          [maxLength]="2"
                          [decimals]="0"
                          [min]="0"
                          [max]="99"
                          [format]="'#'"
                          [spinners]="false"
                          [(ngModel)]="currentDraft.hardwarePrinter"
                          (ngModelChange)="
                            onHardwareQuantityChange('printer')
                          "
                        ></kendo-numerictextbox>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="
                            'offers.request.printerDesc' | translate
                          "
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                      </div>
                    </div>
                    <!-- Cables -->
                    <div
                      class="row"
                      style="
                        padding: 10px;
                        margin: 0px;
                        border-top: lightgray 1px solid;
                      "
                      [hidden]="currentDraft.hardwarePrinter <= 0"
                      [@fade]="
                        currentDraft.hardwarePrinter > 0 ? 'visible' : 'hidden'
                      "
                    >
                      <div style="font-size: medium">
                        {{ "offers.request.chooseCablesMsg1" | translate }}
                        {{ currentDraft.hardwarePrinter }}
                        {{ "offers.request.chooseCablesMsg2" | translate }}:
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <div
                          class="col"
                          style="margin-right: 15px; text-align: center"
                        >
                          <div
                            class="row"
                            style="justify-content: end; margin-bottom: 10px"
                          >
                            <div
                              class="row"
                              style="
                                width: 110px;
                                margin-left: 10px;
                                justify-content: end;
                              "
                            >
                              <div style="font-size: 14px; padding-top: 3px">
                                1m
                              </div>
                              <input
                                class="form-control form-control-sm"
                                style="
                                  width: 50px;
                                  margin-right: 10px;
                                  margin-left: 5px;
                                  text-align: center;
                                "
                                type="number"
                                min="0"
                                max="99"
                                [(ngModel)]="cableProductsAmount.one"
                                (change)="onHardwareQuantityChange()"
                              />
                            </div>
                            <div
                              class="row"
                              style="
                                width: 110px;
                                margin-left: 10px;
                                justify-content: end;
                              "
                            >
                              <div style="font-size: 14px; padding-top: 3px">
                                5m
                              </div>
                              <input
                                class="form-control form-control-sm"
                                style="
                                  width: 50px;
                                  margin-right: 10px;
                                  margin-left: 5px;
                                  text-align: center;
                                "
                                type="number"
                                min="0"
                                max="99"
                                [(ngModel)]="cableProductsAmount.five"
                                (change)="onHardwareQuantityChange()"
                              />
                            </div>
                            <div
                              class="row"
                              style="
                                width: 110px;
                                margin-left: 10px;
                                justify-content: end;
                              "
                            >
                              <div style="font-size: 14px; padding-top: 3px">
                                10m
                              </div>
                              <input
                                class="form-control form-control-sm"
                                style="
                                  width: 50px;
                                  margin-right: 10px;
                                  margin-left: 5px;
                                  text-align: center;
                                "
                                type="number"
                                min="0"
                                max="99"
                                [(ngModel)]="cableProductsAmount.ten"
                                (change)="onHardwareQuantityChange()"
                              />
                            </div>
                          </div>
                          <div class="row" style="justify-content: end">
                            <div
                              class="row"
                              style="
                                width: 110px;
                                margin-left: 10px;
                                justify-content: end;
                              "
                            >
                              <div style="font-size: 14px; padding-top: 3px">
                                20m
                              </div>
                              <input
                                class="form-control form-control-sm"
                                style="
                                  width: 50px;
                                  margin-right: 10px;
                                  margin-left: 5px;
                                  text-align: center;
                                "
                                type="number"
                                min="0"
                                max="99"
                                [(ngModel)]="cableProductsAmount.twenty"
                                (change)="onHardwareQuantityChange()"
                              />
                            </div>
                            <div
                              class="row"
                              style="
                                width: 110px;
                                margin-left: 10px;
                                justify-content: end;
                              "
                            >
                              <div style="font-size: 14px; padding-top: 3px">
                                30m
                              </div>
                              <input
                                class="form-control form-control-sm"
                                style="
                                  width: 50px;
                                  margin-right: 10px;
                                  margin-left: 5px;
                                  text-align: center;
                                "
                                type="number"
                                min="0"
                                max="99"
                                [(ngModel)]="cableProductsAmount.thirty"
                                (change)="onHardwareQuantityChange()"
                              />
                            </div>
                            <div
                              class="row"
                              style="
                                width: 110px;
                                margin-left: 10px;
                                justify-content: end;
                              "
                            >
                              <div style="font-size: 14px; padding-top: 3px">
                                50m
                              </div>
                              <input
                                class="form-control form-control-sm"
                                style="
                                  width: 50px;
                                  margin-right: 10px;
                                  margin-left: 5px;
                                  text-align: center;
                                "
                                type="number"
                                min="0"
                                max="99"
                                [(ngModel)]="cableProductsAmount.fifty"
                                (change)="onHardwareQuantityChange()"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="'offers.request.cable-desc' | translate"
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                      </div>
                    </div>
                    <!-- Routers -->
                    <div
                      class="row"
                      style="
                        padding: 10px;
                        margin: 0px;
                        border-top: lightgray 1px solid;
                      "
                    >
                      <div style="font-size: medium; padding-top: 2px">
                        {{ "offers.request.routerQuestion" | translate }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <kendo-numerictextbox
                          style="margin-right: 10px"
                          [style.width.px]="45"
                          [style.height.px]="32"
                          [maxLength]="2"
                          [decimals]="0"
                          [min]="0"
                          [max]="99"
                          [format]="'#'"
                          [spinners]="false"
                          [(ngModel)]="currentDraft.hardwareRouter"
                          (ngModelChange)="
                            onSoftwareLicenseQuantityChange('router')
                          "
                        ></kendo-numerictextbox>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="'offers.request.routerDesc' | translate"
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                      </div>
                    </div>
                    <!-- Additional Costs Hardware -->
                    <div
                      style="
                        font-size: medium;
                        padding: 10px;
                        margin: 0px;
                        border-top: lightgray 1px solid;
                      "
                    >
                      {{
                        "offers.request.additionalCosts.products" | translate
                      }}
                    </div>
                    <!-- Cash Drawer -->
                    <div class="row" style="margin: 0px; padding: 10px">
                      <i
                        class="fa fa-arrow-right"
                        style="padding-top: 7px; margin-right: 10px"
                      ></i>
                      <div style="font-size: medium; padding-top: 1px">
                        {{
                          "offers.request.additionalCosts.cashDrawer.title"
                            | translate
                        }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <kendo-numerictextbox
                          style="margin-right: 10px"
                          [style.width.px]="45"
                          [style.height.px]="32"
                          [maxLength]="2"
                          [decimals]="0"
                          [min]="0"
                          [max]="99"
                          [format]="'#'"
                          [spinners]="false"
                          [(ngModel)]="currentDraft.cashDrawers"
                          (ngModelChange)="onAdditionalCostNumberInputChange()"
                        ></kendo-numerictextbox>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="imgCashDrawer"
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                        <ng-template #imgCashDrawer>
                          <div style="width: 30%"></div>
                          <img
                            style="width: 100%; height: 100%"
                            src="/assets/img/CashDrawer.png"
                          />
                        </ng-template>
                      </div>
                    </div>
                    <!-- Belt Bag -->
                    <div class="row" style="margin: 0px; padding: 10px">
                      <i
                        class="fa fa-arrow-right"
                        style="padding-top: 7px; margin-right: 10px"
                      ></i>
                      <div style="font-size: medium; padding-top: 1px">
                        {{
                          "offers.request.additionalCosts.beltBag.title"
                            | translate
                        }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <kendo-numerictextbox
                          style="margin-right: 10px"
                          [style.width.px]="45"
                          [style.height.px]="32"
                          [maxLength]="2"
                          [decimals]="0"
                          [min]="0"
                          [max]="99"
                          [format]="'#'"
                          [spinners]="false"
                          [(ngModel)]="currentDraft.beltBags"
                          (ngModelChange)="onAdditionalCostNumberInputChange()"
                        ></kendo-numerictextbox>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="imgBeltBag"
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                        <ng-template #imgBeltBag>
                          <div style="width: 30%"></div>
                          <img
                            style="width: 100%; height: 100%"
                            src="/assets/img/BeltBag.png"
                          />
                        </ng-template>
                      </div>
                    </div>
                    <!-- Wallet -->
                    <div class="row" style="margin: 0px; padding: 10px">
                      <i
                        class="fa fa-arrow-right"
                        style="padding-top: 7px; margin-right: 10px"
                      ></i>
                      <div style="font-size: medium; padding-top: 1px">
                        {{
                          "offers.request.additionalCosts.wallet.title"
                            | translate
                        }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <kendo-numerictextbox
                          style="margin-right: 10px"
                          [style.width.px]="45"
                          [style.height.px]="32"
                          [maxLength]="2"
                          [decimals]="0"
                          [min]="0"
                          [max]="99"
                          [format]="'#'"
                          [spinners]="false"
                          [(ngModel)]="currentDraft.waiterWallets"
                          (ngModelChange)="onAdditionalCostNumberInputChange()"
                        ></kendo-numerictextbox>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="imgWaiterWallet"
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                        <ng-template #imgWaiterWallet>
                          <div style="width: 30%"></div>
                          <img
                            style="width: 100%; height: 100%"
                            src="/assets/img/WaiterWallet.png"
                          />
                        </ng-template>
                      </div>
                    </div>
                    <!-- Sticker -->
                    <div class="row" style="margin: 0px; padding: 10px">
                      <i
                        class="fa fa-arrow-right"
                        style="padding-top: 7px; margin-right: 10px"
                      ></i>
                      <div style="font-size: medium; padding-top: 1px">
                        {{
                          "offers.request.additionalCosts.sticker.title"
                            | translate
                        }}
                      </div>
                      <div
                        class="row"
                        style="margin-left: auto; margin-right: 0px"
                      >
                        <div
                          style="
                            font-size: 14px;
                            padding-top: 6px;
                            margin-right: 4px;
                          "
                        >
                          10 x
                        </div>
                        <kendo-numerictextbox
                          style="margin-right: 10px"
                          [style.width.px]="45"
                          [style.height.px]="32"
                          [maxLength]="2"
                          [decimals]="0"
                          [min]="0"
                          [max]="99"
                          [format]="'#'"
                          [spinners]="false"
                          [(ngModel)]="currentDraft.stickers"
                          (ngModelChange)="onAdditionalCostNumberInputChange()"
                        ></kendo-numerictextbox>
                        <div
                          style="
                            margin-top: 5px;
                            padding: 1px;
                            width: 20px;
                            height: 20px;
                            background-color: #57c6f2;
                            border-radius: 30px;
                            text-align: center;
                          "
                          [ngbTooltip]="
                            'offers.request.additionalCosts.sticker.description'
                              | translate
                          "
                        >
                          <i class="fa fa-info" style="color: white"> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Additional Costs Service -->
              <div
                style="margin-bottom: 20px; border: gray 1px solid"
                [hidden]="!showTabs"
                [@fade]="showTabs ? 'visible' : 'hidden'"
              >
                <button
                  class="accordion"
                  style="
                    background-color: #57c6f2;
                    color: white;
                    font-size: large;
                  "
                  (click)="onServicePanelClick()"
                >
                  <div class="row">
                    <div style="margin-left: 10px">{{'offers.request.serviceTitle' | translate}}</div>
                    <i
                      *ngIf="!servicePanelOpen"
                      class="fa fa-plus"
                      style="
                        margin-left: auto;
                        margin-right: 20px;
                        margin-top: 5px;
                      "
                    ></i>
                    <i
                      *ngIf="servicePanelOpen"
                      class="fa fa-minus"
                      style="
                        margin-left: auto;
                        margin-right: 20px;
                        margin-top: 5px;
                      "
                    ></i>
                  </div>
                </button>
                <div *ngIf="servicePanelOpen">
                  <!-- Upload Checking Pricelist -->
                  <div
                    class="row"
                    style="
                      margin: 0px;
                      padding: 10px;
                    "
                  >
                    <div style="font-size: medium">
                      {{
                        "offers.request.checkingPricelistQuestion" | translate
                      }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <div
                        class="form-group row"
                        style="
                          margin-right: 20px;
                          max-height: fit-content;
                          margin-top: 6px;
                          margin-bottom: 4px;
                        "
                      >
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="uploadPricelistCheck"
                              id="uploadPricelistCheckTrue"
                              [(ngModel)]="currentDraft.hasCheckingPriceList"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="true"
                            />
                            <label
                              class="form-check-label"
                              for="softwareLicenseCheckTrue"
                            >
                              {{ "offers.request.yes" | translate }}
                            </label>
                          </div>
                        </div>
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="uploadPricelistCheck"
                              id="uploadPricelistCheckFalse"
                              [(ngModel)]="currentDraft.hasCheckingPriceList"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="false"
                            />
                            <label
                              class="form-check-label"
                              for="mainDeviceCheckFalse"
                            >
                              {{ "offers.request.no" | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.additionalCosts.uploadingCheckingPricelist.description'
                            | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- Training -->
                  <div
                    class="row"
                    style="
                      margin: 0px;
                      padding: 10px;
                      border-top: lightgray 1px solid;
                    "
                  >
                    <div style="font-size: medium">
                      {{ "offers.request.trainingQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <div
                        class="form-group row"
                        style="
                          margin-right: 20px;
                          max-height: fit-content;
                          margin-top: 6px;
                          margin-bottom: 4px;
                        "
                      >
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="trainingCheck"
                              id="trainingCheckTrue"
                              [(ngModel)]="currentDraft.hasTraining"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="true"
                            />
                            <label
                              class="form-check-label"
                              for="softwareLicenseCheckTrue"
                            >
                              {{ "offers.request.yes" | translate }}
                            </label>
                          </div>
                        </div>
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="trainingCheck"
                              id="trainingCheckFalse"
                              [(ngModel)]="currentDraft.hasTraining"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="false"
                            />
                            <label
                              class="form-check-label"
                              for="mainDeviceCheckFalse"
                            >
                              {{ "offers.request.no" | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.additionalCosts.training.description'
                            | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                  <!-- Pickup Installed -->
                  <div
                    class="row"
                    style="
                      margin: 0px;
                      padding: 10px;
                      border-top: lightgray 1px solid;
                    "
                    [hidden]="!hasHardware"
                    [@fade]="hasHardware ? 'visible' : 'hidden'"
                  >
                    <div style="font-size: medium">
                      {{ "offers.request.pickUpInstalledQuestion" | translate }}
                    </div>
                    <div
                      class="row"
                      style="margin-left: auto; margin-right: 0px"
                    >
                      <div
                        class="form-group row"
                        style="
                          margin-right: 20px;
                          max-height: fit-content;
                          margin-top: 6px;
                          margin-bottom: 4px;
                        "
                      >
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="pickupInstalledCheck"
                              id="pickupInstalledCheckTrue"
                              [(ngModel)]="currentDraft.hasPickupInstalled"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="true"
                            />
                            <label
                              class="form-check-label"
                              for="softwareLicenseCheckTrue"
                            >
                              {{ "offers.request.yes" | translate }}
                            </label>
                          </div>
                        </div>
                        <div class="col text-center" style="max-width: 50px">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="pickupInstalledCheck"
                              id="pickupInstalledCheckFalse"
                              [(ngModel)]="currentDraft.hasPickupInstalled"
                              (change)="onAdditionalCostNumberInputChange()"
                              [value]="false"
                            />
                            <label
                              class="form-check-label"
                              for="mainDeviceCheckFalse"
                            >
                              {{ "offers.request.no" | translate }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          margin-top: 5px;
                          padding: 1px;
                          width: 20px;
                          height: 20px;
                          background-color: #57c6f2;
                          border-radius: 30px;
                          text-align: center;
                        "
                        [ngbTooltip]="
                          'offers.request.additionalCosts.pickupInstalled.description'
                            | translate
                        "
                      >
                        <i class="fa fa-info" style="color: white"> </i>
                      </div>
                    </div>
                  </div>
                <!-- Advertise Event -->
                <div
                  class="row"
                  style="
                    margin: 0px;
                    padding: 10px;
                    border-top: lightgray 1px solid;
                  "
                >
                  <div style="font-size: medium">
                    {{ "offers.request.advertiseQuestion" | translate }}
                  </div>
                  <div class="row" style="margin-left: auto; margin-right: 0px">
                    <div
                      class="form-group row"
                      style="
                        margin-right: 20px;
                        max-height: fit-content;
                        margin-top: 6px;
                        margin-bottom: 4px;
                      "
                    >
                      <div class="col text-center" style="max-width: 50px">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="advertiseCheck"
                            id="advertiseCheckTrue"
                            [(ngModel)]="currentDraft.isAdvertise"
                            (change)="onAdditionalCostNumberInputChange()"
                            [value]="true"
                          />
                          <label
                            class="form-check-label"
                            for="advertiseCheckTrue"
                          >
                            {{ "offers.request.yes" | translate }}
                          </label>
                        </div>
                      </div>
                      <div class="col text-center" style="max-width: 50px">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="advertiseCheck"
                            id="advertiseCheckFalse"
                            [(ngModel)]="currentDraft.isAdvertise"
                            (change)="onAdditionalCostNumberInputChange()"
                            [value]="false"
                          />
                          <label
                            class="form-check-label"
                            for="advertiseCheckFalse"
                          >
                            {{ "offers.request.no" | translate }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        margin-top: 5px;
                        padding: 1px;
                        width: 20px;
                        height: 20px;
                        background-color: #57c6f2;
                        border-radius: 30px;
                        text-align: center;
                      "
                      [ngbTooltip]="'offers.request.advertiseInfo' | translate"
                    >
                      <i class="fa fa-info" style="color: white"> </i>
                    </div>
                  </div>
                </div>
                <!-- Cancellation Insurance -->
                <div class="row" style="margin: 0px; padding: 10px; border-top: lightgray 1px solid;">
                  <div style="font-size: medium">
                    {{
                      "offers.request.cancellationInsuranceQuestion"
                        | translate
                    }}
                  </div>
                  <div
                    class="row"
                    style="margin-left: auto; margin-right: 0px"
                  >
                    <div
                      class="form-group row"
                      style="
                        margin-right: 20px;
                        max-height: fit-content;
                        margin-top: 6px;
                        margin-bottom: 4px;
                      "
                    >
                      <div class="col text-center" style="max-width: 50px">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="cancellationInsuranceCheck"
                            id="cancellationInsuranceCheckTrue"
                            [(ngModel)]="
                              currentDraft.hasCancellationInsurance
                            "
                            (change)="onAdditionalCostNumberInputChange()"
                            [value]="true"
                          />
                          <label
                            class="form-check-label"
                            for="softwareLicenseCheckTrue"
                          >
                            {{ "offers.request.yes" | translate }}
                          </label>
                        </div>
                      </div>
                      <div class="col text-center" style="max-width: 50px">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="cancellationInsuranceCheck"
                            id="cancellationInsuranceCheckFalse"
                            [(ngModel)]="
                              currentDraft.hasCancellationInsurance
                            "
                            (change)="onAdditionalCostNumberInputChange()"
                            [value]="false"
                          />
                          <label
                            class="form-check-label"
                            for="mainDeviceCheckFalse"
                          >
                            {{ "offers.request.no" | translate }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      style="
                        margin-top: 5px;
                        padding: 1px;
                        width: 20px;
                        height: 20px;
                        background-color: #57c6f2;
                        border-radius: 30px;
                        text-align: center;
                      "
                      [ngbTooltip]="
                        'offers.request.additionalCosts.badWeather.description'
                          | translate
                      "
                    >
                      <i class="fa fa-info" style="color: white"> </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <!-- Offer Price and Confirm -->
            </div>
            <div
              class="card-header"
              style="
                margin-left: 1%;
                height: fit-content;
                position: sticky;
                top: 40px;
                border: black 2px solid;
                border-radius: 5px;
                width: 300px;
              "
            >
              <b
                style="
                  justify-self: center;
                  display: block;
                  font-size: large;
                  margin-bottom: 5px;
                "
                >{{ "offers.request.eventOffer" | translate }}</b
              >
              <div
                class="row"
                style="margin: 0px"
                *ngIf="this.currentDraft.eventName"
              >
                <div style="font-weight: bold">
                  {{ "offers.request.summary.eventName" | translate }}:
                </div>
                <div style="margin-left: auto">
                  {{ this.currentDraft.eventName }}
                </div>
              </div>
              <div
                class="row"
                style="margin: 0px"
                *ngIf="this.currentDraft.startDate && this.currentDraft.endDate"
              >
                <div style="font-weight: bold">
                  {{ "offers.request.date" | translate }}:
                </div>
                <div style="margin-left: auto">
                  {{ this.currentDraft.startDate | date: 'dd-MM-YYYY'}} -
                  {{ this.currentDraft.endDate | date: 'dd-MM-YYYY' }}
                </div>
              </div>
              <table style="width: 100%; border-top: lightgray 1px solid">
                <thead>
                  <th *ngIf="hasSoftware || hasHardware || hasService">
                    {{'offers.request.products' | translate}}
                  </th>
                  <th
                    style="width: 10%; text-align: center"
                    *ngIf="hasSoftware"
                  >
                    SW
                  </th>
                  <th
                    style="width: 10%; text-align: center"
                    *ngIf="hasHardware"
                  >
                    HW
                  </th>
                  <th style="width: 10%; text-align: center" *ngIf="hasService">
                    {{'offers.request.service' | translate}}
                  </th>
                </thead>
                <tbody>
                  <tr *ngIf="currentDraft.softwareWaiter > 0">
                    <td>{{ "offers.request.waiters" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">
                      {{ currentDraft.softwareWaiter }}
                    </td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{
                        currentDraft.hardwarePhone < currentDraft.softwareWaiter
                          ? currentDraft.hardwarePhone
                          : currentDraft.softwareWaiter
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.softwareCashRegister > 0">
                    <td>{{ "offers.request.cashRegisters" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">
                      {{ currentDraft.softwareCashRegister }}
                    </td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ getCashRegistersHardware() }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.softwareMainDevice > 0">
                    <td>{{ "offers.request.mainDevices" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">
                      {{ currentDraft.softwareMainDevice }}
                    </td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ getMainDeviceHardware() }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.hasStockManagement">
                    <td>
                      {{
                        "offers.request.additionalCosts.stockManagement.title"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">-</td>
                    <td style="text-align: center" *ngIf="hasService">1</td>
                  </tr>
                  <tr
                    *ngIf="
                      currentDraft.hardwarePhone -
                        currentDraft.softwareWaiter -
                        currentDraft.softwareCashRegister -
                        currentDraft.softwareMainDevice >
                      0
                    "
                  >
                    <td style="display: flex">{{'offers.request.phoneCount' | translate}}
                      <div
                      style="
                        margin-top: 1px;
                        margin-left: 5px;
                        padding: 1px;
                        font-size: 10px;
                        width: 16px;
                        height: 16px;
                        background-color: orange;
                        border-radius: 30px;
                        text-align: center;
                      "
                      [ngbTooltip]="
                        'offers.request.deviceCountWarning'
                          | translate
                      "
                    >
                      <i class="fa fa-info" style="color: white; margin-left: -2px"> </i>
                    </div>
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{
                        currentDraft.hardwarePhone -
                          currentDraft.softwareWaiter -
                          currentDraft.softwareCashRegister -
                          currentDraft.softwareMainDevice
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr
                    *ngIf="
                      currentDraft.softwarePrinter > 0 ||
                      currentDraft.hardwarePrinter > 0
                    "
                  >
                    <td style="display: flex">{{ "offers.request.printers" | translate }}
                      <div
                      *ngIf="printerCountWarning"
                      style="
                        margin-top: 1px;
                        margin-left: 5px;
                        padding: 1px;
                        font-size: 10px;
                        width: 16px;
                        height: 16px;
                        background-color: orange;
                        border-radius: 30px;
                        text-align: center;
                      "
                      [ngbTooltip]="
                        'offers.request.deviceCountWarning'
                          | translate
                      "
                    >
                      <i class="fa fa-info" style="color: white; margin-left: -2px"> </i>
                    </div>
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">
                      {{ currentDraft.softwarePrinter }}
                    </td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ currentDraft.hardwarePrinter }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.hardwareRouter > 0">
                    <td>{{ "offers.request.routers" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ currentDraft.hardwareRouter }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.vivaWalletLicenses > 0">
                    <td>
                      {{
                        "offers.request.additionalCosts.vivaWallet.title"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">
                      {{ currentDraft.vivaWalletLicenses }}
                    </td>
                    <td style="text-align: center" *ngIf="hasHardware">-</td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.sumUpLicenses > 0">
                    <td>
                      {{
                        "offers.request.additionalCosts.sumUp.title" | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">
                      {{ currentDraft.sumUpLicenses }}
                    </td>
                    <td style="text-align: center" *ngIf="hasHardware">-</td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="cableProductsAmount.one > 0">
                    <td>{{ "offers.request.cable-1m" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ cableProductsAmount.one }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="cableProductsAmount.five > 0">
                    <td>{{ "offers.request.cable-5m" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ cableProductsAmount.five }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="cableProductsAmount.ten > 0">
                    <td>{{ "offers.request.cable-10m" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ cableProductsAmount.ten }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="cableProductsAmount.twenty > 0">
                    <td>{{ "offers.request.cable-20m" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ cableProductsAmount.twenty }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="cableProductsAmount.thirty > 0">
                    <td>{{ "offers.request.cable-30m" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ cableProductsAmount.thirty }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="cableProductsAmount.fifty > 0">
                    <td>{{ "offers.request.cable-50m" | translate }}</td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ cableProductsAmount.fifty }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.cashDrawers > 0">
                    <td>
                      {{
                        "offers.request.additionalCosts.cashDrawer.title"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ currentDraft.cashDrawers }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.beltBags > 0">
                    <td>
                      {{
                        "offers.request.additionalCosts.beltBag.title"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ currentDraft.beltBags }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.waiterWallets > 0">
                    <td>
                      {{
                        "offers.request.additionalCosts.wallet.title"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ currentDraft.waiterWallets }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.stickers > 0">
                    <td>
                      {{
                        "offers.request.additionalCosts.sticker.title"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">
                      {{ currentDraft.stickers * 10 }}
                    </td>
                    <td style="text-align: center" *ngIf="hasService">-</td>
                  </tr>
                  <tr *ngIf="currentDraft.hasCancellationInsurance">
                    <td>
                      {{
                        "offers.request.additionalCosts.badWeather.label"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">-</td>
                    <td style="text-align: center" *ngIf="hasService">
                      {{ currentDraft.hasCancellationInsurance ? 1 : 0 }}
                    </td>
                  </tr>
                  <tr *ngIf="currentDraft.hasCheckingPriceList">
                    <td>
                      {{
                        "offers.request.additionalCosts.uploadingCheckingPricelist.label"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">-</td>
                    <td style="text-align: center" *ngIf="hasService">
                      {{ currentDraft.hasCheckingPriceList ? 1 : 0 }}
                    </td>
                  </tr>
                  <tr *ngIf="currentDraft.hasTraining">
                    <td>
                      {{
                        "offers.request.additionalCosts.training.label"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">-</td>
                    <td style="text-align: center" *ngIf="hasService">
                      {{ currentDraft.hasTraining ? 1 : 0 }}
                    </td>
                  </tr>
                  <tr *ngIf="currentDraft.hasPickupInstalled">
                    <td>
                      {{
                        "offers.request.additionalCosts.pickupInstalled.label"
                          | translate
                      }}
                    </td>
                    <td style="text-align: center" *ngIf="hasSoftware">-</td>
                    <td style="text-align: center" *ngIf="hasHardware">-</td>
                    <td style="text-align: center" *ngIf="hasService">
                      {{ this.currentDraft.hasPickupInstalled ? 1 : 0 }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                *ngIf="!eventOffer.discountedPrice"
                style="margin: 10px; text-align: center"
              >
                {{ "offers.request.addProductsMsg" | translate }}
              </div>
              <div
                style="
                  text-align: right;
                  font-size: medium;
                  margin-bottom: 5px;
                  border-top: lightgray 1px solid;
                "
                *ngIf="eventOffer.discountedPrice"
              >
                <div class="row" style="margin: 0px">
                  <div style="font-weight: bold">
                    {{ "offers.request.price" | translate }}:
                  </div>
                  <div style="margin-left: auto; margin-right: 0px">
                    {{ eventOffer.discountedPrice | number : "1.2-2" }} € + 22%
                    {{ "event.request.vat" | translate }}
                  </div>
                </div>
              </div>
              <div *ngIf="!hasSoftware">
                <div
                  class="alert alert-warning"
                  style="margin-top: 20px; text-align: center"
                >
                  {{ "offers.request.completeData" | translate }}
                </div>
              </div>
              <div *ngIf="hasSoftware && currentDraft.delivery && !currentDraft.deliveryAddress" style="justify-self: center">
                <button class="btn btn-primary" (click)="openDeliveryModal()">{{'offers.request.addDelivery' | translate}}</button>
              </div>
              <div *ngIf="hasSoftware && (!currentDraft.delivery || (currentDraft.delivery && currentDraft.deliveryAddress))">
                <div *ngIf="currentDraft.delivery" style="justify-self: center; width: 100%; margin-bottom: 6px">
                  <div style="font-weight: bold; font-size: 16px;">{{'offers.request.deliveryAddress' | translate}}:</div>
                  <div class="row" style="margin: 0px">
                    <div style="margin-top: 6px; max-width: 70%">{{currentDraft.deliveryAddress.address}}</div>
                    <button class="btn btn-primary" style="margin-right: 0px; margin-left: auto; height: 30px; padding: 0px; padding-left: 5px; padding-right: 5px" (click)="openDeliveryModal()">{{'offers.request.change' | translate}}</button>
                  </div>
                </div>
                <div style="margin-bottom: 10px; margin-left: 18px">
                  <div
                    class="custom-control custom-switch col-12"
                    style="padding-left: 15px; padding-right: 15px"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitchTermsAndConditions"
                      [value]="true"
                      [(ngModel)]="acceptAGB"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitchTermsAndConditions"
                      style="font-size: 10px"
                    >
                      <strong style="line-height: 0px"
                        >
                        {{'offers.request.termsString1' | translate}}
                        <a
                          href="../../../assets/downloads/Privacy_{{
                            this.currentLanguage
                          }}.pdf"
                          target="_blank"
                          download="Privacy_{{ this.currentLanguage }}.pdf"
                          style="display: inline-block; padding-bottom: 10px"
                          >

                              {{
                                "offers.request.termsString2" | translate
                              }}</a
                        >
                        {{'offers.request.termsString3' | translate}}
                        <a
                          href="../../../assets/downloads/AGB_Famulus_{{
                            this.currentLanguage
                          }}.pdf"
                          target="_blank"
                          download="AGBFamulus_{{ this.currentLanguage }}.pdf"
                          style="display: inline-block; padding-bottom: 10px"
                          >
                              {{
                                "offers.request.termsString4"
                                  | translate
                              }}</a
                        >
                        {{'offers.request.termsString5' | translate}}
                        {{'offers.request.termsString6' | translate}}
                        </strong
                      >
                    </label>
                  </div>
                </div>
                <div *ngIf="showAGBError" style="margin-top: -10px; color: red; margin-left: 26px"><i>*{{'offers.request.agbError' | translate}}</i></div>
                <button
                  style="
                    display: block;
                    height: 30px;
                    padding: 0;
                    width: 70%;
                    justify-self: center;
                  "
                  class="btn btn-primary"
                  (click)="openOfferModal()"
                >
                  {{ "offers.request.submitOfferButton" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #closeDraftModal let-closeDraftModal>
  <div class="modal-body" style="padding: 20px">
    <div
      class="title"
      style="
        text-align: center;
        font-size: large;
        margin-bottom: 10px;
        font-weight: bold;
      "
    >
      {{ "offers.request.clearQuestion" | translate }}
    </div>
    <div class="content">
      <div style="text-align: center; font-size: large">
        {{ "offers.request.eventTitle" | translate }}:
      </div>
      <div style="text-align: center; font-size: large; font-weight: bold">
        {{ closeDraft.eventName }}
      </div>
      <div style="text-align: center; font-size: large">
        {{ "offers.request.eventUpdated" | translate }}:
      </div>
      <div
        style="
          text-align: center;
          font-size: large;
          font-weight: bold;
          margin-bottom: 10px;
        "
      >
        {{
          (closeDraft.updatedAt ? closeDraft.updatedAt : closeDraft.createdAt)
            | date : "dd-MM-YYYY"
        }}
      </div>
      <div class="row" style="justify-content: center">
        <button
          class="btn btn-secondary"
          (click)="closeDraftModal.close()"
          style="margin: 10px; width: 150px"
        >
          {{ "offers.request.cancel" | translate }}
        </button>
        <button
          class="btn btn-primary"
          (click)="clearDraft()"
          style="margin: 10px; width: 150px"
        >
          {{ "offers.request.clear" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #detailsRedirectModal let-detailsRedirectModal>
  <div class="modal-body" style="padding: 20px">
    <div
      class="title"
      style="
        text-align: center;
        font-size: large;
        margin-bottom: 10px;
        font-weight: bold;
      "
    >
      {{ "offers.request.missingDetailsTitle" | translate }}
    </div>
    <div class="content">
      <div style="text-align: center; font-size: large">
        {{ "offers.request.missingDetailsInfo" | translate }}:
      </div>
      <div class="row" style="justify-content: center">
        <button
          class="btn btn-secondary"
          (click)="detailsRedirectModal.close()"
          style="margin: 10px; width: 150px"
        >
          {{ "offers.request.cancel" | translate }}
        </button>
        <button
          class="btn btn-primary"
          (click)="toDetails()"
          style="margin: 10px; width: 150px"
        >
          {{ "offers.request.toDetails" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deliveryModal let-deliveryModal>
  <div class="modal-body" style="padding: 20px">
    <div style="font-size: large; text-align: center">
      {{'offers.request.deliveryAddress' | translate}}
    </div>
    <form
      class="k-form k-form-md"
      [formGroup]="deliveryForm"
      (ngSubmit)="addDeliveryAddress()"
    >
        <kendo-formfield>
          <kendo-label
            [for]="country"
            text="{{'offers.request.country' | translate}}"
            style="margin-bottom: 0px"
          >
          </kendo-label>
          <kendo-dropdownlist
            #country
            formControlName="country"
            [data]="allCountries"
            textField="name"
            valueField="name"
            object="true"
            required
            [(ngModel)]="selectedCountry"
          >
          </kendo-dropdownlist>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="selectedCountry?.id !== 0">
          <kendo-label [for]="city" text="{{'offers.request.city' | translate}}" style="margin-bottom: 0px">
          </kendo-label>
          <kendo-textbox formControlName="city" #city required> </kendo-textbox>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
      <div *ngIf="selectedCountry?.id !== 0" style="display: flex; justify-content: space-between">
        <kendo-formfield [style.width.%]="45">
          <kendo-label
            [for]="firstName"
            text="{{'offers.request.firstName' | translate}}"
            style="margin-bottom: 0px">
          </kendo-label>
          <kendo-textbox formControlName="firstName" #firstName required></kendo-textbox>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield [style.width.%]="45">
          <kendo-label
            [for]="lastName"
            text="{{'offers.request.lastName' | translate}}"
            style="margin-bottom: 0px">
          </kendo-label>
          <kendo-textbox formControlName="lastName" #firstName required></kendo-textbox>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
      </div>
      <div *ngIf="selectedCountry?.id !== 0" style="display: flex; justify-content: space-between">
        <kendo-formfield [style.width.%]="45" *ngIf="selectedCountry.id === 1">
          <kendo-label
            [for]="province"
            text="{{'offers.request.province' | translate}}"
            style="margin-bottom: 0px"
          >
          </kendo-label>
          <kendo-textbox formControlName="province" #province required>
          </kendo-textbox>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield [style.width.%]="45" *ngIf="selectedCountry?.id === 2">
          <kendo-label
            [for]="province"
            text="Bundesland"
            style="margin-bottom: 0px"
          >
          </kendo-label>
          <kendo-textbox formControlName="province" #province required>
          </kendo-textbox>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield [style.width.%]="45" *ngIf="selectedCountry?.id === 3">
          <kendo-label
            [for]="province"
            text="Landkreis"
            style="margin-bottom: 0px"
          >
          </kendo-label>
          <kendo-textbox formControlName="province" #province required>
          </kendo-textbox>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield [style.width.%]="45">
          <kendo-label
            [for]="postcode"
            text="{{'offers.request.postcode' | translate}}"
            style="margin-bottom: 0px"
          >
          </kendo-label>
          <kendo-textbox formControlName="postcode" #postcode required>
          </kendo-textbox>
          <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
        </kendo-formfield>
      </div>
      <kendo-formfield *ngIf="selectedCountry?.id !== 0">
        <kendo-label [for]="address" text="{{'offers.request.street' | translate}}" style="margin-bottom: 0px">
        </kendo-label>
        <kendo-textbox formControlName="address" #address required></kendo-textbox>
        <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield *ngIf="selectedCountry?.id !== 0">
        <kendo-label [for]="phone" text="{{'offers.request.phone' | translate}}" style="margin-bottom: 0px">
        </kendo-label>
        <kendo-textbox formControlName="phone" #phone required>
        </kendo-textbox>
        <kendo-formerror>{{'offers.request.requiredError' | translate}}</kendo-formerror>
      </kendo-formfield>
      <div class="k-form-buttons">
        <button
          kendoButton
          class="btn btn-secondary"
          (click)="deliveryModal.close()"
          themeColor="secondary"
        >
          {{'offers.request.cancel' | translate}}
        </button>
        <button
          kendoButton
          class="btn btn-primary"
          style="margin-right: 0px; margin-left: auto"
          type="submit"
        >
          {{'offers.request.save' | translate}}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #eventOfferModal let-eventOfferModal>
  <div class="modal-body">
    <div class="summary">
      <div class="title">
        <span class="h4">{{
          "offers.request.summary.noteTitle" | translate
        }}</span>
        {{ "offers.request.summary.noteSubTitle" | translate }}
      </div>
      <div class="content">
        <div class="row">
          <div class="element col-12">
            <div class="form-group">
              <textarea
                class="form-control"
                rows="4"
                [(ngModel)]="eventOffer.notes"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="summary">
      <div class="title">
        <span class="h4">{{ "offers.request.summary.title" | translate }}</span>
        {{ "offers.request.summary.subtitle" | translate }}
      </div>
      <div class="content">
        <div class="row">
          <div class="element col-12">
            <div class="label">
              {{ "offers.request.summary.eventName" | translate }}
            </div>
            <div class="data">{{ eventOffer.eventName }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.eventDates" | translate }}
            </div>
            <div class="data">
              {{ this.eventOffer.startDate | date : "dd/MM/yyyy" }} -
              {{ this.eventOffer.endDate | date : "dd/MM/yyyy" }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.hardwarePhone" | translate }}
            </div>
            <div class="data">{{ eventOffer.hardwarePhone }}</div>
          </div>
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.hardwarePrinter" | translate }}
            </div>
            <div class="data">{{ eventOffer.hardwarePrinter }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.hardwareRouter" | translate }}
            </div>
            <div class="data">{{ eventOffer.hardwareRouter }}</div>
          </div>
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.hardwareCable" | translate }}
            </div>
            <div class="data">
              {{
                cableProductsAmount.one +
                  cableProductsAmount.five +
                  cableProductsAmount.ten +
                  cableProductsAmount.fifteen +
                  cableProductsAmount.twenty +
                  cableProductsAmount.thirty +
                  cableProductsAmount.fifty
              }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.softwareWaiter" | translate }}
            </div>
            <div class="data">{{ eventOffer.softwareWaiter }}</div>
          </div>
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.softwareCashRegister" | translate }}
            </div>
            <div class="data">{{ eventOffer.softwareCashRegister }}</div>
          </div>
        </div>
        <div class="row">
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.softwareMainDevice" | translate }}
            </div>
            <div class="data">{{ eventOffer.softwareMainDevice }}</div>
          </div>
          <div class="element col-6">
            <div class="label">
              {{ "offers.request.summary.softwarePrinter" | translate }}
            </div>
            <div class="data">{{ eventOffer.softwarePrinter }}</div>
          </div>
        </div>
        <div class="row">
          <div class="additionalcosttitle col-12 text-center">
            <h5>
              <b
                ><u>{{
                  "offers.request.additionalCosts.mainTitle" | translate
                }}</u></b
              >
            </h5>
          </div>
          <ng-container
            *ngFor="let additionalCost of eventOffer.additionalCosts"
          >
            <div
              class="element col-12"
            >
              <div class="label">{{ getAdditionalCostTitle(additionalCost.id) }}</div>
              <div class="data">
                {{
                  additionalCost.quantity
                }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="element col-12 lead mt-0 h4">
            <div class="label">
              {{ "event.request.totalPrice" | translate }}:
            </div>
            <div class="data" style="color: #000000">
              {{ eventOffer.discountedPrice | currency : "€ " }}+
              {{ "event.request.vat" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="display: block">
    <div class="row">
      <div class="col">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="eventOfferModal.close()"
        >
          {{ "offers.request.missingRouterWarningStayButton" | translate }}
        </button>
      </div>
      <div class="col text-right">
        <button
          class="btn btn-outline-dark"
          type="button"
          (click)="submitEventOffer()"
        >
          {{ "offers.request.submitOfferButton" | translate }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        {{ "offers.request.submitOfferButtonBelowText" | translate }}
      </div>
    </div>
  </div>
</ng-template>
