import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/models/Event';
import { EventService } from '../../shared/event.service';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { Printer } from 'src/app/shared/models/Printer';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

class CableSizes {
  public length: number;
  public amount: number;
}

class DeviceUserPrinterCategory {
  public deviceUser: DeviceUser;
  public printerCategories: PrinterCategories[] = [];
}

class PrinterCategories {
  public printer: Printer;
  public productCategory: ProductCategory[] = [];
}

class PrintersCategoriesDeliveryPaper {
  public printerId: number;
  public printerName: string;
  public categoryName: string;
  public deviceName: string;
}

@Component({
  selector: 'app-delivery-paper',
  templateUrl: './delivery-paper.component.html',
  styleUrls: ['./delivery-paper.component.css']
})
export class DeliveryPaperComponent implements OnInit {

  public event: Event = new Event();
  public cablesAmount: CableSizes[] = [];
  public deviceUserPrinterCategory: DeviceUserPrinterCategory[] = [];
  public deviceUserPrinterCategorySorted: DeviceUserPrinterCategory[] = [];
  public printers: Printer[] = [];
  public printersCategoriesDeliveryPaper: PrintersCategoriesDeliveryPaper[] = [];

  public additionalCostsArray: AdditionalCost[];
  public currentLanguage = 'de';

  public waitersCount = 0;
  public cashRegisterPhoneCount = 0;
  public cashRegisterTabletCount = 0;
  public mainDevicePhoneCount = 0;
  public mainDeviceTabletCount = 0;
  public printerCount = 0;
  public fiscalPrinterCount = 0;
  public routerCount = 0;
  public upsCount = 0;

  public mainDeviceSoftwareCount = 0;
  public cashRegisterSoftwareCount = 0;
  public waiterSoftwareCount = 0;
  public showWaiterSoftwareCount = false;
  public printerSoftwareCount = 0;
  public fiscalPrinterSoftwareCount = 0;

  public showPayment = false;
  public showSales = false;
  public showDoublePrint = false;
  public showDisablePrint = false;
  public showSumUp = false;
  public showVivaWallet = false;
  public showFiscalPrinter = false;

  public hasHardware = false;

  public rentedPhones;
  public rentedTablets;

  public pickupPoint;

  constructor(private _eventService: EventService, private _translate: TranslateService) { }

  ngOnInit() {
    this.currentLanguage = this._translate.currentLang;
    this._eventService.currentEventObject.subscribe(result => {
      if (result) {
        this.event = result;
        if (this.event.clientUser.company.village) {
          this.event.clientUser.company.village.name = this.getLocationName(this.event.clientUser.company.village.name, this.event.language);
        }

        this.event.printersCategories.forEach(printCat => {
          let existingDeviceUser = this.deviceUserPrinterCategory.filter(devUsr => devUsr.deviceUser.id === printCat.deviceUser.id)[0];
          if (!existingDeviceUser) {
            existingDeviceUser = new DeviceUserPrinterCategory();
            existingDeviceUser.deviceUser = printCat.deviceUser;
            this.deviceUserPrinterCategory.push(existingDeviceUser);
          }
          let existingDeviceUserPrinterCat = existingDeviceUser.printerCategories.filter(printer =>
            printer.printer.id === printCat.printer.id)[0];
          if (!existingDeviceUserPrinterCat) {
            existingDeviceUserPrinterCat = new PrinterCategories();
            existingDeviceUserPrinterCat.printer = printCat.printer;
            existingDeviceUser.printerCategories.push(existingDeviceUserPrinterCat);
          }
          existingDeviceUserPrinterCat.productCategory.push(printCat.productCategory);

        });

        this._eventService.getPickupPointByEventId(this.event.id + "").subscribe(res => {
          this.pickupPoint = res;
        });

        this.countHardwareSoftware();
        this.checkDeviceUsers();

        this._eventService.getPrintersCategoriesDeliveryPaperByEventId(this.event.id.toString()).subscribe(records => {
          if (records) {
            records.forEach(rec => {
            this.printersCategoriesDeliveryPaper.push(
              {
                printerId: rec.printerId,
                printerName: rec.printerName,
                categoryName: rec.categoryName,
                deviceName: rec.deviceName,
              });
            });
          }
        });

        const requestedDevices: RequestedDevice[] = [];
        this.event.requestedDevices.forEach(elem => {
          const existingDeviceType = requestedDevices.filter(deviceType => deviceType.deviceTypes.id === elem.deviceTypes.id)[0];
          if (!existingDeviceType) {
            requestedDevices.push(elem);
          } else {
            existingDeviceType.quantity += elem.quantity;
          }
        });
        this.event.requestedDevices = requestedDevices;
        this.calculateCableAmount(this.event.requestedCable);

        forkJoin([
          this._eventService.getAdditionalCosts(),
          this._eventService.getAdditionalCostsAssigned(this.event.id.toString())
        ]).subscribe(res1 => {
          const additionalCostsArray = res1[0];
          const additionalCostsAssignedArray = res1[1];
          additionalCostsAssignedArray.forEach(rec => {
            additionalCostsArray.filter(item => item.id === rec.id)[0].quantityRequested = rec.quantity;
          });
          this.additionalCostsArray = additionalCostsArray.filter(item => item.quantityRequested > 0 && item.name !== 'cancellation');
        });
      }
    });
    this._eventService.updateCurrentActiveSubPage('deliveryPaper');
  }

  getLocationName(locationName: string, language: string) {
    return (language === 'de' ? locationName.split('/').pop() : locationName.split('/').slice(0, -1).join('/'));
  }

  calculateCableAmount(requestedCable: RequestedCable[]) {
    requestedCable.forEach(cable => {
      this.cablesAmount.push({ length: cable.cables.cableType.length, amount: cable.quantity });
    });
  }

  getadditionalCostLabel(name) {
    return this._translate.instant('offers.request.additionalCosts.' + name + '.title');
  }

  /**
   * Counts all the Hardwares and Softwares of the event and puts them into variables so they can be visualized on the delivery paper properly
   */

  countHardwareSoftware() {
    this.event.requestedSoftwareTypes.forEach(elem => {
      if (elem.softwareType.id === 1) { // waiter phones
        this.waiterSoftwareCount = elem.quantity;
        this.showWaiterSoftwareCount = true;
      }
      if (elem.softwareType.id === 2) { // cash register
        this.cashRegisterSoftwareCount = elem.quantity;
      }
      if (elem.softwareType.id === 3) { // main device
        this.mainDeviceSoftwareCount = elem.quantity;
      }
      if (elem.softwareType.id === 4) { // printer
        this.printerSoftwareCount = elem.quantity;
      }
      if (elem.softwareType.id === 5) { // fiscal printer
        this.fiscalPrinterSoftwareCount = elem.quantity;
      }
    });

    if (this.event.requestedDevices.length > 0) {

      let totalPhoneCount = 0;

      this.event.requestedDevices.filter(d => d.deviceTypes.id === 1).forEach(rd => {
        totalPhoneCount += rd.quantity;
        this.hasHardware = true;
      });

      if (this.waiterSoftwareCount > 0) {
        if (totalPhoneCount < this.waiterSoftwareCount) {
          this.waitersCount = totalPhoneCount;
        } else {
          this.waitersCount = this.waiterSoftwareCount;
        }
      }

      if (this.mainDeviceSoftwareCount > 0) {
        if (this.event.requestedDevices.filter(d => d.deviceTypes.id === 2)[0]?.quantity > 0) { // HW main device tablet
          this.mainDeviceTabletCount += 1;
        } else if (this.event.requestedDevices.filter(d => d.deviceTypes.id === 1)[0]?.quantity - this.waitersCount > 0) { // HW main device phone
          this.mainDevicePhoneCount += 1;
        }
      }

      if (this.cashRegisterSoftwareCount > 0) {
        if (this.event.requestedDevices.filter(d => d.deviceTypes.id === 2)[0]?.quantity - this.mainDeviceTabletCount > 0) { //HW Cash Register tablet
          this.cashRegisterTabletCount += this.cashRegisterSoftwareCount;
        } else if (this.event.requestedDevices.filter(d => d.deviceTypes.id === 1)[0]?.quantity - this.waitersCount - this.mainDevicePhoneCount > 0) { // HW Cash Register phone
          this.cashRegisterPhoneCount += this.cashRegisterPhoneCount;
        }
      }

    }

    this.event.requestedPrinterTypes.forEach(r => {
      if (r.printerType.isFiscalPrinter) {
        this.fiscalPrinterCount += r.quantity;
        this.hasHardware = true;
      } else {
        this.printerCount += r.quantity;
        this.hasHardware = true;
      }
    });

    this.event.requestedRouterTypes.forEach(r => {
      this.routerCount += r.quantity;
      this.hasHardware = true;
    });
  }

  checkDeviceUsers() {
    if (this.event.deviceUsers.filter(deviceUser => deviceUser.enablePaymentScreen).length > 0) {
      this.showPayment = true;
    } else {
      this.showPayment = false;
    }
    if (this.event.deviceUsers.filter(deviceUser => deviceUser.showSales).length > 0) {
      this.showSales = true;
    } else {
      this.showSales = false;
    }
    if (this.event.deviceUsers.filter(deviceUser => deviceUser.enableDoublePrint).length > 0) {
      this.showDoublePrint = true;
    } else {
      this.showDoublePrint = false;
    }
    if (this.event.deviceUsers.filter(deviceUser => deviceUser.paidWithoutPrint).length > 0) {
      this.showDisablePrint = true;
    } else {
      this.showDisablePrint = false;
    }
    if (this.event.deviceUsers.filter(deviceUser => deviceUser.enableSumUp).length > 0) {
      this.showSumUp = true;
    } else {
      this.showSumUp = false;
    }
    if (this.event.deviceUsers.filter(deviceUser => deviceUser.enableVivaWallet).length > 0) {
      this.showVivaWallet = true;
    } else {
      this.showVivaWallet = false;
    }
    if (this.event.deviceUsers.filter(deviceUser => deviceUser.hasFiscalPrinter).length > 0) {
      this.showFiscalPrinter = true;
    } else {
      this.showFiscalPrinter = false;
    }
  }

}
