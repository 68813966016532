import { Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { EventOffer } from "src/app/shared/models/EventOffer";
import { OffersService } from "../shared/offers.service";
import { EventCost } from "src/app/shared/models/EventCost";
import { forkJoin, switchMap } from "rxjs";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { setTime } from "@progress/kendo-angular-dateinputs/util";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LOCAL_STORAGE } from "src/app/core/constants";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { AccountService } from "src/app/account/shared/account.service";
import { Country } from "src/app/shared/models/Country";
import { Village } from "src/app/shared/models/Village";
import { EventOfferDraft } from "src/app/shared/models/EventOfferDraft";
import { Company } from "src/app/shared/models/Company";
import { DateRangePopupComponent, MultiViewCalendarComponent } from "@progress/kendo-angular-dateinputs";
import { DeliveryAddress } from "src/app/shared/models/DeliveryAddress";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CldrIntlService, IntlService } from "@progress/kendo-angular-intl";

@Component({
  selector: "app-request-offer2",
  templateUrl: "./request-offer2.component.html",
  styleUrl: "./request-offer2.component.css",
  animations: [
    trigger("fade", [
      state("visible", style({ opacity: 1 })),
      state("hidden", style({ opacity: 0 })),
      transition("visible <=> hidden", animate("1s ease-in-out")),
    ]),
  ],
})
export class RequestOffer2Component implements OnInit {
  private _tokenData: any;

  public currentDraft: EventOfferDraft = new EventOfferDraft();
  public userDrafts: EventOfferDraft[] = [];
  public eventOffer: EventOffer = new EventOffer();

  public deliveryAddress: DeliveryAddress = new DeliveryAddress();

  public cableProductsAmount = {
    one: 0,
    five: 0,
    ten: 0,
    fifteen: 0,
    twenty: 0,
    thirty: 0,
    fifty: 0,
  };

  public pickupPoints: any[] = [];

  public eventStartDate;
  public eventEndDate;

  public selectionDateRange = {
    start: null,
    end: null,
  };

  public showHardware: boolean = false;
  public hasHardware: boolean = false;
  public hasSoftware: boolean = false;
  public hasService: boolean = false;

  public showMainDevice: boolean = false;
  public showOnlinePay: boolean = false;

  public showTabs: boolean = false;

  public softwarePanelOpen: boolean = false;
  public servicePanelOpen: boolean = false;
  public hardwarePanelOpen: boolean = false;

  private _currentVat: number;

  public currentLanguage: string;

  public nameError = false;
  public startDateError = false;
  public endDateError = false;

  public printerCountWarning = false;

  public offerValid: boolean = false;
  public acceptAGB: boolean = false;

  public hasData: boolean = false;

  public clientCompany: Company = null;

  public isNewDraft: boolean = true;

  public closeDraft: EventOfferDraft = null;

  public allCountries: Country[] = [];

  public today = new Date();

  public noDevices = false;
  public showAGBError = false;

  public selectedCountry;

  public addServices = true;

  public infoForm: FormGroup = new FormGroup({
    eventName: new FormControl("", Validators.required),
    startDate: new FormControl("", Validators.required),
    endDate: new FormControl("", Validators.required),
  })

  public deliveryForm: FormGroup = new FormGroup({
    address: new FormControl("", Validators.required),
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    country: new FormControl("", Validators.required),
    postcode: new FormControl("", Validators.required),
    phone: new FormControl("", Validators.required),
    city: new FormControl("", Validators.required),
    province: new FormControl("", Validators.required),
  });

  @ViewChild("eventOfferModal", { static: true }) eventOfferModal: any;

  @ViewChild("closeDraftModal", { static: true }) closeDraftModal: any;

  @ViewChild("deliveryModal", { static: true }) deliveryModal: any;

  @ViewChild("detailsRedirectModal", { static: true }) detailsRedirectModal: any;

  @ViewChild("datePopup", { static: true }) datePopup: any;

  constructor(
    private _translate: TranslateService,
    private _offersService: OffersService,
    private _toastr: ToastrService,
    private _router: Router,
    private _jwtHelper: JwtHelperService,
    private _accountService: AccountService,
    private _modalService: NgbModal,
    public intlService: IntlService,
    private _route: ActivatedRoute
  ) {
    this.currentLanguage = this._translate.currentLang;
  }

  public eventDuration = 0;

  public showVillageFraction = false;
  public showGeneralLocationInput = false;

  public chosenVillage: Village;
  public chosenCountry: Country;

  public currentClient: any;

  ngOnInit() {
    (<CldrIntlService>this.intlService).localeId = this._translate.currentLang;
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    this._tokenData = this._jwtHelper.decodeToken(accessToken).data;
    this._offersService.getPickupPoints().subscribe((result) => {
      this.pickupPoints = result.filter((p) => p.id !== 2);
    });

    this._offersService.getAllDrafts().subscribe((result) => {
      this.userDrafts = result;
      if (this.userDrafts.length > 0) {
        this.currentDraft = this.userDrafts[0];
        this.initializeDraft();
      } else {
        this.newDraft();
      }

    },
    error => {
      if (error.status === 610) {
        this.currentDraft.language = this.currentLanguage;
        if (!this.currentDraft.pickupPoint) {
          this.currentDraft.pickupPoint = this.pickupPoints[0];
        }
      }
    });

    this._accountService.getCompanyByClientId(this._tokenData.id).subscribe((result) => {
      this.clientCompany = result;
    });

    this._offersService.getCountries().subscribe((result) => {
      this.selectedCountry = { id: 0, name: this._translate.instant('offers.request.selectCountry') };
      this.allCountries = result.filter(c => c.id === 1 || c.id === 2 || c.id === 3);
    });

    this._accountService.getSpecificClientById(this._tokenData.id).subscribe((result) => {
      this.currentClient = result;
    });
  }

  newDraft() {
    this.currentDraft = new EventOfferDraft();
    this.eventOffer = new EventOffer();
    this.showTabs = false;
    this.currentDraft.language = this.currentLanguage;
    this.currentDraft.pickupPoint = this.pickupPoints[0];
    this.selectionDateRange.start = null;
    this.selectionDateRange.end = null;
    this.showHardware = false;
    this.hasHardware = false;
    this.hasSoftware = false;
    this.hasService = false;
    this.softwarePanelOpen = false;
    this.servicePanelOpen = false;
    this.hardwarePanelOpen = false;
    this.offerValid = false;
    this.acceptAGB = false;
    this.hasData = false;
    this.isNewDraft = true;
    this.addServices = true;
  }

  initializeDraft() {
    this.hasData = true;
    this.isNewDraft = false;
    this.showTabs = true;
    if (
      this.currentDraft.startDate !== "Invalid date" ||
      this.currentDraft.endDate !== "Invalid date"
    ) {
      this.selectionDateRange.start = new Date(this.currentDraft.startDate);
      this.selectionDateRange.end = new Date(this.currentDraft.endDate);
    }

    this.currentDraft.language = this.currentLanguage;

    if (!this.currentDraft.pickupPoint) {
      this.currentDraft.pickupPoint = this.pickupPoints[0];
    }

    this.checkSoftware();
    this.checkHardware();
    this.checkService();

    if (this.hasHardware) {
      this.showHardware = true;
    }

    this._route.params.subscribe(params => {
      if (params['prefill']) {
        this.softwarePanelOpen = true;
      }
    });

    this.onDateChange();

    this.calculateEventOfferPrice();
  }

  calculateEventOfferPrice() {
    this.draftToOffer();

    this._offersService
      .calculateEventOfferPrice(
        this.eventOffer,
        this.eventDuration,
        this.currentDraft.pickupPoint.id,
        true
      )
      .subscribe((result) => {
        this._currentVat = result.vat;
        this.eventOffer.vat = this._currentVat * 100;
        this.eventOffer.fullPrice = EventCost.fromDtoPrice(result);
        this.eventOffer.discountedPrice = EventCost.fromDtoPrice(result);
        this.eventOffer.discount = 0;
        this.eventOffer.insuranceCost = result.insuranceCost;
        this._calculateVat();
      });
  }

  private _calculateVat() {
    const vatPrice: number =
      (this.eventOffer.discountedPrice / 100) * this.eventOffer.vat;
    this.eventOffer.discountedPriceWithVat =
      parseFloat(this.eventOffer.discountedPrice.toString()) +
      parseFloat(vatPrice.toString());
  }

  removeHardware() {
    this.noDevices = true;
    this.hasHardware = false;
    this.showHardware = false;
    this.currentDraft.hardwarePhone = 0;
    this.currentDraft.hardwarePrinter = 0;
    this.currentDraft.hardwarePrinter = 0;
    this.currentDraft.hardwareCable = "";
    this.currentDraft.beltBags = 0;
    this.currentDraft.cashDrawers = 0;
    this.currentDraft.waiterWallets = 0;
    this.currentDraft.stickers = 0;
    this.cableProductsAmount.one = 0;
    this.cableProductsAmount.five = 0;
    this.cableProductsAmount.ten = 0;
    this.cableProductsAmount.fifteen = 0;
    this.cableProductsAmount.twenty = 0;
    this.cableProductsAmount.thirty = 0;
    this.cableProductsAmount.fifty = 0;
    this.currentDraft.delivery = false;
    this.currentDraft.hasPickupInstalled = false;

    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  /**
   * Check if current draft contains any Software
   */

  checkSoftware() {
    if (
      this.currentDraft.softwareWaiter > 0 ||
      this.currentDraft.softwareCashRegister > 0 ||
      this.currentDraft.softwareMainDevice > 0 ||
      this.currentDraft.softwarePrinter > 0 ||
      this.currentDraft.vivaWalletLicenses > 0 ||
      this.currentDraft.sumUpLicenses > 0
    ) {
      this.hasSoftware = true;
    } else {
      this.hasSoftware = false;
    }
    if (this.currentDraft.softwareWaiter > 99) {
      this.currentDraft.softwareWaiter = 99;
    }
    if (this.currentDraft.softwareCashRegister > 99) {
      this.currentDraft.softwareCashRegister = 99;
    }
    if (this.currentDraft.softwarePrinter > 99) {
      this.currentDraft.softwarePrinter = 99;
    }
    if (this.currentDraft.vivaWalletLicenses > 99) {
      this.currentDraft.vivaWalletLicenses = 99;
    }
    if (this.currentDraft.sumUpLicenses > 99) {
      this.currentDraft.sumUpLicenses = 99;
    }

    if (this.currentDraft.softwarePrinter < this.currentDraft.hardwarePrinter) {
      this.printerCountWarning = true;
    } else {
      this.printerCountWarning = false;
    }
  }

  /**
   * Check if current draft contains any hardware
   */

  checkHardware() {
    if (
      this.currentDraft.hardwarePhone > 0 ||
      this.currentDraft.hardwarePrinter > 0 ||
      this.currentDraft.hardwareRouter > 0 ||
      this.currentDraft.beltBags > 0 ||
      this.currentDraft.cashDrawers > 0 ||
      this.currentDraft.waiterWallets > 0 ||
      this.currentDraft.stickers > 0
    ) {
      this.hasHardware = true;
    } else {
      this.hasHardware = false;
    }

    if (this.currentDraft.hardwarePhone > 99) {
      this.currentDraft.hardwarePhone = 99;
    }

    if (this.currentDraft.hardwarePrinter > 99) {
      this.currentDraft.hardwarePrinter = 99;
    }

    if (this.cableProductsAmount.one > 99) {
      this.cableProductsAmount.one = 99;
    }

    if (this.cableProductsAmount.five > 99) {
      this.cableProductsAmount.five = 99;
    }

    if (this.cableProductsAmount.ten > 99) {
      this.cableProductsAmount.ten = 99;
    }

    if (this.cableProductsAmount.twenty > 99) {
      this.cableProductsAmount.twenty = 99;
    }

    if (this.cableProductsAmount.thirty > 99) {
      this.cableProductsAmount.thirty = 99;
    }

    if (this.cableProductsAmount.fifty > 99) {
      this.cableProductsAmount.fifty = 99;
    }

    if (this.currentDraft.hardwareRouter > 99) {
      this.currentDraft.hardwareRouter = 99;
    }

    if (this.currentDraft.beltBags > 99) {
      this.currentDraft.beltBags = 99;
    }

    if (this.currentDraft.cashDrawers > 99) {
      this.currentDraft.cashDrawers = 99;
    }

    if (this.currentDraft.waiterWallets > 99) {
      this.currentDraft.waiterWallets = 99;
    }

    if (this.currentDraft.stickers > 99) {
      this.currentDraft.stickers = 99;
    }

    if (this.currentDraft.softwarePrinter < this.currentDraft.hardwarePrinter) {
      this.printerCountWarning = true;
    } else {
      this.printerCountWarning = false;
    }
  }

  /**
   * Check if current draft contains any services
   */

  checkService() {
    if (
      this.currentDraft.hasCancellationInsurance ||
      this.currentDraft.hasCheckingPriceList ||
      this.currentDraft.hasPickupInstalled ||
      this.currentDraft.hasStockManagement ||
      this.currentDraft.hasTraining
    ) {
      this.hasService = true;
    } else {
      this.hasService = false;
    }
  }

  onNameChange() {
    if (this.currentDraft.eventName && this.selectionDateRange.start && this.selectionDateRange.end) {
      this.saveDraft();
    }
  }

  onSoftwareLicenseQuantityChange(licenseType: string, negative?: boolean) {
    this.checkSoftware();

    switch (licenseType) {
      case "mainDevice":
        if (negative) {
          this.currentDraft.hasStockManagement = false;
          this.checkService();
        }
    }
    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  onHardwareQuantityChange() {
    this.currentDraft.hardwareCable = JSON.stringify(this.cableProductsAmount);
    this.checkHardware();

    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  public onAdditionalCostNumberInputChange() {
    this.checkSoftware();
    this.checkHardware();
    this.checkService();

    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  onPickupPointChange(pickupPointId: number) {
    this.currentDraft.pickupPoint = this.pickupPoints.filter(
      (pickup) => pickup.id === pickupPointId
    )[0];
    if (pickupPointId === 1) {
      this.currentDraft.delivery = false;
    } else {
      this.currentDraft.delivery = true;
    }
    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  submitEventOffer() {
    this._modalService.dismissAll();
    this._offersService
      .insertEventOffer(this.eventOffer, this._tokenData.firstEmail)
      .subscribe((result) => {
        this._offersService.closeDraft(this.currentDraft.id).subscribe((res) => {
          this._toastr.success(
            this._translate.instant("offers.request.eventSuccessRequest")
          );
          if (result.hasHardware) {
            if (this.eventOffer.isAdvertise) {
              return this._router.navigate([
                "/event/details/" + result.eventOfferId + "/advertising",
              ]);
            } else {
              return this._router.navigate(["/event/my-events"]);
            }
          } else {
            if (this.eventOffer.isAdvertise) {
              return this._router.navigate([
                "/event/details/" + result.eventId + "/advertising",
              ]);
            } else {
              return this._router.navigate([
                "/event/details/" + result.eventId + "/general",
              ]);
            }
          }
        });
      });
  }

  onDateChange() {
    if (this.selectionDateRange.start && this.selectionDateRange.end) {
      const startDate = moment(this.selectionDateRange.start);
      const endDate = moment(this.selectionDateRange.end);

      this.currentDraft.startDate = startDate.format("YYYY-MM-DD");
      this.currentDraft.endDate = endDate.format("YYYY-MM-DD");

      const today = moment().format("YYYY-MM-DD");
      if (today === this.currentDraft.startDate) {
        this.currentDraft.pickupDate = startDate.format("YYYY-MM-DD");
      } else {
        let subtract = 1;
        const startDay = new Date(startDate.format('YYYY-MM-DD'));
        // If Monday make pickupdate Friday
        if (startDay.getDay() === 1) {
          subtract = 3;
        }
        // If Sunday make pickupdate Friday
        if (startDay.getDay() === 7) {
          subtract = 2;
        }
        this.currentDraft.pickupDate = startDate
          .subtract(subtract, "day")
          .format("YYYY-MM-DD");
      }
      let add = 1
      const endDay = new Date(endDate.format('YYYY-MM-DD'));
      // If Friday make returndate Monday
      if (endDay.getDay() === 5) {
        add = 3;
      }
      // If Saturday make returndate Monday
      if (endDay.getDay() === 6) {
        add = 2;
      }
      this.currentDraft.returnDate = endDate.add(add, "day").format("YYYY-MM-DD");

      this.selectionDateRange.start.setHours(0, 0, 0, 0);
      this.selectionDateRange.end.setHours(0, 0, 0, 0);

      this.eventDuration =
        Math.floor(
          (this.selectionDateRange.end - this.selectionDateRange.start) /
            86400000
        ) + 1;

      this.calculateEventOfferPrice();
      if (this.showTabs) {
        this.saveDraft();
      }
    }
  }

  onCountryChosen(country: Country) {
    this.showVillageFraction = country?.id === 1; // Italy
    this.showGeneralLocationInput = !this.showVillageFraction;
    this.chosenCountry = country;
  }

  continue() {
    this.infoForm.markAllAsTouched();
    if (this.infoForm.valid) {
      this.showTabs = true;
      this.saveDraft();
    }
  }

  getCashRegistersHardware() {
    let cashRegisters =
      this.currentDraft.hardwarePhone - this.currentDraft.softwareWaiter;
    if (cashRegisters > this.currentDraft.softwareCashRegister) {
      cashRegisters = this.currentDraft.softwareCashRegister;
    }
    return cashRegisters >= 0 ? cashRegisters : 0;
  }

  getMainDeviceHardware() {
    const mainDevices =
      this.currentDraft.hardwarePhone -
      this.currentDraft.softwareWaiter -
      this.currentDraft.softwareCashRegister;
    return mainDevices > 0 ? 1 : 0;
  }

  onOnlinePaySwitch(remove: boolean) {
    if (remove) {
      this.currentDraft.vivaWalletLicenses = 0;
      this.currentDraft.sumUpLicenses = 0;
    }
  }

  onRentDevicesClick() {
    this.showHardware = true;
    this.currentDraft.hardwarePhone =
      this.currentDraft.softwareWaiter +
      this.currentDraft.softwareCashRegister +
      this.currentDraft.softwareMainDevice;
    this.currentDraft.hardwarePrinter = this.currentDraft.softwarePrinter;

    if (
      this.currentDraft.hardwarePhone > 0 ||
      this.currentDraft.hardwarePrinter > 0
    ) {
      this.hasHardware = true;
    }

    this.noDevices = false;

    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  getAdditionalCostsArr() {
    let additionalCostsArr: { id: number; quantity: number }[] = [];

    if (this.currentDraft.hasCancellationInsurance) {
      additionalCostsArr.push({ id: 1, quantity: 1 });
    }

    if (this.currentDraft.cashDrawers) {
      additionalCostsArr.push({
        id: 3,
        quantity: this.currentDraft.cashDrawers,
      });
    }

    if (this.currentDraft.beltBags > 0) {
      additionalCostsArr.push({ id: 4, quantity: this.currentDraft.beltBags });
    }

    if (this.currentDraft.waiterWallets > 0) {
      additionalCostsArr.push({
        id: 5,
        quantity: this.currentDraft.waiterWallets,
      });
    }

    if (this.currentDraft.hasPickupInstalled) {
      additionalCostsArr.push({ id: 6, quantity: 1 });
    }

    if (this.currentDraft.hasCheckingPriceList) {
      additionalCostsArr.push({ id: 7, quantity: 1 });
    }

    if (this.currentDraft.hasTraining) {
      additionalCostsArr.push({ id: 8, quantity: 1 });
    }

    if (this.currentDraft.vivaWalletLicenses > 0) {
      additionalCostsArr.push({
        id: 9,
        quantity: this.currentDraft.vivaWalletLicenses,
      });
    }

    if (this.currentDraft.sumUpLicenses > 0) {
      additionalCostsArr.push({
        id: 10,
        quantity: this.currentDraft.sumUpLicenses,
      });
    }

    if (this.currentDraft.hasStockManagement) {
      additionalCostsArr.push({ id: 11, quantity: 1 });
    }

    if (this.currentDraft.stickers > 0) {
      additionalCostsArr.push({ id: 12, quantity: this.currentDraft.stickers * 10 });
    }

    return additionalCostsArr;
  }

  draftToOffer() {
    this.eventOffer.eventName = this.currentDraft.eventName;
    this.eventOffer.startDate = this.currentDraft.startDate;
    this.eventOffer.endDate = this.currentDraft.endDate;
    this.eventOffer.pickUpDate = this.currentDraft.pickupDate;
    this.eventOffer.returnDate = this.currentDraft.returnDate;

    this.eventOffer.softwareWaiter = this.currentDraft.softwareWaiter;
    this.eventOffer.softwareCashRegister = this.currentDraft.softwareCashRegister;
    this.eventOffer.softwareMainDevice = this.currentDraft.softwareMainDevice;
    this.eventOffer.softwarePrinter = this.currentDraft.softwarePrinter;
    this.eventOffer.hardwarePhone = this.currentDraft.hardwarePhone;
    this.eventOffer.hardwarePrinter = this.currentDraft.hardwarePrinter;
    this.eventOffer.hardwareRouter = this.currentDraft.hardwareRouter;
    this.eventOffer.additionalCosts = this.getAdditionalCostsArr();
    this.eventOffer.hardwareCable = JSON.stringify(this.cableProductsAmount);
    this.eventOffer.pickupPoint = this.currentDraft.pickupPoint;
    this.eventOffer.village = this.chosenVillage;
    this.eventOffer.zone = this.currentDraft.zone;
    this.eventOffer.isAdvertise = this.currentDraft.isAdvertise;
    this.eventOffer.deliveryAddress = this.currentDraft.deliveryAddress;
    this.eventOffer.delivery = this.currentDraft.deliveryAddress ? true : false;

    this.eventOffer.company = this.clientCompany;

    if (this.eventOffer.notes) {
      this.eventOffer.notes = this.eventOffer.notes.replace(/\n/g, "<br />");
    }
  }

  openOfferModal() {
    if (this.acceptAGB) {
      this.showAGBError = false;
      const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
      const tokenData = this._jwtHelper.decodeToken(accessToken).data;

      this._accountService.getSpecificClientById(tokenData.id).subscribe(res => {
        if (res.firstEmail &&
            res.firstName &&
            res.lastName &&
            res.title &&
            res.firstPhone &&
            res.company &&
            res.company.country &&
            res.company.firstEmail &&
            res.company.firstPhone &&
            res.company.fiscalCode &&
            res.company.postalCode &&
            res.company.name
        ) {
          this.draftToOffer();
          this._modalService.open(this.eventOfferModal, { centered: true });
        } else {
          this._modalService.open(this.detailsRedirectModal, { centered: true });
        }
      });
    } else {
      this.showAGBError = true;
    }
  }

  toDetails() {
    this._modalService.dismissAll();
    this._router.navigateByUrl("/account");
  }

  saveDraft() {
    this._offersService.saveDraft(this.currentDraft).subscribe((res) => {
      this.currentDraft = res.draft;
      if (this.isNewDraft) {
        this.isNewDraft = false;
        this.userDrafts.push(this.currentDraft);
      }
    });
  }

  chooseDraft(draft: EventOfferDraft) {
    this.currentDraft = draft;
    this.initializeDraft();
  }

  openCloseDraftModal(draft: EventOfferDraft) {
    this.closeDraft = draft;
    this._modalService.open(this.closeDraftModal, { centered: true });
  }

  clearDraft() {
    this._offersService.closeDraft(this.closeDraft.id).subscribe((res) => {
      if (this.currentDraft.id === this.closeDraft.id) {
        this.newDraft();
      }
      this.userDrafts = this.userDrafts.filter(
        (draft) => draft.id !== this.closeDraft.id
      );
      this._modalService.dismissAll();
    });
  }

  openDeliveryModal() {
    if (this.currentDraft.deliveryAddress) {
      this.deliveryAddress = this.currentDraft.deliveryAddress;
      this.deliveryForm.patchValue(this.deliveryAddress);

      const space = this.deliveryAddress.firstLastName.indexOf(" ");

      this.deliveryForm.controls.firstName.setValue(this.deliveryAddress.firstLastName.substring(0, space));
      this.deliveryForm.controls.lastName.setValue(this.deliveryAddress.firstLastName.substring(space + 1, this.deliveryAddress.firstLastName.length));
    }
    this._modalService.open(this.deliveryModal, { centered: true });
  }

  addDeliveryAddress() {
    if (!this.deliveryForm.valid || this.deliveryForm.controls.country.value.id < 1) {
      return;
    }

    this.deliveryAddress = new DeliveryAddress();
    this.deliveryAddress.address = this.deliveryForm.controls.address.value;
    this.deliveryAddress.country = this.deliveryForm.controls.country.value;
    this.deliveryAddress.postcode = this.deliveryForm.controls.postcode.value;
    this.deliveryAddress.phone = this.deliveryForm.controls.phone.value;
    this.deliveryAddress.city = this.deliveryForm.controls.city.value;
    this.deliveryAddress.province = this.deliveryForm.controls.province.value;
    this.deliveryAddress.firstLastName = this.deliveryForm.controls.lastName.value + " " + this.deliveryForm.controls.firstName.value;

    this._offersService.saveDeliveryAddress(this.deliveryAddress).subscribe((result) => {
      this.currentDraft.deliveryAddress = result.deliveryAddress;
      this.saveDraft();
      this._modalService.dismissAll();
    });

  }

  onServicePanelClick() {
    this.servicePanelOpen = !this.servicePanelOpen;
    if (this.addServices) {
      console.log("test");
      this.addServices = false;
      this.currentDraft.hasCancellationInsurance = true;
      this.currentDraft.hasCheckingPriceList = true;
      if (this.hasHardware) {
        this.currentDraft.hasPickupInstalled = true;
      }
      this.currentDraft.hasTraining = true;
    }
    this.checkService();
    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  public getAdditionalCostTitle(additionalCost: number) {
    switch (additionalCost) {
      case 1: return this._translate.instant('offers.request.additionalCosts.badWeather.title');
      case 3: return this._translate.instant('offers.request.additionalCosts.cashDrawer.title');
      case 4: return this._translate.instant('offers.request.additionalCosts.beltBag.title');
      case 5: return this._translate.instant('offers.request.additionalCosts.wallet.title');
      case 6: return this._translate.instant('offers.request.additionalCosts.pickupInstalled.title');
      case 7: return this._translate.instant('offers.request.additionalCosts.uploadingCheckingPricelist.title');
      case 8: return this._translate.instant('offers.request.additionalCosts.training.title');
      case 9: return this._translate.instant('offers.request.additionalCosts.vivaWallet.title');
      case 10: return this._translate.instant('offers.request.additionalCosts.sumUp.title');
      case 11: return this._translate.instant('offers.request.additionalCosts.stockManagement.title');
      case 12: return this._translate.instant('offers.request.additionalCosts.sticker.title');
      default: return '';
    }
  }

}
