import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { Language } from '../language';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from 'src/app/account/shared/account.service';
import { CookieService } from 'src/app/shared/cookie.service';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Survey } from 'src/app/shared/models/Survey';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare const $: any;

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent implements OnInit {
  public languages = Language.getAvailableLanguages();
  public currentLanguage: any;
  public surveys: Survey[] = [];
  public errorArray = new Array();
  public surveyForm: FormGroup;
  public clientUserId: number;
  public inputActive = false;
  public missingSurveyErrorMsg = '';

  @Input()
  title: string;

  @ViewChild('completeSurveyModal', { static: true }) surveyModal: TemplateRef<any>;

  constructor(private _authService: AuthService, private _navigation: NavigationService, private _translate: TranslateService, private _formBuilder: FormBuilder,
    private _jwtHelper: JwtHelperService, private _accountService: AccountService, private _cookieService: CookieService, private intlService: IntlService, private _modalService: NgbModal) {
    this.currentLanguage = Language.fromLocale(this._translate.currentLang) || Language.fromLocale(this._translate.getDefaultLang());
    this.surveyForm = this._formBuilder.group({
          survey: [0, [Validators.required]],
          surveyDescription: ['']
    });
  }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const _tokenData = this._jwtHelper.decodeToken(accessToken).data;
    this.clientUserId = _tokenData.id;
    this._accountService.getSurveyAnswers(this.clientUserId).subscribe(res => {
      if (!res || res === null) {
        this._accountService.getSurveyOptions().subscribe(result => {
          this.surveys = result;
          this.surveys.forEach(item => {
            switch (this.currentLanguage.locale) {
              case 'it': item.name = item.nameIt;
                break;
              case 'de': item.name = item.nameDe;
                break;
              default: item.name = item.nameEn;
            }
          });
          this._modalService.open(this.surveyModal, { centered: true, backdrop: 'static', keyboard: false });
        });
      }
    });
  }

  logout(e) {
    // tslint:disable-next-line: deprecation
    e.preventDefault();
    this._authService.disconnect();
    this._cookieService.deleteCookie('clientCryptCode');
    this._navigation.login().go();
  }

  changeLanguage(language: Language) {
    this.currentLanguage = language;
    this._translate.use(this.currentLanguage.locale);
    localStorage.setItem('locale', this.currentLanguage.locale);
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const _tokenData = this._jwtHelper.decodeToken(accessToken).data;
    this._accountService.updateClientLanguage(_tokenData.id, this.currentLanguage.locale).subscribe(res => {
      (<CldrIntlService>this.intlService).localeId = this._translate.currentLang;
    });
  }

  isMobileMenu() {
    return $(window).width() <= 991;
  }

  completeSurvey() {
    if (this.surveyForm.controls['survey'].value !== 0) {
      this.missingSurveyErrorMsg = null;
      const surveyAnswer = {
        surveyId: this.surveyForm.controls['survey'].value,
        description: this.surveyForm.controls['surveyDescription'].value,
        clientUserId: this.clientUserId
      }
      this._accountService.saveSurveyAnswer(surveyAnswer).subscribe(res => {
        this._modalService.dismissAll();
      });
    } else {
      this.missingSurveyErrorMsg = this._translate.instant('account.errorMsg.missingSurvey');
    }
  }

  onCheckBoxClick(id: any) {
    if (id === 7) {
      this.inputActive = true;
    } else {
      this.inputActive = false;
      this.surveyForm.get('surveyDescription').setValue('');
    }
  }

}
