import { Component, OnInit, ViewChild } from '@angular/core';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from 'src/app/account/shared/account.service';
import { PriceListService } from '../shared/price-list.service';
import { TranslateService } from '@ngx-translate/core';
import { PriceList } from 'src/app/shared/models/PriceList';
import * as moment from 'moment';
import { NavigationService } from 'src/app/shared/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Option } from 'src/app/shared/simple-form-group-select/simple-form-group-select.component';

@Component({
  selector: 'app-price-list-dashboard',
  templateUrl: './price-list-dashboard.component.html',
  styleUrls: ['./price-list-dashboard.component.css']
})
export class PriceListDashboardComponent implements OnInit {

  public newPriceListToUpload: File = null;
  public priceListName: string;
  public uploadPriceListName: string;
  public priceListEventId: string;
  public uploadPriceListEventId: string;
  public priceListNameError = '';
  public priceListEventError = '';
  public newPriceListNameError = '';
  public newPriceListEventError = '';
  public todaysDate = moment().format('YYYY-MM-DD');
  public page = 1;
  public pageSize = 5;
  public priceListCollectionSize: number;
  public clientPriceLists: PriceList[] = [];
  public unAssignedApprovedEventsArray: Option[] = [];
  public priceListUploadError = undefined;
  private _currentClientId: number;
  private _selectedPriceListId: number;
  public companyId: number;
  public companyName: string;
  @ViewChild('confirmDuplicatePriceListDialog', { static: true })
  confirmDuplicatePriceListDialog;

  @ViewChild('confirmDeletePriceListDialog', { static: true })
  confirmDeletePriceListDialog;

  constructor(public jwtHelper: JwtHelperService, private _accountService: AccountService, private _priceListService: PriceListService,
              private _translate: TranslateService, private _navigation: NavigationService, private modalService: NgbModal,
              private _toastr: ToastrService) {
  }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this.jwtHelper.decodeToken(accessToken).data;
    this._currentClientId = tokenData.id;
    this._accountService.getSpecificClientById(this._currentClientId).subscribe({
      next: (result) => {
        this.companyId = result.company.id;
        this.companyName = result.company.name;
        this._priceListService.getParentPriceLists(this._currentClientId, this.companyId).subscribe({
          next: (priceListsResult) => {
            this.priceListCollectionSize = priceListsResult.length;
            this.clientPriceLists = priceListsResult;
            this._getUnAssignedApprovedEventsArray();
          }, error: (error) => {
            console.log(error);
          }
        });
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  get priceLists(): PriceList[] {
    return this.clientPriceLists
      .map((priceList, i) => ({id: i + 1, ...priceList}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }


  getPriceListNameUpload(value) {
    this.uploadPriceListName = this.unAssignedApprovedEventsArray.filter(event => event.value === value)[0].label;
  }

  getPriceListNameNew(value) {
    this.priceListName = this.unAssignedApprovedEventsArray.filter(event => event.value === value)[0].label;
  }

  private _getUnAssignedApprovedEventsArray() {
    this.unAssignedApprovedEventsArray = [];
    this._priceListService.getUnAssignedApprovedEvents(this.companyId).subscribe({
      next: (events) => {
        events.forEach(event => {
          this.unAssignedApprovedEventsArray.push({value: event.id.toString(), label: event.eventName});
        });
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  uploadNewPriceListFile() {
    this.priceListNameError = '';
    this.priceListEventError = '';
    this.priceListUploadError = undefined;

    if (this.uploadPriceListEventId == null) {
      this.priceListEventError = this._translate.instant('price-list.dashboard.priceListEvntError');
      return;
    }
    if (this.uploadPriceListName == null) {
      this.priceListNameError = this._translate.instant('price-list.dashboard.priceListNameError');
      return;
    }
    if (this.newPriceListToUpload === null) {
      this.priceListNameError = this._translate.instant('price-list.dashboard.fileUploadError');
      return;
    }
    this._priceListService.uploadNewPriceListToEvent(
      this.newPriceListToUpload, this._currentClientId.toString(), this.companyId.toString(), this.uploadPriceListName,
      this.uploadPriceListEventId).subscribe({
      next: (result) => {
        this.clientPriceLists.push(PriceList.fromDto(result));
        this.uploadPriceListName = '';
        this._toastr.success(this._translate.instant('price-list.dashboard.priceListUploadSuccessMsg'));
        this.uploadPriceListEventId = null;
        this._getUnAssignedApprovedEventsArray();
        this.priceListCollectionSize++;
      }, error: (error) => {
        if (error.status === 603) {
          this._toastr.error(
            this._translate.instant('price-list.dashboard.pricelistUploadErrorOne') + ' ' + error.error.index + ' ' +
            this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col);
          return;
        }
        if (error.status === 604) {
          this._toastr.error(
            this._translate.instant('price-list.dashboard.pricelistUploadErrorTwo') + ' ' + error.error.index + ' ' +
            this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col);
          return;
        }
        if (error.status === 605) {
          this._toastr.error(
            this._translate.instant('price-list.dashboard.pricelistUploadErrorThree') + ' ' + error.error.index + ' ' +
            this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col);
          return;
        }
        if (error.status === 606) {
          this._toastr.error(
            this._translate.instant('price-list.dashboard.pricelistUploadErrorFour') + ' ' + error.error.index + ' ' +
            this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col);
          return;
        }
        // this._toastr.error(error.error);
        this.priceListUploadError = error.error;
      }
    });
  }

  handlePriceListFileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    this.newPriceListToUpload = files.item(0);
    this.uploadPriceListName = this.newPriceListToUpload.name;
  }

  createNewPriceList() {
    this.newPriceListNameError = '';
    if (this.priceListName == null) {
      this.newPriceListNameError = this._translate.instant('price-list.dashboard.priceListNameError');
      return;
    }
    this._priceListService.insertPriceListWithEvent(this.priceListName, this._currentClientId,
      this.priceListEventId, this.companyId.toString()).subscribe({
      next: (result) => {
        this._navigation.editPriceList(result.id).go();
      }, error: (error) => {
        console.log(error);
        if (error.status === 606) {
          this.newPriceListNameError = this._translate.instant('price-list.dashboard.priceListNameAlreadyExist');
        }
      }
    });
  }

  duplicatePriceListClickEvent(priceListId: number) {
    this._selectedPriceListId = priceListId;
    this.confirmDuplicatePriceListDialog.openDialog();
  }

  getDuplicatePriceListDecision(decision: boolean) {
    if (decision) {
      this._priceListService.duplicatePriceList(this._selectedPriceListId, this._currentClientId, this.companyId)
        .subscribe({
          next: (result) => {
            this.clientPriceLists.push(result);
            this._toastr.success(this._translate.instant('price-list.dashboard.priceListDuplicateSuccessMsg'));
          }, error: (error) => {
            console.log(error);
          }
        });
    }
    this._selectedPriceListId = undefined;
  }

  showDeleteConfermationDialog(priceListId: number) {
    this._selectedPriceListId = priceListId;
    this.confirmDeletePriceListDialog.openDialog();
  }

  getDeletePriceListDecision(decision: boolean) {
    if (decision) {
      this._priceListService.deletePriceList(this._selectedPriceListId).subscribe({
        next: (result) => {
          if (this.clientPriceLists.length === 1) {
            this.clientPriceLists = [];
          } else {
            const index = this.clientPriceLists.findIndex(pricel => pricel.id === this._selectedPriceListId);
            this.clientPriceLists.splice(index, 1);
          }
          this._selectedPriceListId = undefined;
          this._getUnAssignedApprovedEventsArray();
        }, error: (error) => {
          console.log(error);
          this._selectedPriceListId = undefined;
        }
      });
    }
  }

}
