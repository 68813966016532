import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-redirect',
  template: ''
})
export class ExternalRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    window.location.href = this.route.snapshot.data['externalUrl'];
  }
}
