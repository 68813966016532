import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  DoCheck,
  IterableDiffers
} from '@angular/core';
import { PriceList } from 'src/app/shared/models/PriceList';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';

@Component({
  selector: 'app-products-bulk-actions',
  templateUrl: './products-bulk-actions.component.html',
  styleUrls: ['./products-bulk-actions.component.css']
})
export class ProductsBulkActionsComponent implements OnInit, OnChanges, DoCheck {

  public selectedCategory: number;
  public hideChangeCategory = true;
  private _differ: any;
  public bulkOptions;

  @Input()
  priceList: PriceList;

  @Input()
  productIdsArray = new Array<string>();

  @Input()
  productCategoryOptions: Option[] = [];

  @Output()
  unselectAllEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  selectAllEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  getPriceListProductCategories: EventEmitter<ProductCategory[]> = new EventEmitter<ProductCategory[]>();

  constructor(differs: IterableDiffers, private _priceListService: PriceListService, private _toastr: ToastrService,
    private _translate: TranslateService) {
    this._differ = differs.find([]).create(null);
  }

  ngOnInit() {
    this.bulkOptions = [
      { id: 0, text: this._translate.instant('price-list.manage.changeCategorySelection'),  iconClass: 'fa fa-edit'},
      { id: 1, text: this._translate.instant('price-list.manage.duplicateSelection'), iconClass: 'fa fa-copy' },
      { id: 2, text: this._translate.instant('price-list.manage.deleteSelected'), iconClass: 'fa fa-trash-alt' }
    ]
  }

  ngOnChanges(changes: any): void {
  }

  ngDoCheck() {
  }

  changeProductsCategory() {
    if (this.selectedCategory === undefined) {
      return;
    }
    const productCategory = this.priceList.productCategories.filter(category =>
      category.id.toString() === this.selectedCategory.toString())[0];
    this._priceListService.changeMultipleProductsCategory(this.productIdsArray, productCategory).subscribe({
      next: (result) => {
        this.getPriceListProductCategories.emit(result);
        this._toastr.success(this._translate.instant('price-list.manage.productsCategoryChangeSuccessMsg'));
        this.selectedCategory = undefined;
        this.hideChangeCategory = true;
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  duplicateSelection() {
    if (this.productIdsArray.length > 0) {
      this._priceListService.duplicateMultipleProducts(this.productIdsArray).subscribe({
        next: (result) => {
          this.getPriceListProductCategories.emit(result);
          this._toastr.success(this._translate.instant('price-list.manage.productsDuplicateSuccessMsg'));
        }, error: (error) => {
          console.log(error);
        }
      });
    }
  }

  deleteSelection() {
    if (this.productIdsArray.length > 0) {
      this._priceListService.deleteMultipleProducts(this.productIdsArray).subscribe({
        next: (result) => {
          this.getPriceListProductCategories.emit(result);
          this._toastr.success(this._translate.instant('price-list.manage.productsDeleteSuccessMsg'));
        }, error: (error) => {
          console.log(error);
        }
      });
    }
  }

  onBulkActionChosen(event) {
    switch (event.id) {
      case 0:
        this.productIdsArray.length > 0 ? this.hideChangeCategory = false : this.hideChangeCategory = true
       break;
      case 1:
        this.duplicateSelection()
       break;
      case 2:
        this.deleteSelection()
       break;
    }
  }

}
