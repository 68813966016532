<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="padding: 10.4px; background-color: #57c6f2 !important;">
  <a class="navbar-brand" href="#">{{title}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto" id="">
      <!--
      <li class="nav-item">
        <a class="nav-link" href="/account">{{'topNavbar.account' | translate}}</a>
      </li>
      -->
      <li class="nav-item">
        <a class="nav-link" href="#" (click)="logout($event)">{{'topNavbar.logout' | translate}}</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false" style="padding: 7px;">
          <img [src]="'/assets/flag/' + currentLanguage.flag +'.png'">
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="min-width: 0px;">
          <a class="dropdown-item" *ngFor="let l of languages" (click)="changeLanguage(l)">
            <img [src]="'/assets/flag/' + l.flag +'.png'">
          </a>
        </div>
      </li>
    </ul>
  </div>

  <app-sidebar *ngIf="isMobileMenu()"></app-sidebar>
</nav>

<ng-template #completeSurveyModal let-modal>
  <div class="modal-body">
    <form [formGroup]="surveyForm">
      <div>
        <div class="survey-title col-12"
          style="margin-top: 15px; padding:5px 0; text-align: center; background-color: #eff1f2; text-transform: uppercase; font-weight: bold;">
          {{'register.surveyQuestionLabel' | translate}}
        </div>
        <div class="survey-content col-12"
          style="margin-bottom: 15px; padding: 5px 50px;border:2px solid #eff1f2;">
          <div class="row" style="padding: 5px 0;" *ngFor="let survey of surveys">
            <input
              class="form-check-input {{(errorArray['survey'] ? 'is-invalid' : '')}}"
              type="radio" name="survey" id="survey"
              formControlName="survey" [formGroup]="surveyForm" [value]="survey.id"
              ngDefaultControl (click)="onCheckBoxClick(survey.id)">
            <label class="form-check-label" for="survey" style="margin-top: 3px;">
                {{survey.name}}
            </label>
            <input type="text" maxlength="200" name="description" formControlName="surveyDescription" [attr.disabled]="inputActive ? null : ''"
              [formGroup]="surveyForm" [value]="surveyForm.controls['surveyDescription'].value" *ngIf="survey.id === 7"
              style="margin: 0 10px; width:150px; border:2px solid #E0E0E0">
          </div>
        </div>
        <div class="invalid" *ngIf="missingSurveyErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px; color: red">{{missingSurveyErrorMsg}}</div>
      </div>
      <div>
        <button type="button" class="btn btn-primary btn-block" (click)="completeSurvey()" style="margin-top: 15px;">
          {{'topNavbar.confirmSurvey' | translate}}
        </button>
      </div>
    </form>
  </div>
</ng-template>
