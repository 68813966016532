<div class="row">
  <div class="col-auto">
    <div class="btn-group dropdown">
      <kendo-dropdownbutton
        [data]="bulkOptions"
        (itemClick)="onBulkActionChosen($event)">
        {{'price-list.manage.bulkActions' | translate}}<i class="fa fa-arrow-down"></i>
    </kendo-dropdownbutton>
    </div>
  </div>
  <div class="col-auto" [hidden]="hideChangeCategory">
    <div class="input-group input-group-sm">
      <select class="form-control" [(ngModel)]="selectedCategory">
        <option *ngFor="let option of productCategoryOptions" [value]="option.value">{{option.label}}</option>
      </select>
      <div class="input-group-append" id="button-addon4">
        <button type="button" class="btn btn-primary btn-sm" placement="top"
          [ngbTooltip]="'price-list.manage.update' | translate" (click)="changeProductsCategory()"><i
            class="fa fa-check"></i></button>
        <button type="button" class="btn btn-danger btn-sm" (click)="hideChangeCategory = true" placement="top"
          [ngbTooltip]="'price-list.manage.cancel' | translate"><i class="fa fa-times"></i></button>
      </div>
    </div>
  </div>
</div>
