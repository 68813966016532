<div class="wrapper">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-top-navbar title="{{'dashboard.pageTitle' | translate}}"></app-top-navbar>
        <div class="main-content">
          <div class="container-fluid">
              <div class="row" style="padding: 0 25px;">
                <div class="col-12 col-xl-4" *ngIf="currentLanguage === 'DE'">
                  <div class="card" style="margin:25px 25px;">
                    <div class="header">
                        <h4 class="title">{{'dashboard.clientPresentationVideoTitle' | translate}}</h4>
                    </div>
                    <div class="content embed-responsive">
                        <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen" id="myFrame1" [src]="clientPresentationVideoUrl"></iframe>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-4" *ngIf="currentLanguage === 'DE'">
                  <div class="card" style="margin:25px 25px;">
                    <div class="header">
                        <h4 class="title">{{'dashboard.appFunctionVideoTitle' | translate}}</h4>
                    </div>
                    <div class="content embed-responsive">
                        <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen"  [src]="AppFunctionVideoUrl"></iframe>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-4" *ngIf="currentLanguage === 'DE'">
                  <div class="card" style="margin:25px 25px;">
                    <div class="header">
                        <h4 class="title">{{'dashboard.mainDeviceVideoTitle' | translate}}</h4>
                    </div>
                    <div class="content embed-responsive">
                        <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen" [src]="MainDeviceVideoUrl"></iframe>
                    </div>
                  </div>
                </div>
                  <div class="col-12 col-xl-4">
                    <div class="card" style="margin:25px 25px;">
                      <div class="header">
                          <h4 class="title">{{'dashboard.presentationVideoTitle' | translate}}</h4>
                      </div>
                      <div class="content embed-responsive">
                          <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen" id="myFrame1" [src]="presentationVideoUrl"></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="card" style="margin:25px 25px;">
                      <div class="header">
                          <h4 class="title">{{'dashboard.setupVideoTitle' | translate}}</h4>
                      </div>
                      <div class="content embed-responsive">
                          <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen"  [src]="setupVideoUrl"></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="card" style="margin:25px 25px;">
                      <div class="header">
                          <h4 class="title">{{'dashboard.eventSetupVideoTitle' | translate}}</h4>
                      </div>
                      <div class="content embed-responsive">
                          <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen" [src]="eventSetupVideoUrl"></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="card" style="margin:25px 25px;">
                      <div class="header">
                          <h4 class="title">{{'dashboard.eventAppExplanationVideoTitle' | translate}}</h4>
                      </div>
                      <div class="content embed-responsive">
                          <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen" [src]="eventAppExplanationVideoUrl"></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="card" style="margin:25px 25px;">
                      <div class="header">
                        <h4 class="title">{{'dashboard.deleteDataVideoTitle' | translate}}</h4>
                      </div>
                      <div class="content embed-responsive">
                        <iframe class="embed-responsive-item" allowfullscreen="allowfullscreen" id="myFrame2" [src]="deleteDataVideoUrl"></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4">
                    <div class="card" style="margin:25px 25px;">
                      <div class="header" style="background-color: #57c6f2 !important; padding-bottom: 10px; ">
                          <h4 class="title" style="color: #FFFFFF; font-weight:bold">Downloads</h4>
                      </div>
                      <div class="content">
                        <a *ngIf="this.currentLanguage === 'DE'" href="../../../assets/downloads/Kurzanleitung_Famulus.pdf" target="_blank" download="Kurzanleitung_Famulus.pdf"><h4><b><i class="fa fa-file-pdf"></i> {{'dashboard.setupDocumentationTitle' | translate}}</b></h4></a>
                        <a *ngIf="this.currentLanguage === 'IT'" href="../../../assets/downloads/Guida_rapida_dinstallazione.pdf" target="_blank" download="Guida rapida d'installazione.pdf"><h4><b><i class="fa fa-file-pdf"></i> {{'dashboard.setupDocumentationTitle' | translate}}</b></h4></a>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<ng-template #changePasswordModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" style="margin: 0 auto;">
            {{'changePasswordModal.changePassword' | translate}}</h4>
    </div>
    <div class="modal-body">
        <p class="text-center">
            {{'changePasswordModal.changePasswordDescription' | translate}}
        </p>
        <div class="form-group">
            <label>{{'changePasswordModal.newPassword' | translate}} *</label>
            <input type="password" class="form-control form-control-sm" [(ngModel)]="newPassword" />
            <small class="text-danger" *ngIf="errorMsg">{{errorMsg}}</small>
        </div>
        <div class="form-group">
            <label>{{'changePasswordModal.repeatPassword' | translate}} *</label>
            <input type="password" class="form-control form-control-sm" [(ngModel)]="repeatPassword" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-block"
            (click)="updateCurrentClientPassword()">{{'changePasswordModal.change' | translate}}</button>
        <!-- <a class="btn btn-outline-dark btn-block"
            href="/account">{{'event.request.missingCompanyButton' | translate}}</a> -->
    </div>
</ng-template>
