import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DpDatePickerModule } from 'ng2-date-picker';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupInputComponent } from './form-group-input/form-group-input.component';
import { SimpleFormGroupInputComponent } from './simple-form-group-input/simple-form-group-input.component';
import { FormGroupDatepickerComponent } from './form-group-datepicker/form-group-datepicker.component';
import { FormGroupNumberInputComponent } from './form-group-number-input/form-group-number-input.component';
import { ConfirmDialogModelComponent } from './confirm-dialog-model/confirm-dialog-model.component';
import { FormGroupSelectComponent } from './form-group-select/form-group-select.component';
import { SimpleFormGroupSelectComponent } from './simple-form-group-select/simple-form-group-select.component';
import { DecimalNumberPipe } from './pipes/number.pipe';
import { DecimalCurrencyInputDirective } from './directives/decimal-currency-input.directive';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableColumnComponent } from './data-table/data-table-column/data-table-column.component';
import { DataFiltersComponent } from './data-filters/data-filters.component';
import { DataFilterButtonComponent } from './data-filters/data-filter-button/data-filter-button.component';
import { DataFilterDatepickerComponent } from './data-filters/data-filter-datepicker/data-filter-datepicker.component';
import { DataFilterGroupComponent } from './data-filters/data-filter-group/data-filter-group.component';
import { DataFilterInputComponent } from './data-filters/data-filter-input/data-filter-input.component';
import { DataFilterSelectComponent } from './data-filters/data-filter-select/data-filter-select.component';
import { SelectComponent } from './select/select.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectLocationGroupComponent } from './select-location-group/select-location-group.component';
import { ExternalRedirectComponent } from './external-redirect/external-redirect.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    DpDatePickerModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  declarations: [
    DecimalNumberPipe,
    DecimalCurrencyInputDirective,
    ExternalRedirectComponent,
    FormGroupInputComponent,
    SimpleFormGroupInputComponent,
    FormGroupDatepickerComponent,
    FormGroupNumberInputComponent,
    ConfirmDialogModelComponent,
    FormGroupSelectComponent,
    SimpleFormGroupSelectComponent,
    DataTableComponent,
    DataTableColumnComponent,
    DataFiltersComponent,
    DataFilterButtonComponent,
    DataFilterDatepickerComponent,
    DataFilterGroupComponent,
    DataFilterInputComponent,
    DataFilterSelectComponent,
    SelectComponent,
    PaginationComponent,
    SelectSearchComponent,
    SelectLocationGroupComponent
  ],
  exports: [
    DecimalNumberPipe,
    DecimalCurrencyInputDirective,
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    NgbModule,
    ReactiveFormsModule,
    ExternalRedirectComponent,
    FormGroupInputComponent,
    SimpleFormGroupInputComponent,
    FormGroupDatepickerComponent,
    FormGroupNumberInputComponent,
    ConfirmDialogModelComponent,
    DataFilterInputComponent,
    DataFilterGroupComponent,
    DataFiltersComponent,
    DataTableComponent,
    DataFilterDatepickerComponent,
    FormGroupSelectComponent,
    SimpleFormGroupSelectComponent,
    SelectSearchComponent,
    SelectLocationGroupComponent
  ],
  providers: [
  ]
})
export class SharedModule {
}
