import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'src/app/shared/cookie.service';
import { ActivateLoginService } from '../activate-login/activate-login.service';
import { AccountService } from 'src/app/account/shared/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public errorArray = new Array();
  public currentDate = new Date();
  public showRegisterSuccessMsg = false;
  public showActivationSuccessMsg = false;
  private _admcode: any;
  public activateCode: string;
  public lang: string;
  public hasError: boolean;
  public isCompleted = false;
  public title: string;
  public message: string;
  public userMail: string;
  public fromWidget = false;

  constructor(private _formBuilder: FormBuilder, private _authService: AuthService, private _router: Router,
    private _navigation: NavigationService, private _activatedRoute: ActivatedRoute, private _translate: TranslateService,
    private _cookieService: CookieService, private _route: ActivatedRoute, private _activateLoginService: ActivateLoginService
    ) {
    this.loginForm = this._formBuilder.group({
      email: ['', [<any>Validators.required, <any>Validators.email]],
      password: ['', <any>Validators.required],
    });
  }

  ngOnInit() {


    // const paramAdmCode = this._activatedRoute.snapshot.queryParamMap.get('admcode');
    const paramAdmCode = this._cookieService.getCookie('clientCryptCode');
    if (paramAdmCode) {
      this._cookieService.deleteCookie('clientCryptCode');
      this._admcode = Math.round(parseInt(paramAdmCode, 10) / 256577556571162887);
      this._authService.authenticateFromAdmin(this._admcode).subscribe(result => {
        this._router.navigate(['event/my-events']);
        return;
      });
    } else {
      this._activatedRoute.queryParams.subscribe(result => {
        if (result['action']) {
          if (result['action'] === 'register') {
            this.showRegisterSuccessMsg = true;
          }
        }
        if (result['lang'] && !result['email']) {
          this._translate.use(result['lang']);
          localStorage.setItem('locale', result['lang']);
          this._router.navigate(['login']);
        }
        if (result['email'] && result['lang']) {
          this.showActivationSuccessMsg = true;
        }
      });
    }

    this._route.queryParams
    .subscribe(params => {
      if (params.name) {
        this.loginForm.controls['email'].setValue(params.name);
        this.loginForm.controls['email'].disable();
        this.fromWidget = true;
        this._translate.use(params.lang);
      }
      if (this._authService.isAuthenticated() && !this.fromWidget) {
        return this.redirectToRequestEvent();
      }
      if (params.activateCode) {
        this.activateCode = params.activateCode;
        this.lang = params.lang;
        this.userMail = params.email;
        this.loginForm.controls['email'].setValue(this.userMail);
        if ((this.activateCode) && (this.lang)) {
          if (this.lang === 'it') {
            this.title = 'Attivazione account';
          } else {
            this.title = 'Kontoaktivierung';
          }
          this._activateLoginService.activateAccountLogin(this.activateCode).subscribe(res => {
            this.hasError = false;
            this.isCompleted = true;
            if (this.lang === 'it') {
              this.message = 'Il suo account è stato attivato con successo! Nella sua casella di posta elettronica troverà un e-mail con le credenziali valide per l\'accesso al sistema!';
            } else {
              this.message = 'Ihr Konto wurde erfolgreich aktiviert! Sie werden in Ihrem Posteingang eine E-Mail mit gültigen Zugangsdaten für das System finden!';
            }
          }, (error => {
            if (error.status === 601) {
              // Send activation code
              this.hasError = true;
              if (this.lang === 'it') {
                this.message = 'Errore: il codice attivazione non è stato inviato!';
              } else {
                this.message = 'Fehler: Aktivierungskodex nicht gesendet!';
              }
            } else if (error.status === 602) {
              // Activation code does not exist.
              this.hasError = true;
              if (this.lang === 'it') {
                this.message = 'Errore: il codice attivazione non esiste!';
              } else {
                this.message = 'Fehler: Der Aktivierungskodex existiert nicht!';
              }
            } else if (error.status === 605) {
              this.hasError = true;
              if (this.lang === 'it') {
                this.message = 'Errore: Email non registrata o non confermata';
              } else {
                this.message = 'Fehler: Email nicht registriert oder bestätigt';
              }
            } else if (error.status === 603) {
              // Error sending Email to specific email.
              this.hasError = true;
              if (this.lang === 'it') {
                this.message = 'Errore: impossibile inviare l\'email!';
              } else {
                this.message = 'Fehler: E-Mail kann nicht gesendet werden!';
              }
            } else {
              // Database error
              this.hasError = true;
              if (this.lang === 'it') {
                this.message = 'Errore: Errore generico!';
              } else {
                this.message = 'Fehler: Allgemeiner Fehler!';
              }
            }
          }));
        }
      }
    }
  );

  }


  onSignin() {
    if (this.loginForm.valid) {
      const userEmail = this.loginForm.get('email').value;
      const userPassword = this.loginForm.get('password').value.replace(/\s+/g, '');
      this._authService.authenticate(userEmail, userPassword).subscribe(result => {
        this.redirectToRequestEvent();
      }, error => {
        switch (error.status) {
          case 600:
          case 601:
            this.errorArray['password'] = 'login.errorMsg.pwdEmailWrong';
            break;
          case 602:
          case 603:
            this.errorArray['email'] = 'login.errorMsg.notEnabled';
            break;
          case 604:
            this.errorArray['email'] = 'login.errorMsg.recoveryMode';
            break;
          case 605:
            this.errorArray['email'] = 'login.errorMsg.notConfirmed';
            break;
          default:
            this.errorArray['email'] = 'login.errorMsg.default';
            break;
        }
      });
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.loginForm, 'login');
  }

  redirectToRequestEvent() {
    if (this.fromWidget) {
      this._router.navigate(['request2/event/prefill']);
    } else {
      this._navigation.home().go();
    }
  }

}
