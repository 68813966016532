import { Component, OnInit, ViewChild } from '@angular/core';
import { EventOffer } from 'src/app/shared/models/EventOffer';
import { OffersService } from '../shared/offers.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { Village } from 'src/app/shared/models/Village';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from 'src/app/account/shared/account.service';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EventCost } from 'src/app/shared/models/EventCost';
import { Router } from '@angular/router';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';
import { Country } from 'src/app/shared/models/Country';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { PickupPoint } from 'src/app/shared/models/PickupPoint';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-request-offer',
  templateUrl: './request-offer.component.html',
  styleUrls: ['./request-offer.component.css']
})
export class RequestOfferComponent implements OnInit {

  public eventOffer: EventOffer = new EventOffer();
  public deliveryAddressForm: FormGroup;
  public cableProductsAmount = {
    one: 0,
    five: 0,
    ten: 0,
    fifteen: 0,
    twenty: 0,
    thirty: 0,
    fifty: 0
  };
  public summary = {
    eventStartDate: null,
    eventEndDate: null,
    villageName: null
  };
  public countriesOptions: Option[] = [];
  public chosenVillage: Village;
  public chosenCountry: Country;
  public showVillageFraction = false;
  public showGeneralLocationInput = false;
  public eventInformationsError = '';
  public pickupLocationError = '';
  public deviceLicensingError = '';
  public rentHardwareError = '';
  public missingCableError = '';
  public eventStartDate;
  public eventEndDate;
  public choosenDeliveryCountryId = '1';
  public fullPackageRadioButton: boolean;
  public hidePackagesContent = true;
  public deliveryAddressErrorArray = new Array();
  public showCableSection = false;
  public allPickupPoints: PickupPoint[];
  public pickupPoints: PickupPoint[];
  public choosenPickupPoint: number;
  public villageObjectArray: Village[];
  public currentLanguage: string;
  public minDate: Date;
  public minDateObj: { year: number, month: number, day: number };
  public minDateM: any;
  public disableAdvertising = false;
  public acceptTermsAndConditions = false;
  public acceptTermsAndConditionsPoint4 = false;
  public acceptTermsAndConditionsPoint6 = false;
  public acceptTermsAndConditionsPoint9 = false;
  private _countriesObjectArray: Country[];
  private _currentVat: number;
  private _tokenData: any;
  private _blockWaiterSoftwareCount = false;
  private _blockPrinterSoftwareCount = false;
  public additionalCostsCustomArray: {
    id: number,
    name: string,
    cost: number,
    typeShow: string,
    typeField: string,
    title: string,
    text: string,
    priceLabel: string,
    value: number,
    endRow: boolean,
    hiddenSummary: boolean,
    titleSummary: string,
    valueSummary: string,
    valueSummaryZero: string,
    offset: number
  } [] = [];

  public additionalCostsContainerIsOpen = true;
  public additionalCostsCount = 0;
  public additionalCostsObjectArray: AdditionalCost[];

  @ViewChild('missingCompanyModal', {static: true}) missingCompanyModal: any;

  @ViewChild('eventOfferCommentsModal', {static: true}) eventOfferCommentsModal: any;

  @ViewChild('missingRoutersWarningModal', {static: true}) missingRoutersWarningModal: any;

  constructor(private _offersService: OffersService, private _translate: TranslateService, private _toastr: ToastrService,
              private _jwtHelper: JwtHelperService, private _accountService: AccountService, private _modalService: NgbModal,
              private _router: Router, private _formBuilder: FormBuilder) {
    this.deliveryAddressForm = this._formBuilder.group({
      firstLastName: ['', <any>Validators.required],
      address: ['', <any>Validators.required],
      addressDetails: [''],
      city: ['', <any>Validators.required],
      province: ['', <any>Validators.required],
      postcode: ['', <any>Validators.required],
      phone: ['', <any>Validators.required],
      country: ['', <any>Validators.required],
    });

    this.currentLanguage = this._translate.currentLang;
  }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    this._tokenData = this._jwtHelper.decodeToken(accessToken).data;
    this._accountService.getCompanyByClientId(this._tokenData.id).subscribe(company => {
      this.eventOffer.company = company;
      this.eventStartDate = this._fromModel(moment().add(0, 'day'));
      this.eventEndDate = this._fromModel(moment().add(0, 'day'));
      this.summary.eventStartDate = this._toModel(this.eventStartDate);
      this.summary.eventEndDate = this._toModel(this.eventEndDate);
      this.eventOffer.discountedPrice = 0.00;
      this.eventOffer.delivery = false;
      this.eventOffer.isAdvertise = true;
      const today = new Date();
      this.minDate = new Date();
      this.minDate.setDate(today.getDate());
      this.minDateObj = {
        year: this.minDate.getFullYear(),
        month: this.minDate.getMonth() + 1,
        day: this.minDate.getDate()
      };
      forkJoin([
        this._offersService.getAllVillages(),
        this._offersService.getCountries(),
        this._offersService.getPickupPoints(),
        this._offersService.getAdditionalCosts()
      ]).subscribe(result => {
        this.villageObjectArray = result[0];
        this._countriesObjectArray = result[1];
        this.additionalCostsObjectArray = result[3];
        if (result[3]) {
          this.additionalCostsCount = result[3].length;
          this._updateAdditionalCostObject(result[3]);
        }
        this._countriesObjectArray.forEach(country => {
          this.countriesOptions.push({ value: country.id, label: country.name });
        });
        this.allPickupPoints = result[2];
        this.pickupPoints = result[2];
        // added by Ivan
        this.choosenPickupPoint = 1;
        this.onPickupPointChange(1);

      });

      this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this._offersService.getAdditionalCosts().subscribe(result => {
          if (result && this.additionalCostsObjectArray.length > 0) {
            this.additionalCostsCustomArray = [];
            this.additionalCostsObjectArray = result;
            this.additionalCostsCount = result.length;
            this._updateAdditionalCostObject(result);
          }
        });
      });

    }, error => {
      this._modalService.open(this.missingCompanyModal, {centered: true, keyboard: false, backdrop: 'static'});
      console.log(error);
    });
  }


  manageTermsAndConditions(values: any) {
    if (this.acceptTermsAndConditions) {
      this.acceptTermsAndConditionsPoint4 = true;
      this.acceptTermsAndConditionsPoint6 = true;
      this.acceptTermsAndConditionsPoint9 = true;
    } else {
      this.acceptTermsAndConditionsPoint4 = false;
      this.acceptTermsAndConditionsPoint6 = false;
      this.acceptTermsAndConditionsPoint9 = false;
    }
  }

  onCountryChosen(country: Country) {
    this.showVillageFraction = country?.id === 1; // Italy
    this.showGeneralLocationInput = !this.showVillageFraction;
    this.chosenCountry = country;
    if (this.chosenCountry?.id !== 2) { // Austria
      this.pickupPoints = this.pickupPoints.filter(pickup => pickup.country.id !== 2);
    } else {
      this.pickupPoints = this.allPickupPoints;
    }
  }

  submitEventOfferRequest() {
    this.eventInformationsError = '';
    this.deviceLicensingError = '';
    this.pickupLocationError = '';
    this.rentHardwareError = '';
    this.missingCableError = '';
    if (this.eventOffer.eventName === '' || this.eventOffer.eventName === undefined) {
      this.eventInformationsError = this._translate.instant('offers.request.missingEventNameError');
      return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
    }
    const startDate = this._toModel(this.eventStartDate);
    const endDate = this._toModel(this.eventEndDate);
    if (startDate > endDate) {
      this.eventInformationsError = this._translate.instant('offers.request.startDateError');
      return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
    }
    if (this.chosenCountry?.id === 1) {
      if (!this.chosenVillage) {
        this.eventInformationsError = this._translate.instant('offers.request.villageError');
        return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
      }
    } else {
      if (this.eventOffer.zone?.length === 0) {
        this.eventInformationsError = this._translate.instant('offers.request.zoneError');
        return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
      }
    }
    this.eventOffer.startDate = startDate.format('YYYY-MM-DD');
    this.eventOffer.endDate = endDate.format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    if (today === this.eventOffer.startDate) {
      this.eventOffer.pickUpDate = startDate.format('YYYY-MM-DD');
    } else {
      this.eventOffer.pickUpDate = startDate.subtract(1, 'day').format('YYYY-MM-DD');
    }
    // this.eventOffer.pickUpDate = startDate.subtract(1, 'day').format('YYYY-MM-DD');
    this.eventOffer.returnDate = endDate.add(1, 'day').format('YYYY-MM-DD');
    this.eventOffer.isAdvertise = !this.disableAdvertising;

    if (this.eventOffer.delivery === true) {
      if (this.deliveryAddressForm.valid) {
        const deliveryAddress: any = DeliveryAddress.formDto(this.deliveryAddressForm.value);
        deliveryAddress.country = this._countriesObjectArray.filter(country => country.id.toString() === deliveryAddress.country)[0];
        this.eventOffer.deliveryAddress = deliveryAddress;
      } else {
        this.deliveryAddressErrorArray = ValidationUtils.getFormValidationErrors(this.deliveryAddressForm, 'offers.request');
        return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
      }
    } else {
      if (!this.eventOffer.pickupPoint) {
        this.pickupLocationError = this._translate.instant('offers.request.pickupLocationError');
        return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
      }
    }
    if (this.eventOffer.fullPackageRenting === undefined) {
      this.rentHardwareError = this._translate.instant('offers.request.hardwareRentError') + ' *';
      return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
    }
    if (this.eventOffer.softwareWaiter === 0 && this.eventOffer.softwareCashRegister === 0) {
      this.deviceLicensingError = this._translate.instant('offers.request.minSoftwareRequirementError');
      return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
    }

    if (this.eventOffer.hardwarePrinter > 0) {
      let cableQuantity = 0;
      for (const key in this.cableProductsAmount) {
        if (this.cableProductsAmount.hasOwnProperty(key)) {
          const quantity = this.cableProductsAmount[key];
          cableQuantity += quantity;
        }
      }
      if (cableQuantity < this.eventOffer.hardwarePrinter) {
        this.missingCableError = this._translate.instant('offers.request.missingCableErrorMsg',
          {quantity: this.eventOffer.hardwarePrinter});
        return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
      }
    }

    if (this.chosenVillage) {
      this.eventOffer.village = this.chosenVillage;
    }

    this.eventOffer.hardwareCable = JSON.stringify(this.cableProductsAmount);
    this.eventOffer.language = this._translate.currentLang;

    this._updateAdditionalCostValueSummary();
    this._modalService.open(this.eventOfferCommentsModal, {centered: true});
  }

  closeMissingRoutesWarningModal(nextStep: any) {
    this._modalService.dismissAll();
    if (nextStep) {
      this._modalService.open(this.eventOfferCommentsModal, {centered: true});
    }
  }

  private _updateStickerDescription() {
    const stickerIndex = this.additionalCostsCustomArray.findIndex(ac => ac.id === 12);
    let stickerDescriptionTemplate = this._translate.instant('offers.request.additionalCosts.sticker.description');
    const waiters = this.eventOffer.softwareWaiter;
    const cashRegisters = this.eventOffer.softwareCashRegister;
    const quantity = (waiters + cashRegisters) * this.additionalCostsCustomArray[stickerIndex].offset;
    stickerDescriptionTemplate = stickerDescriptionTemplate.replace('{quantity}', quantity > 0 ? quantity : '');
    stickerDescriptionTemplate = stickerDescriptionTemplate.replace('{offset}', this.additionalCostsCustomArray[stickerIndex].offset);
    stickerDescriptionTemplate = stickerDescriptionTemplate.replace('{price}', this.additionalCostsCustomArray[stickerIndex].cost.toFixed(2) + ' €');
    this.additionalCostsCustomArray[stickerIndex].text = stickerDescriptionTemplate;
  }

  onSoftwareLicenseQuantityChange(licenseType: string, negative?: boolean) {
    if (this.eventOffer.fullPackageRenting) {
      switch (licenseType) {
        case 'waiter':
          this.eventOffer.hardwarePhone = this.eventOffer.softwareWaiter;
          this._updateStickerDescription();
          break;
        case 'cashRegister':
          this._updateStickerDescription();
        // tslint:disable-next-line:no-switch-case-fall-through
        case 'mainDevice':
          if (negative !== undefined) {
            this.eventOffer.hardwarePrinter = (!negative ? (this.eventOffer.hardwarePrinter + 1) : (this.eventOffer.hardwarePrinter - 1));
            this.eventOffer.softwarePrinter = (!negative ? (this.eventOffer.softwarePrinter + 1) : (this.eventOffer.softwarePrinter - 1));
          }
          this._showCableSection();
          this.eventOffer.hardwareTablet = (this.eventOffer.softwareCashRegister + this.eventOffer.softwareMainDevice);
          this.onAdditionalCostNumberInputChange(11);
          break;
        case 'printer':
          this.eventOffer.hardwarePrinter = this.eventOffer.softwarePrinter;
          this._showCableSection();
          break;
        case 'fiscalPrinter':
          this.eventOffer.hardwareFiscalPrinter = this.eventOffer.softwareFiscalPrinter;
          this._showCableSection();
          break;
      }
    }
    this.calculateEventOfferPrice();
  }

  onHardwareQuantityChange(licenseType: string, negative?: boolean) {
    if (!this.eventOffer.fullPackageRenting) {
      switch (licenseType) {
        case 'waiter':
          this._updateStickerDescription();
          break;
      }
    }
    this.calculateEventOfferPrice();
  }

  calculateEventOfferPrice() {
    this.eventInformationsError = '';

    this._showCableSection();
    if (!this.choosenPickupPoint) {
      return;
    }
    const startDate = moment(this._toModel(this.eventStartDate).format('YYYY-MM-DD'));
    const endDate = moment(this._toModel(this.eventEndDate).format('YYYY-MM-DD'));

    if (this.chosenCountry?.id === 1 && !this.chosenVillage) {
      this.eventInformationsError = this._translate.instant('offers.request.villageError');
      return;
    } else if (this.chosenCountry?.id !== 1 && this.eventOffer.zone?.length === 0) {
      this.eventInformationsError = this._translate.instant('offers.request.zoneError');
      return;
    }

    this.summary.villageName = !this.chosenVillage ?
      this.eventOffer.zone
      : this.villageObjectArray.filter(village => village.id === this.chosenVillage.id)[0]['name'];

    const duration = moment.duration(endDate.diff(startDate));
    this._offersService.calculateEventOfferPrice(this.eventOffer, (duration.asDays() + 1), this.choosenPickupPoint, true)
      .subscribe(result => {
        this._currentVat = result.vat;
        this.eventOffer.vat = (this._currentVat * 100);
        this.eventOffer.fullPrice = EventCost.fromDtoPrice(result);
        this.eventOffer.discountedPrice = EventCost.fromDtoPrice(result);
        this.eventOffer.discount = 0;
        this.eventOffer.insuranceCost = result.insuranceCost;
        this._calculateVat();
      });
  }

  onDateChange(dateType: string) {
    const startDate = this._toModel(this.eventStartDate);
    const endDate = this._toModel(this.eventEndDate);
    if (startDate > endDate) {
      if (dateType === 'startDate') {
        this.eventEndDate = this.eventStartDate;
      } else {
        this.eventInformationsError = this._translate.instant('offers.request.startDateError');
        return this._toastr.error(this._translate.instant('offers.request.genericErrorMsg'));
      }
    } else {
      this.eventInformationsError = '';
    }
    this.summary.eventStartDate = this._toModel(this.eventStartDate);
    this.summary.eventEndDate = this._toModel(this.eventEndDate);
    this.calculateEventOfferPrice();
  }

  onRentHardwareRadioButtonChange() {
    this.rentHardwareError = '';
    this.hidePackagesContent = false;
    this.eventOffer.softwareWaiter = 0;
    this.eventOffer.softwareMainDevice = 0;
    this.eventOffer.softwareCashRegister = 0;
    this.eventOffer.softwarePrinter = 0;
    this.eventOffer.softwareFiscalPrinter = 0;
    this.eventOffer.hardwareRouter = 0;
    this.eventOffer.hardwarePhone = 0;
    this.eventOffer.hardwareTablet = 0;
    this.eventOffer.hardwarePrinter = 0;
    this.eventOffer.hardwareFiscalPrinter = 0;
    this._resetCableAmount();
    this._showCableSection();
    this.calculateEventOfferPrice();
  }

  submitEventOffer() {
    if (this.eventOffer.notes) {
      this.eventOffer.notes = this.eventOffer.notes.replace(/\n/g, '<br />');
    }
    this._modalService.dismissAll();
    this._offersService.insertEventOffer(this.eventOffer, this._tokenData.firstEmail).subscribe(result => {
      this._toastr.success(this._translate.instant('offers.request.eventSuccessRequest'));
      if (result.hasHardware) {
        if (this.eventOffer.isAdvertise) {
          return this._router.navigate(['/event/details/' + result.eventOfferId + '/advertising']);
        } else {
          return this._router.navigate(['/event/my-events']);
        }
      } else {
        if (this.eventOffer.isAdvertise) {
          return this._router.navigate(['/event/details/' + result.eventId + '/advertising']);
        } else {
          return this._router.navigate(['/event/details/' + result.eventId + '/general']);

        }
      }
    });
  }

  onDeliverTypeChange(delivery: boolean) {
    this.deliveryAddressErrorArray = [];
    this.pickupLocationError = '';
    if (!delivery) {
      this.eventOffer.pickupPoint = undefined;
      this.choosenPickupPoint = null;
      this.eventOffer.discountedPrice = 0;
    } else {
      this.deliveryAddressForm.reset({country: '1'});
      this.choosenPickupPoint = 1;
    }
    this.calculateEventOfferPrice();
  }

  onPickupPointChange(pickupPointId: number) {
    this.pickupLocationError = '';
    this.eventOffer.pickupPoint = this.pickupPoints.filter(pickup => pickup.id === pickupPointId)[0];
    this.calculateEventOfferPrice();
  }

  private _calculateVat() {
    const vatPrice: number = this.eventOffer.discountedPrice / 100 * this.eventOffer.vat;
    this.eventOffer.discountedPriceWithVat = parseFloat(this.eventOffer.discountedPrice.toString()) + parseFloat(vatPrice.toString());
  }

  private _fromModel(date: moment.Moment): NgbDateStruct {
    if (!date) {
      return null;
    }
    return {day: date.date(), month: date.month() + 1, year: date.year()};
  }

  private _toModel(date: NgbDateStruct): moment.Moment {
    if (!date) {
      return null;
    }
    return moment(date.day + '-' + date.month + '-' + date.year, 'DD-MM-YYYY');
  }

  private _showCableSection() {
    this.missingCableError = '';
    if (this.eventOffer.hardwarePrinter > 0 || this.eventOffer.hardwareFiscalPrinter > 0) {
      this.showCableSection = true;
    } else {
      this.showCableSection = false;
      this._resetCableAmount();
    }
  }

  private _resetCableAmount() {
    this.cableProductsAmount = {
      one: 0,
      five: 0,
      ten: 0,
      fifteen: 0,
      twenty: 0,
      thirty: 0,
      fifty: 0
    };
  }

  public openCloseExtrasContainer() {
    this.additionalCostsContainerIsOpen = !this.additionalCostsContainerIsOpen;
  }

  private _updateAdditionalCostObject(rs: AdditionalCost[]) {
    rs.forEach(rec => {
      const obj: {
        id: number,
        name: string,
        cost: number,
        typeShow: string,
        typeField: string,
        title: string,
        text: string,
        priceLabel: string,
        value: number,
        endRow: boolean,
        hiddenSummary: boolean,
        titleSummary: string,
        valueSummary: string,
        valueSummaryZero: string,
        offset: number,
      } = {
        id: 0, name: '', cost: 0, typeShow: '', typeField: '', title: '', text: '',
        priceLabel: '', value: 0, endRow: false, hiddenSummary: false, titleSummary: '', valueSummary: '',
        valueSummaryZero: '', offset: 1,
      };
      obj.id = rec.id;
      obj.name = rec.name;
      obj.cost = rec.cost;
      obj.title = this._translate.instant('offers.request.additionalCosts.' + rec.name + '.title');
      obj.titleSummary = this._translate.instant('offers.request.additionalCosts.' + rec.name + '.title');
      obj.text = this._translate.instant('offers.request.additionalCosts.' + rec.name + '.description');
      obj.priceLabel = this._translate.instant('offers.request.additionalCosts.' + rec.name + '.priceLabel');
      if (rec.id === 12) { // sticker
        obj.offset = 10; // same as Global.STICKER_OFFSET in backend
      }
      switch (rec.type) {
        case 1:
          obj.typeShow = 'showPerc';
          break;
        case 2:
          obj.typeShow = 'showValue';
          break;
        case 3:
          obj.typeShow = 'showLabel';
          break;
      }
      switch (rec.name) {
        case 'beltBag':
        case 'wallet':
        case 'cashDrawer':
        case 'sticker':
        case 'vivaWallet':
        case 'sumUp':
          obj.typeField = 'groupNumber';
          break;
        case 'badWeather':
        case 'pickupInstalled':
        case 'uploadingCheckingPricelist':
        case 'training':
        case 'stockManagement':
          obj.typeField = 'radio';
          break;
        case 'cancellation':
          obj.typeField = 'simpleText';
          obj.hiddenSummary = true;
          break;
      }
      obj.endRow = rec.name === 'training' || rec.name === 'sticker';
      if (rec.name !== 'cancellation') {
        this.additionalCostsCustomArray.push(obj);
      }
    });

    this._updateStickerDescription();
  }

  public onAdditionalCostNumberInputChange(id: number) {
    this.eventOffer.additionalCosts = [];
    this.additionalCostsCustomArray.forEach(rec => {
      const obj: { id: number, quantity: number } = {id: 0, quantity: 0};
      obj.id = rec.id;
      obj.quantity = rec.value;
      if (rec.id === 11 && this.eventOffer.softwareMainDevice && rec.value > 0) {
        this.eventOffer.additionalCosts.push(obj);
      } else if (rec.id !== 11 && rec.value > 0) {
        this.eventOffer.additionalCosts.push(obj);
      }
    });
    this.calculateEventOfferPrice();
  }

  public showAdditionalCostAmount(id: number) {
    return !(id === 9 || id === 10); // 9 = vivaWallet, 10 = sumUp
  }

  private _updateAdditionalCostValueSummary() {
    this.additionalCostsCustomArray.forEach(ac1 => {
      switch (ac1.name) {
        case 'beltBag':
        case 'wallet':
        case 'cashDrawer':
        case 'sticker':
        case 'vivaWallet':
        case 'sumUp':
          ac1.valueSummaryZero = this._translate.instant('offers.request.additionalCosts.no');
          this.eventOffer.additionalCosts.forEach(ac2 => {
            if (ac1.id === ac2.id) {
              ac1.value = ac2.quantity;
              ac1.valueSummary = ac2.quantity.toString();
            }
          });
          break;
        case 'badWeather':
        case 'pickupInstalled':
        case 'uploadingCheckingPricelist':
        case 'training':
        case 'stockManagement':
          ac1.valueSummary = this._translate.instant('offers.request.additionalCosts.yes');
          ac1.valueSummaryZero = this._translate.instant('offers.request.additionalCosts.no');
          ac1.value = 0;
          this.eventOffer.additionalCosts.forEach(ac2 => {
            if (ac1.id === ac2.id) {
              ac1.value = ac2.quantity;
            }
          });
          break;
        case 'cancellation':
          ac1.valueSummaryZero = this._translate.instant('offers.request.additionalCosts.no');
          this.eventOffer.additionalCosts.forEach(ac2 => {
            if (ac1.id === ac2.id) {
              ac1.value = ac2.quantity;
              ac1.hiddenSummary = true;
              ac1.valueSummary = this._translate.instant('offers.request.additionalCosts.yes');
            }
          });
          break;
      }
    });
  }
}
