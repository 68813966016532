import { FormGroup, ValidationErrors } from '@angular/forms';

export class ValidationUtils {

    /**
     *
     *
     */
    public static getFormValidationErrors(form: FormGroup, translationGroup: string): Array<any> {
        const errorArray = new Array();
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            errorArray[key] = null;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    let errorMsg;
                    switch (keyError) {
                        case 'email':
                            errorMsg = translationGroup + '.errorMsg.invalideEmail';
                            break;
                        case 'minlength':
                            errorMsg = translationGroup + '.errorMsg.shortLength';
                            break;
                        case 'notEquivalent':
                            errorMsg = translationGroup + '.errorMsg.passwordNotEqual';
                            break;
                        case 'required':
                            errorMsg = translationGroup + '.errorMsg.required';
                            break;
                        case 'notTerms':
                            errorMsg = translationGroup + '.errorMsg.missingTerms';
                            break;
                        case 'fiscalCodeVatRequired':
                            errorMsg = translationGroup + '.errorMsg.fiscalCodeVatRequired';
                            break;
                        case 'maxlength':
                            errorMsg = translationGroup + '.errorMsg.maxlength';
                            break;
                        default:
                            errorMsg = translationGroup + '.errorMsg.default';
                            break;
                    }
                    errorArray[key] = errorMsg;
                });
            }
        });
        return errorArray;
    }

    public static checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string, passwordConfirmRequired = true) {
        return (group: FormGroup) => {
            const passwordInput = group.controls[passwordKey],
                passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordConfirmRequired) {
                if (passwordConfirmationInput.value === undefined) {
                    return passwordConfirmationInput.setErrors({ required: true });
                }
            }
            if (passwordInput.value !== passwordConfirmationInput.value) {
                return passwordConfirmationInput.setErrors({ notEquivalent: true });
            } else {
                return passwordConfirmationInput.setErrors(null);
            }
        };
    }

    public static checkStringLength(nameKey: string, extraInfo: string): any {
        return (group: FormGroup) => {
            const nameInput = group.controls[nameKey],
                extraInfoInput = group.controls[extraInfo];
            if (nameInput.value.length === 0) {
                nameInput.setErrors({ required: true });
                return;
            }
            if (!extraInfoInput.value) {
              if ((nameInput.value.length) > 32) {
                nameInput.setErrors({ maxlength: true });
                extraInfoInput.setErrors({ maxlength: true });
              } else {
                nameInput.setErrors(null);
                extraInfoInput.setErrors(null);
              }
            } else {
              if ((nameInput.value.length + extraInfoInput.value.length) > 32) {
                nameInput.setErrors({ maxlength: true });
                extraInfoInput.setErrors({ maxlength: true });
              } else {
                nameInput.setErrors(null);
                extraInfoInput.setErrors(null);
              }
            }

        };
    }
}
