import { Country } from "./Country";
import { DeliveryAddress } from "./DeliveryAddress";
import { PickupPoint } from "./PickupPoint";
import { Village } from "./Village";

export class EventOfferDraft {
  public id: number;
  public eventName: string = "";
  public startDate: string;
  public endDate: string;
  public pickupDate: string;
  public returnDate: string;
  public softwareWaiter: number = 0;
  public softwareCashRegister: number = 0;
  public softwareMainDevice: number = 0;
  public softwarePrinter: number = 0;
  public hardwarePhone: number = 0;
  public hardwarePrinter: number = 0;
  public hardwareRouter: number = 0;
  public hardwareCable: string;
  public hasStockManagement: boolean = false;
  public vivaWalletLicenses: number = 0;
  public sumUpLicenses: number = 0;
  public hasCancellationInsurance: boolean;
  public hasPickupInstalled: boolean;
  public hasCheckingPriceList: boolean;
  public hasTraining: boolean;
  public cashDrawers: number = 0;
  public beltBags: number = 0;
  public waiterWallets: number = 0;
  public stickers: number = 0;
  public status: number;
  public language: string;
  public country: Country;
  public village: Village;
  public zone: string;
  public isAdvertise: boolean = true;
  public pickupPoint: PickupPoint;
  public delivery: boolean = false;
  public deliveryAddress: DeliveryAddress;

  static fromDto(e): EventOfferDraft {
    const eventOfferDraft = new EventOfferDraft();
    if (!e) {
      return null;
    }
    eventOfferDraft.id = e.id;
    eventOfferDraft.eventName = e.eventName;
    eventOfferDraft.startDate = e.startDate;
    eventOfferDraft.endDate = e.endDate;
    eventOfferDraft.pickupDate = e.pickupDate;
    eventOfferDraft.returnDate = e.returnDate;
    eventOfferDraft.softwareWaiter = e.softwareWaiter;
    eventOfferDraft.softwareCashRegister = e.softwareCashRegister;
    eventOfferDraft.softwareMainDevice = e.softwareMainDevice;
    eventOfferDraft.softwarePrinter = e.softwarePrinter;
    eventOfferDraft.hardwarePhone = e.hardwarePhone;
    eventOfferDraft.hardwarePrinter = e.hardwarePrinter;
    eventOfferDraft.hardwareRouter = e.hardwareRouter;
    eventOfferDraft.hardwareCable = e.hardwareCable;
    eventOfferDraft.hasStockManagement = e.hasStockManagement;
    eventOfferDraft.vivaWalletLicenses = e.vivaWalletLicenses;
    eventOfferDraft.sumUpLicenses = e.sumUpLicenses;
    eventOfferDraft.hasCancellationInsurance = e.hasCancellationInsurance;
    eventOfferDraft.hasPickupInstalled = e.hasPickupInstalled;
    eventOfferDraft.hasCheckingPriceList = e.hasCheckingPriceList;
    eventOfferDraft.hasTraining = e.hasTraining;
    eventOfferDraft.cashDrawers = e.cashDrawers;
    eventOfferDraft.beltBags = e.beltBags;
    eventOfferDraft.waiterWallets = e.waiterWallets;
    eventOfferDraft.stickers = e.stickers;
    eventOfferDraft.status = e.status;
    eventOfferDraft.language = e.language;
    eventOfferDraft.country = e.country;
    eventOfferDraft.village = e.village;
    eventOfferDraft.zone = e.zone;
    eventOfferDraft.isAdvertise = e.isAdvertise;
    eventOfferDraft.pickupPoint = e.pickupPoint;
    eventOfferDraft.delivery = e.delivery;
    eventOfferDraft.deliveryAddress = e.deliveryAddress;
  }
}
