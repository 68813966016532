// import { PasswordChangeCallbackComponent } from './password-change-callback/password-change-callback.component';
import { Routes } from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RegisterConfirmComponent } from './register/register-confirm/register-confirm.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { ActivateLoginComponent } from './activate-login/activate-login.component';
import { ExternalRedirectComponent } from '../shared/external-redirect/external-redirect.component';
import { environment } from '../../environments/environment';

export const CORE_ROUTES: Routes = [
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    {path: 'login', component: LoginComponent},
    {path: 'login/:admcode', component: LoginComponent},
    {path: 'recovery-password', component: RecoveryComponent},
    {path: 'reset-password/:recoveryCode', component: ResetPasswordComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'register/confirm', component: RegisterConfirmComponent},
    {path: 'register/activate', component: ActivateLoginComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'terms', component: TermsComponent},
    {path: 'meeting', component: ExternalRedirectComponent, data: { externalUrl: environment.MEETING_URL}, pathMatch: 'full'},
    {path: 'meeting2', component: ExternalRedirectComponent, data: { externalUrl: environment.MEETING_URL2}, pathMatch: 'full'},
    {path: 'meeting3', component: ExternalRedirectComponent, data: { externalUrl: environment.MEETING_URL3}, pathMatch: 'full'}
  ];
