import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/shared/models/Product';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ProductOption } from 'src/app/shared/models/ProductOption';

@Component({
  selector: 'app-product-option-table',
  templateUrl: './product-option-table.component.html',
  styleUrls: ['./product-option-table.component.css']
})
export class ProductOptionTableComponent implements OnInit {

  public choosenEditProductOption: ProductOption = new ProductOption();
  public editProductOption = false;
  private _choosendProductOptionId: number;

  @ViewChild('confirmDeleteProductOptionDialog', { static: true })
  confirmDeleteProductOptionDialog;


  @ViewChild('confirmDuplicateProductOptionDialog', { static: true })
  confirmDuplicateProductOptionDialog;

  @Input()
  product: Product;

  @Input()
  eventCurrency: string;

  @Output()
  getEditProductOptionMood: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _priceListService: PriceListService, private _toastr: ToastrService, private _translate: TranslateService) { }

  ngOnInit() {
    let index = 0;
    this.product.productOptions.forEach(rec => {
      if (!rec.extraInfo) {
        this.product.productOptions[index].extraInfo = '';
        // if null there is an error in "product-option-update-form.component" so I set here empty string instead of null
      }
      index++;
    });

  }

  enableProductOptionedit(productOption: ProductOption) {
    this.choosenEditProductOption = { ...productOption };
    this.editProductOption = true;
    this.getEditProductOptionMood.emit(this.editProductOption);
  }

  getCancelProductOptionEditMood() {
    this.choosenEditProductOption = new ProductOption();
    this.editProductOption = false;
    this.getEditProductOptionMood.emit(this.editProductOption);
  }

  changeProductOptionPosition(productOptionId: number, newPosition: number) {
    this._priceListService.changeProductOptionItemOrder(productOptionId, newPosition, this.product.id)
      .subscribe({
        next: (result) => {
          this.product.productOptions = result.productOptions;
        }, error: (error) => {
          console.log(error);
        }
      });
  }

  changeProductOptionStatus(productOption: ProductOption, newStatusValue: number) {
    productOption = ProductOption.fromDto(productOption);
    productOption.status = newStatusValue;
    productOption.product = this.product;
    this._priceListService.updateProductOption(productOption).subscribe({
      next: (result) => {
        this.product.productOptions = result.productOptions;
        if (newStatusValue === 1) {
          this._toastr.success(this._translate.instant('price-list.manage.productOptionEnabledSuccessMsg'));
        } else {
          this._toastr.success(this._translate.instant('price-list.manage.productOptionDisabledSuccessMsg'));
        }
      }, error: (error) => {
        console.log(error);
      }
    });
  }


  showDuplicateProductOptionDialog(optionId: number) {
    this.confirmDuplicateProductOptionDialog.openDialog();
    this._choosendProductOptionId = optionId;
  }

  showDeleteProductOptionDialog(optionId: number) {
    this.confirmDeleteProductOptionDialog.openDialog();
    this._choosendProductOptionId = optionId;
  }

  getDeleteProductOptionDecision(decision: boolean) {
    if (decision) {
      this._priceListService.deleteProductOption(this._choosendProductOptionId).subscribe({
        next: (result) => {
          const productOptionIndex = this.product.productOptions.findIndex(
            option => option.id.toString() === this._choosendProductOptionId.toString());
          this.product.productOptions.splice(productOptionIndex, 1);
          this._choosendProductOptionId = null;
          this._toastr.success(this._translate.instant('price-list.manage.productOptionDeleteSuccessMsg'));
        }, error: (error) => {
          console.log(error);
          this._choosendProductOptionId = null;
        }
      });
    }
  }

  getDuplicateProductOptionDecision(decision: boolean) {
    if (decision) {
      this._priceListService.duplicateProductOption(this._choosendProductOptionId).subscribe({
        next: (result) => {
          this.product.productOptions = result.productOptions;
          this._toastr.success(this._translate.instant('price-list.manage.productOptionDuplicateSuccessMsg'));
        }, error: (error) => {
          console.log(error);
        }
      });
    }
    this._choosendProductOptionId = null;
  }

}
