import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { EventService } from '../../shared/event.service';
import { Event } from 'src/app/shared/models/Event';
import { Router } from 'src/app/shared/models/Router';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { Printer } from 'src/app/shared/models/Printer';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AdditionalCost } from '../../../shared/models/AdditionalCost';
import { DeviceUser } from '../../../shared/models/DeviceUser';

@Component({
  selector: 'app-device-users',
  templateUrl: './device-users.component.html',
  styleUrls: ['./device-users.component.css']
})
export class DeviceUsersComponent implements OnInit {
  @ViewChild('notificationContainer', {read: ViewContainerRef})
  public notificationContainer: ViewContainerRef;

  public event: Event = new Event();
  public routers: Router[];
  public disableDeviceMenuItem = false;
  public printerListOptions: Option[];
  public ownPrinters: Printer[] = [];
  public selectedPrinter: Printer;
  public selectablePrinterCount = 0;

  public sumUpDescription: string;
  public vivaWalletDescription: string;
  public fiscalPrinterDescription: string;

  private additionalCosts: AdditionalCost[] = [];
  private sumUpCost: AdditionalCost;
  private vivaWalletCost: AdditionalCost;
  private popupRef: PopupRef;
  public eventWithoutPrinter = false;

  public sumUpCount = 0;
  public vivaWalletCount = 0;

  public fiscalPrinterLicenses = {qtySoftware: 0, qtyHardware: 0};

  constructor(
    private _eventService: EventService,
    private _translateService: TranslateService,
    private _popupService: PopupService,
    private _notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this._eventService.currentEventObject.subscribe(result => {
      if (result) {
        this.event = result;
        this.sumUpCount = this.event.deviceUsers.filter(d => d.enableSumUp).length;
        this.vivaWalletCount = this.event.deviceUsers.filter(d => d.enableVivaWallet).length;
        if (this.event.requestedSoftwareTypes.filter(s => s.softwareType.id === 4).length < 1) {
          this.eventWithoutPrinter = true;
          for (let i = 0; i < this.event.deviceUsers.length; i++) {
            this.event.deviceUsers[i].paidWithoutPrint = true;
          }
        }
        this.routers = this.event.routers;
        this.fiscalPrinterLicenses = {
          qtySoftware: this.event.requestedSoftwareTypes?.filter(software => software.softwareType.id === 5)[0]?.quantity ?? 0,
          qtyHardware: this.event.requestedPrinterTypes?.filter(printer => printer.printerType.isFiscalPrinter)[0]?.quantity ?? 0
        };
        if (this.fiscalPrinterLicenses.qtySoftware > 0) {
          this.fiscalPrinterDescription = this._translateService
            .instant('event.details.enableFiscalPrinter_description')
            .replace('{quantity}', this.fiscalPrinterLicenses.qtySoftware);
        } else if (this.fiscalPrinterLicenses.qtyHardware > 0) {
          this.fiscalPrinterDescription = this._translateService
            .instant('event.details.enableFiscalPrinter_description')
            .replace('{quantity}', this.fiscalPrinterLicenses.qtyHardware);
        }
        if (this.event.requestedPrinterTypes.length === 0 && this.event.requestedRouterTypes.length === 0) {
          if (this.event.deviceUsers.length === 0) {
            this.disableDeviceMenuItem = true;
          }
        } else {
          if (this.event.deviceUsers.length === 0 ||
            this.event.routers.length < this._getRequestedQuantity(this.event.requestedRouterTypes) ||
            this.event.printers.length < this._getRequestedQuantity(this.event.requestedPrinterTypes)) {
            this.disableDeviceMenuItem = true;
          }
        }
        this.disableDeviceMenuItem = false;
        this.populatePrinterSelect();
        this.countSelectablePrinters();

        this._eventService.getAdditionalCostsAssigned(`${this.event.id}`).subscribe(res => {
          this.additionalCosts = res.map(ac => AdditionalCost.fromDto(ac));
          this.sumUpCost = this.additionalCosts.find(ac => ac.id === 10);
          this.vivaWalletCost = this.additionalCosts.find(ac => ac.id === 9);
          if (this.sumUpCost) {
            this.sumUpDescription = this._translateService
              .instant('event.details.enableSumup_description')
              .replace('{quantity}', this.sumUpCost.quantity.toString());
          }
          if (this.vivaWalletCost) {
            this.vivaWalletDescription = this._translateService
              .instant('event.details.enableVivaWallet_description')
              .replace('{quantity}', this.vivaWalletCost.quantity.toString());
          }
        });
      }
    });
    this._eventService.updateCurrentActiveSubPage('devices');
  }

  public updateSumUpCount(event) {
    if (event.target.checked) {
      this.sumUpCount += 1;
    } else {
      this.sumUpCount -= 1;
    }
  }

  public updateVivaWalletCount(event) {
    if (event.target.checked) {
      this.vivaWalletCount += 1;
    } else {
      this.vivaWalletCount -= 1;
    }
  }

  public updateDevicesUser() {
    const devicesUserToUpdate = this.event.deviceUsers.map(deviceUser => {
      const inputUsername = document.getElementById('input-username-' + deviceUser.id) as HTMLInputElement;
      const inputIsvClientId = document.getElementById('input-isv-client-id-' + deviceUser.id) as HTMLInputElement;
      const inputIsvClientSecret = document.getElementById('input-isv-client-secret-' + deviceUser.id) as HTMLInputElement;
      const inputAffiliateKey = document.getElementById('input-affiliate-key-' + deviceUser.id) as HTMLInputElement;

      /*if (deviceUser.hasFiscalPrinter) {
        if (inputFiscalPrinterIP?.value.length > 0) {
          deviceUser.fiscalPrinterIP = inputFiscalPrinterIP.value.trim();
        } else {
          return null;
        }
      }*/

      if (!deviceUser.hasFiscalPrinter) {
        deviceUser.fiscalPrinterIP = null;
      }

      if (inputUsername.value?.length > 0 && inputUsername.value !== deviceUser.name) {
        deviceUser.name = inputUsername.value;
      }

      if (inputIsvClientId?.value?.length > 0 && inputIsvClientId.value !== deviceUser.isvClientId) {
        deviceUser.isvClientId = inputIsvClientId.value;
      }
      if (inputIsvClientSecret?.value?.length > 0 && inputIsvClientSecret.value !== deviceUser.isvClientSecret) {
        deviceUser.isvClientSecret = inputIsvClientSecret.value;
      }
      if (inputAffiliateKey?.value?.length > 0 && inputAffiliateKey.value !== deviceUser.affiliateKey) {
        deviceUser.affiliateKey = inputAffiliateKey.value;
      }

      /*
      if (deviceUser.enableVivaWallet && (!deviceUser.isvClientId || deviceUser.isvClientId.trim().length === 0)) {
        return null;
      }

      if (deviceUser.enableVivaWallet && (!deviceUser.isvClientSecret || deviceUser.isvClientSecret.trim().length === 0)) {
        return null;
      }

      if (deviceUser.enableSumUp && (!deviceUser.affiliateKey || deviceUser.affiliateKey.trim().length === 0)) {
        return null;
      }*/

      return deviceUser;
    });

    if (devicesUserToUpdate.includes(null)) {
      this._notificationService.show({
        content: this._translateService.instant('event.details.fillDetailsError'),
        appendTo: this.notificationContainer,
        position: {horizontal: 'center', vertical: 'bottom'},
        type: {style: 'error', icon: false},
      });
      return;
    }

    if (!this.validatePaymentSystems(devicesUserToUpdate)) {
      return;
    }

    if (!this.validateIpAddress(devicesUserToUpdate)) {
      return;
    }

    const promises = devicesUserToUpdate.map(d => this._eventService.updateDeviceUser(d, this.event.id));

    forkJoin(promises).subscribe({
      next: (result) => {
        this._notificationService.show({
          content: this._translateService.instant('event.details.deviceUsersUpdated'),
          appendTo: this.notificationContainer,
          position: {horizontal: 'center', vertical: 'bottom'},
          type: {style: 'success', icon: false},
        });
        this.ngOnInit();
      },
      error: () => {
        this._notificationService.show({
          content: 'Internal Server Error',
          appendTo: this.notificationContainer,
          position: {horizontal: 'center', vertical: 'bottom'},
          type: {style: 'error', icon: false},
        });
      },
    });
  }

  updateEvent() {
    this._eventService.updateEvent(this.event).subscribe();
  }

  hasVivaWalletUsers() {
    return this.event.deviceUsers.some(device => device.enableVivaWallet);
  }

  hasSumUpUsers() {
    return this.event.deviceUsers.some(device => device.enableSumUp);

  }

  hasFiscalPrinters() {
    return this.event.deviceUsers.some(device => device.hasFiscalPrinter);
  }

  private validatePaymentSystems(devicesUserToUpdate: DeviceUser[] ): boolean {
    if (this.sumUpCost) {
      const sumUpDevicesEnabled = devicesUserToUpdate.filter(d => d.enableSumUp);
      if (sumUpDevicesEnabled.length > this.sumUpCost.quantity) {
       this._notificationService.show({
          content: this._translateService
            .instant('event.details.sumUpQuantityError')
            .replace('{quantity}', this.sumUpCost.quantity.toString()),
          appendTo: this.notificationContainer,
          position: {horizontal: 'center', vertical: 'bottom'},
          type: {style: 'error', icon: false},
        });
        return false;
      }
    }
    if (this.vivaWalletCost) {
      const vivaWalletDevicesEnabled = devicesUserToUpdate.filter(d => d.enableVivaWallet);
      if (vivaWalletDevicesEnabled.length > this.vivaWalletCost.quantity) {
        this._notificationService.show({
          content: this._translateService
            .instant('event.details.vivaWalletQuantityError')
            .replace('{quantity}', this.vivaWalletCost.quantity.toString()),
          appendTo: this.notificationContainer,
          position: {horizontal: 'center', vertical: 'bottom'},
          type: {style: 'error', icon: false},
        });
        return false;
      }
    }
    return true;
  }

  private validateIpAddress(deviceUserToUpdate: DeviceUser[]): boolean {
    const ips = [...new Set(deviceUserToUpdate.filter(d => d.fiscalPrinterIP).map(d => d.fiscalPrinterIP))];

    // validate quantity
    if (ips.length > this.fiscalPrinterLicenses.qtySoftware) {
      this._notificationService.show({
        content: this._translateService
          .instant('event.details.fiscalIpQuantityError')
          .replace('{quantity}', this.fiscalPrinterLicenses.qtySoftware.toString()),
        appendTo: this.notificationContainer,
        position: {horizontal: 'center', vertical: 'bottom'},
        type: {style: 'error', icon: false},
      });
      return false;
    }

    // validate IP address
    if (ips.some(ip => !this.isValidIPAddress(ip))) {
      this._notificationService.show({
        content: this._translateService.instant('event.details.fiscalIpInvalidError'),
        appendTo: this.notificationContainer,
        position: {horizontal: 'center', vertical: 'bottom'},
        type: {style: 'error', icon: false},
      });
      return false;
    }

    return true;
  }

  private isValidIPAddress(ipAddress: string): boolean {
    // Regular expression for IPv4
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    // Check if the ipAddress matches IPv4 regex
    return ipv4Regex.test(ipAddress);
  }

  populatePrinterSelect() {
    this.printerListOptions = [{ value: undefined, label: this._translateService.instant('event.details.printerSelect')}];
    this._eventService.getPrintersByCompany().subscribe(printers => {
      this.ownPrinters = printers;
      printers.forEach(printer => {
        if (this.event.printers.length === 0) {
          this.printerListOptions.push({ value: printer, label: printer.name });
        } else {
          let found = false;
          this.event.printers.forEach(printerE => {
            if (printer.id === printerE.id) {
              found = true;
            }
          });
          if (!found) {
            this.printerListOptions.push({ value: printer, label: printer.name });
          }
        }
      });
    }, error => {
      console.log(error);
    });
  }

  addRemovePrinterToCompanyClick(printerId: number, action: string) {
      if (action === 'add') {
        if (this.selectedPrinter) {
          // call backend endpoint for assign printer to event
          this._eventService.printersAssignUnassignToEvent(this.event.id, this.selectedPrinter.id, 1 ).subscribe(result => {
            // delete from select box
            const index = this.printerListOptions.findIndex(printer => printer.value === this.selectedPrinter);
            this.printerListOptions.splice(index, 1);
            // add to event object
            this.event.printers.push(this.selectedPrinter);
            // add to list of assigned printers
            // set selected printer as undefined
            this.selectedPrinter = undefined;
            this.countSelectablePrinters();
          }
          , error => {
            console.log(error);
          });
        }
      }
      if (action === 'del') {

        // call backend endpoint for unassign printer to event
        this._eventService.printersAssignUnassignToEvent(this.event.id, printerId, 0).subscribe(result => {
          // get clicked printer object
          const index = this.event.printers.findIndex(printer => printer.id === printerId);
          const currentPrinterObj = this.event.printers[index];
          // remove event object
          this.event.printers.splice(index, 1);
          // empty and repopolate printer select
          this.printerListOptions = undefined;
          this.populatePrinterSelect();
          this.countSelectablePrinters();
          // empty printer categories
          this._eventService.insertPrintersCategories([], this.event.id.toString()).subscribe(result => {
            window.location.reload();
          });
        }, error => {
          console.log(error);
        });
      }
  }

  countSelectablePrinters() {
    this.selectablePrinterCount = 0;
    let requestedSoftwareTypes = 0;
    this.event.requestedSoftwareTypes.forEach(element => {
      if (element.softwareType.id === 4) /*printer*/ {
        requestedSoftwareTypes = element.quantity;
        this.selectablePrinterCount += element.quantity;
      }
    });
    this.event.requestedPrinterTypes.forEach(element => {
      this.selectablePrinterCount -= element.quantity;
    });
    this.event.printers.forEach(element => {
      if (this.ownPrinters.findIndex(printer => printer.id === element.id) !== -1) {
        this.selectablePrinterCount -= 1;
      }
    });
    if (this.event.printers.length === requestedSoftwareTypes) {
      this.selectablePrinterCount = 0;
    }
  }

  checkOwnPrinter(printerId: number): boolean {
    return this.ownPrinters.findIndex(printer => printer.id === printerId) !== -1;
  }

  private _getRequestedQuantity(objectArray: any) {
    let amount = 0;
    objectArray.map(function (object) {
      amount += object.quantity;
    });
    return amount;
  }

  public togglePopup(anchor: ElementRef | HTMLElement, template: TemplateRef<{ [Key: string]: unknown }>, mouse: boolean) {
    if (!mouse) {
      this.popupRef.close();
      this.popupRef = null;
    } else {
      this.popupRef = this._popupService.open({
        anchor: anchor,
        content: template,
        animate: false,
        anchorAlign: { horizontal: 'left', vertical: 'top' },
        popupAlign: { horizontal: 'left', vertical: 'bottom' },
      });
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClickOutside(targetElement: HTMLElement) {
    if (!targetElement.classList.contains('ignore-click-listener') && this.popupRef) {
      this.popupRef.close();
      this.popupRef = null;
    }
  }

}
